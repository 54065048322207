import {ITreeNodeModel} from '../tree.state.helper';
import {LayerTreeNode} from '../../common/Models/UI/ui-tree-node.model';
import {environment} from '../../../environments/environment';
import { IPlacemark } from '../placemarks.state/placemarks.model';
import { ExternalId } from '../app.state/app.model';

export enum GeneralLayerId {
  ADDITIONAL_INFO_GROUP = 0,
  QR_CODE = 7,
  PANORAMIC = 8,
  ADDRESS = 9
}

export enum LAYER_TYPES {
  Site = 'SITE_LAYER',
  General = 'GENERAL_LAYER',
  Status = 'STATUS_LAYER',
  Group = 'GROUP_LAYER',
  Scan = 'SCAN_LAYER'
}

export enum LAYER_VISIBILITY_MODE {
  VISIBILITY_CHANGED, IDLE
}

export enum LAYER_MODE {
  NEW_OR_OLD, EDITED
}

export enum LAYER_VISIBILITY {
  VISIBLE = 'VISIBLE',
  UNVISIBLE = 'UNVISIBLE',
  PARTIAL_VISIBLE = 'PARTIAL_VISIBLE'
}

export enum STATUS_LAYER_MASK {
  STATUS1 = 1,
  STATUS2 = 2,
  STATUS3 = 4,
  STATUS4 = 8,
  STATUS5 = 16,
  STATUS6 = 32
}

export enum LAYER_CREATED_BY_APP {
  CHECKLIST = 'checklistApp'
}

export enum STATUS_VISUALIZATION_METHOD {
  STATUS_TAG = 'statustag',
  PIECHART = 'piechart',
  BARCHART = 'barchart',
  TABLE = 'table'
}

export enum ACCESS_MODE {
  UNDEFINED = 'UNDEFINED',
  READ_ONLY = 'READ_ONLY',
  MODIFIABLE = 'READ_ONLY',
  PROTECTED = 'PROTECTED'
}

export interface ILayerMode {
  objMode?: LAYER_VISIBILITY_MODE;
  layerMode?: LAYER_MODE;
}

export interface ILayer extends ITreeNodeModel, ILayerMode {
  visible: LAYER_VISIBILITY;
  layerType: LAYER_TYPES;
  expanded: boolean;
  description: string;
  logicType: number;
  statusColors: string[];
  iconURL: string;
  libIconId: string;
  visibleByDefault: boolean;
  layerStatus: number;
  accessMode: ACCESS_MODE;
  createdByApp?: string;
  // for scan layer only
  scanProjectUUID?: string;
  pointCloudUUID?: string;
  scanProjectBaseUrl?: string;
  scanProjectName?: string;
  scanResolution?: string;

  visualizationMethod?: STATUS_VISUALIZATION_METHOD;
  visualizationConf?: any;
  externalId?: ExternalId[];
}

export enum LAYER_TREE_NODE_PROPERTIES {
  VISIBILITY, EXPANDED
}

export interface ILayerProperty {
  node: LayerTreeNode;
  propType: LAYER_TREE_NODE_PROPERTIES;
  propVal: any;
}

export interface IRemovedLayer {
  removedId: string;
  replacementId: string;
  replacedIcon: string;
}

export interface IScanData {
  layer: ILayer;
  scanPlacemarks: IPlacemark[];
}
