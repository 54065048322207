<div class="expansionTitle" [ngStyle]="titleStyle" [class.titleBackground]="elementOnPanel" [style.height]="titleHeight"
     (mouseover)="markHover(true)" (mouseleave)="markHover(false)" (click)="toggleExpansion()">
  <div class="titleBox">
    <mat-icon *ngIf="leftPositionedArrow" svgIcon={{getIconName()}} class="expandIcon"></mat-icon>
    <ng-content select="[titleContent]"></ng-content>
  </div>
  <div class="controlButtonPlaceHolder" (click)="stopPropagationOfHoverContentClick($event)">
    <ng-content *ngIf="hover" select="[hoverContent]"></ng-content>
  </div>
  <mat-icon *ngIf="!leftPositionedArrow" svgIcon={{getIconName()}} class="expandIcon"></mat-icon>
</div>
<div class="expansionContent" [class.padLeft]="leftPositionedArrow || forcePadLeft" [class.opened]="expanded">
  <ng-content select="[content]"></ng-content>
</div>
