import { Injectable } from '@angular/core';
import { ApiTools } from 'src/app/services/api.services/api.tools';
import * as THREE from 'three';
import { NavigatorManagerService } from './navigator-manager.service';
import { CoordinatesType, IPlacemark } from 'src/app/Store/placemarks.state/placemarks.model';
import { ISite } from 'src/app/Store/sites.state/sites.model';
import { CommonUtilitySvc } from 'src/app/services/common-utility.service';
import { Select, Store } from '@ngxs/store';
import { Observable } from 'rxjs';
import { AppState } from 'src/app/Store/app.state/app.state';
import { NavigatorUtilityService } from './navigator-utility.service';
import { CMD_ACTIONS, CMD_TARGETS, CmdRouterService } from 'src/app/services/cmd-router.service';
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls';
import { ILayout } from 'src/app/Store/models.state/models.model';
import { WebshareApiSvc } from 'src/app/services/api.services/webshare.api.svc';
import { DialogRef } from 'src/app/common/Forms/Dialog-types/dialog-ref';
import { NotificationDialogComponent } from 'src/app/common/Forms/Dialogs/notification-dialog/notification-dialog.component';
import { DialogModel } from 'src/app/common/Models/Dialog/dialog.model';
import { InputsBindingsModel } from 'src/app/common/Models/Dialog/inputs-binding.model';
import { ButtonInfo } from '../../helperClasses/value-and-display.class';
import { DialogService } from 'src/app/services/dialogs.service';
import TWEEN from '@tweenjs/tween.js';

@Injectable({
  providedIn: 'root'
})
export class NavigatorPlacemarkService {
  public camera: THREE.PerspectiveCamera;
  public scene: THREE.Scene;
  public placemarksGroup: THREE.Object3D;
  public placemarksMarkers = [];
  public placemarks: IPlacemark[] = [];
  public placemarksMap: Map<string, any> = new Map();
  public controls: OrbitControls;
  public loadedPanoMarker = null;
  public highlightMarker = null;
  public traingleMarker = null; 
  public firstHighlightPlacemarkId = null;
  public renderer: THREE.WebGLRenderer;

  public layoutsArr: {id: string, visible: boolean, altitude: number}[] = [];
  public animationDelay: number = 1000;
  public webshareAuthPending: boolean = false;

  @Select(AppState.getActiveSite) activeSite$: Observable<ISite>;
  private activeSite: ISite;
  public iconDefaultRotation = 90;
  public rotation = 0;
  public isPanoLoaded: boolean = false;

  constructor(private commonUtilityService: CommonUtilitySvc, private navigatorManager: NavigatorManagerService,
    private navigatorUtility: NavigatorUtilityService, private cmdRouterSvc: CmdRouterService, private store: Store,
    private webshareApiSvc: WebshareApiSvc, private dialogService: DialogService) {
    this.activeSite$.subscribe((site: ISite) => this.activeSite = site ? site : null);
    this.rotation = this.iconDefaultRotation;
  }

  public updatePanoramicProperties(rotation, manualRotation) {
    this.rotation = -1 * (rotation - manualRotation + this.iconDefaultRotation) * Math.PI / 180;
    if (this.traingleMarker && this.isPanoLoaded) {
      this.traingleMarker.material.rotation = this.rotation;
      this.navigatorManager.rerender = true;
    }
  }

  public init(scene: THREE.Scene, camera: THREE.PerspectiveCamera, controls: OrbitControls, renderer: THREE.WebGLRenderer) {
    this.camera = camera;
    this.scene = scene;
    this.controls = controls;
    this.renderer = renderer;
    this.placemarksGroup = new THREE.Object3D();
    this.placemarksGroup.name = "placemarksGroup";
    this.scene.add(this.placemarksGroup);
    this.setPlacemarksListeners();
  }

  public destroy(): void {
    this.camera = null;
    this.scene = null;
    this.controls = null;
    this.placemarksMarkers = [];
    this.placemarks = [];
    this.placemarksMap.clear();
    this.placemarksGroup.clear();
    this.loadedPanoMarker = null;
    this.highlightMarker = null;
    this.traingleMarker = null;
    this.rotation = this.iconDefaultRotation;
    this.isPanoLoaded = false;
    this.firstHighlightPlacemarkId= null;
  }

  public setPlacemarksListeners(): void {
    this.placemarksMap.clear();
    this.setupLayoutsArray();
    const placemarks: IPlacemark[] = this.store.selectSnapshot<IPlacemark[]>((state: any) => state.StatePlacemarks.placemarks);
    placemarks.filter( (pm: IPlacemark) => (pm.category === 'PANORAMIC' || pm.scanUUID) && pm.coordinatesType != CoordinatesType.GIS).forEach( (pm: IPlacemark) => {
      this.placemarks.push(pm)
      if (!this.placemarksMap.has(pm.id) && pm.parentLayerId) {
        const altitude = this.calculateAltitude(pm.positionProperty.y);
        this.createPlacemark(pm, this.activeSite, altitude);
      }
    })
  }

  public setupLayoutsArray(): void {
    const layouts: ILayout[] = this.store.selectSnapshot<ILayout[]>((state: any) => state.StateModels.layouts);
    this.layoutsArr = [];
    layouts.forEach((layout) => {
      const layoutData = {
        id: layout.id,
        visible: this.navigatorManager.getVisibility({zoneId: layout.parentZoneId, layerId: layout.parentLayerId}),
        altitude: layout.altitude,
      }
      this.layoutsArr.push(layoutData);
    });
  }

  public calculateAltitude(placemarkAltitude: number) {
    let smallestAltitudeDiff = Infinity;
    let altitudeForPlacemark = 0;
    this.layoutsArr.forEach( (layout: any) => {
      let altitudeDiff = 0;
      if (layout.visible) {
        if (placemarkAltitude >= layout.altitude) {
          altitudeDiff = placemarkAltitude - layout.altitude;
          if (altitudeDiff < smallestAltitudeDiff) {
            altitudeForPlacemark = layout.altitude + 0.1;
            smallestAltitudeDiff = altitudeDiff;
          }
        } else if (placemarkAltitude == layout.altitude) {
          altitudeForPlacemark = layout.altitude + 0.1;
          smallestAltitudeDiff = 0;
        }
      }
    });
    return altitudeForPlacemark;
  }

  public adjustPlacemarkVisibility() {
    this.setupLayoutsArray();
    this.placemarksMarkers.forEach((placemark) => {
      placemark.visible = this.navigatorManager.getVisibility(placemark);
      placemark.position.y = this.calculateAltitude(placemark.originalAltitude);
    });
    if (this.highlightMarker) {
      // this.highlightMarker.visible = this.navigatorManager.getVisibility(this.highlightMarker);
      this.highlightMarker.position.y = this.calculateAltitude(this.highlightMarker.originalAltitude);
    }
    this.navigatorManager.rerender = true;
  }

  public createPlacemark(placemark: IPlacemark, site: ISite, altitude: number) {
    const iconUrl = this.commonUtilityService.setIconForPM(placemark.parentLayerId, placemark);
    this.navigatorUtility.makeImageSpriteByType(iconUrl, ApiTools.defaultSiteId)
      .then((...args: any[]) => {
        const marker = args[0];
        const xx = placemark.positionProperty.x + site.offsetX;
        // const yy = placemark.positionProperty.y + site.offsetZ;
        const yy = altitude;
        const zz = placemark.positionProperty.z + site.offsetY;
        marker.originalPosition = new THREE.Vector3(xx, yy, zz);
        marker.absolutePosition = new THREE.Vector3(placemark.positionProperty.x, placemark.positionProperty.y, placemark.positionProperty.z);
        marker.position.copy(marker.originalPosition);

        marker.originalAltitude = placemark.positionProperty.y ;

        // add marker properties
        marker.placemarkId = placemark.id;
        marker.name = placemark.name;
        marker.type = placemark.category;
        marker.description = placemark.description;
        marker.link = placemark.url;
        marker.layerId = placemark.parentLayerId;
        marker.zoneId = placemark.parentZoneId;
        marker.visible = this.navigatorManager.getVisibility(marker);
        marker.iconId = iconUrl.substring(iconUrl.lastIndexOf("/") + 1, iconUrl.lastIndexOf("."));

        marker.scanUUID = placemark.scanUUID;

        this.placemarksMarkers.push(marker);
        this.placemarksMap.set(placemark.id, marker);
        marker.uiType = placemark.placemarkUiType;

        marker.showLeg = placemark.style.showLeg;
        this.placemarksGroup.add(marker);
        this.navigatorManager.rerender = true;
        if (this.firstHighlightPlacemarkId && this.firstHighlightPlacemarkId == placemark.id) {
          this.highlightPlacemark(this.firstHighlightPlacemarkId);
        }
      })
      .catch(() => {
        console.log('error in creating placemark. See failure report');
      });
  }

  public onDocumentMouseMove(mouse, toolTipElement: HTMLElement): void {
    const intersects = this.getIntersectedObjects(mouse);
    if (intersects.length > 0) {
      this.navigatorManager.panoLinksLoaded && (this.renderer.domElement.style.cursor = 'pointer');
      const marker = intersects[0].object;

      const screenXY = this.navigatorUtility.toScreenPosition(marker, this.camera, this.renderer);
      toolTipElement.innerText = marker.name;
      toolTipElement.style.top = `${screenXY.y - 18}px`;
      toolTipElement.style.left = `${screenXY.x + 10}px`;
    } else {
      this.renderer.domElement.style.cursor = 'default';
      toolTipElement.innerText = "";
    }
  }

  public onDocumentMouseClick(mouse, position): void {
    const intersects = this.getIntersectedObjects(mouse);
    if (intersects.length > 0) {
      if (intersects.length > 1) {
        this.cmdRouterSvc.sendActionCmd(CMD_TARGETS.NAVIGATOR, CMD_ACTIONS.SHOW_MULTIPLE_PM_MENU, {objects: intersects, position: position});
      } else {
        this.placemarkClicked(intersects[0].object);
      }
    } else {
      this.cmdRouterSvc.sendActionCmd(CMD_TARGETS.NAVIGATOR, CMD_ACTIONS.SHOW_MULTIPLE_PM_MENU, {objects: intersects, position: position});
    }
  }

  public placemarkClicked(placemarkObj): void {
    if (!this.navigatorManager.panoLinksLoaded) {
      return;
    }

    if (this.navigatorManager.panoramaViewLocked) {
      const inputsBinding: InputsBindingsModel = new Map([
        [ 'type', 'warning'],
        [ 'title', 'Save Changes' ],
        [ 'message', 'You are in editing mode. Do you want to save the changes?'],
        [ 'onXAction', 'x']
      ]);
      const dialog: DialogRef = this.dialogService.createNotificationDialog(inputsBinding);
      const dialogComp: NotificationDialogComponent = (dialog.instance as NotificationDialogComponent);
      this.cmdRouterSvc.sendActionCmd(CMD_TARGETS.PANORAMIC_MANAGER, CMD_ACTIONS.SAVE_CHANGES_MODE, {dialogRef: dialog});
      dialogComp.buttonsInfo = [
        new ButtonInfo('no', 'No'),
        new ButtonInfo('yes', 'Yes'),
      ];
      dialog.onClose$().subscribe((data: DialogModel) => {
        if (data.userAction !== 'x') {
          this.loadPlacemarkInPanoramicView(placemarkObj);
        }
      });
    } else {
      this.loadPlacemarkInPanoramicView(placemarkObj);
    }
  }

  public loadPlacemarkInPanoramicView(placemarkObj): void {
    if (!this.loadedPanoMarker || (this.loadedPanoMarker.placemarkId != placemarkObj.placemarkId)) {
      const placemark = this.placemarks.find( (pm: IPlacemark) => pm.id == placemarkObj['placemarkId']);
      if (placemark) {
        if (placemarkObj.scanUUID) {
          this.checkLoginToWebshareAndOpenPlacemark(placemark.id, placemark.parentLayerId);
        } else {
          this.sendCommandToLoadPano(placemark.id, false);
        }
      }
    }
  }

  public sendCommandToLoadPano(placemarkId: string, isScanPM: boolean) {
    this.isPanoLoaded = false;
    this.cmdRouterSvc.sendActionCmd(CMD_TARGETS.PANORAMIC_MANAGER, CMD_ACTIONS.OPEN_PANO_FROM_NAVIGATOR, {placemarkId: placemarkId, isScanPM: isScanPM});
    // this.rotation = 0;
    this.navigatorManager.panoLinksLoaded = false;
  }

  public highlightPlacemark(placemarkId): void {
    this.cmdRouterSvc.sendActionCmd(CMD_TARGETS.PANORAMIC_MANAGER, CMD_ACTIONS.FILL_VIEWPOINT_PROPERTIES);
    const marker: THREE.Sprite = this.placemarksMap.get(placemarkId.toString());
    if (marker) {
      this.firstHighlightPlacemarkId = null;
      this.loadedPanoMarker = marker;
      this.setHighlightMarker();
      this.setTraingle();
      
      this.camera.position.set(marker.position.x, this.camera.position.y, marker.position.z);
      this.camera.lookAt(marker.position);
      this.camera.up.set(0, 1, 0);
      this.controls.target.copy(marker.position);
      this.controls.update();

      this.camera.updateProjectionMatrix();
      this.navigatorManager.rerender = true;
    } else {
      this.firstHighlightPlacemarkId = placemarkId;
    }
  }

  public panoLoaded(placemarkId: string): void {
    this.isPanoLoaded = true;
    this.cmdRouterSvc.sendActionCmd(CMD_TARGETS.PANORAMIC_MANAGER, CMD_ACTIONS.FILL_VIEWPOINT_PROPERTIES);
    if (this.firstHighlightPlacemarkId) {
      this.highlightPlacemark(this.firstHighlightPlacemarkId);
    }
  }

  public updatePlacemarksScale(): void {
    const distanceVector = new THREE.Vector3();
    this.placemarksMarkers.forEach((placemark) => {
      this.updateScale(placemark, distanceVector);
    });
    this.highlightMarker && this.updateScale(this.highlightMarker, distanceVector, 15);
    this.traingleMarker && this.updateScale(this.traingleMarker, distanceVector, 2);
  }

  public updateScale(placemark, distanceVector, factor = 20): void {
    const distance = distanceVector.subVectors(placemark.position, this.camera.position).length();
    const scale = Math.pow(distance/factor, 0.7);
    placemark.scale.set(scale, scale, 1);
  }

  public setHighlightMarker(): void {
    const spritePosition = this.loadedPanoMarker.position;
    if (this.highlightMarker) {
      this.highlightMarker.originalAltitude = this.loadedPanoMarker.originalAltitude;
      this.highlightMarker.layerId = this.loadedPanoMarker.layerId;
      this.highlightMarker.zoneId = this.loadedPanoMarker.zoneId;
      new TWEEN.Tween(this.highlightMarker.position).to(spritePosition, this.animationDelay).easing(TWEEN.Easing.Quadratic.InOut).start().onUpdate(() => this.navigatorManager.rerender = true);
    } else {
      this.createHighlightMarker();
    }
  }
  public createHighlightMarker(): void {
    const spritePosition = this.loadedPanoMarker.position;
    this.navigatorUtility.makeImageSprite({
      imageUrl: "assets/navigator/placemarkOutline.png",
      imageWidth: 2,
      imageHeight: 2,
      position: spritePosition
    }).then((marker: any) => {
      marker.material.depthTest = false;
      marker.material.depthWrite = false;
      marker.material.map.minFilter = THREE.LinearFilter;
      marker.material.map.magFilter = THREE.LinearFilter;

      marker.renderOrder = 9999;

      marker.originalAltitude = this.loadedPanoMarker.originalAltitude;
      marker.layerId = this.loadedPanoMarker.layerId;
      marker.zoneId = this.loadedPanoMarker.zoneId;

      marker.material.opacity = 0.8;
      marker.material.transparent = true;

      this.highlightMarker && this.scene.remove(this.highlightMarker);
      this.highlightMarker = null;
      this.highlightMarker = marker;
      this.scene.add(marker)
      this.navigatorManager.rerender = true;
    });
  }

  public setTraingle(): void {
    const spritePosition = this.loadedPanoMarker.position;
    if (this.traingleMarker) {
      this.traingleMarker.originalAltitude = this.loadedPanoMarker.originalAltitude;
      this.traingleMarker.layerId = this.loadedPanoMarker.layerId;
      this.traingleMarker.zoneId = this.loadedPanoMarker.zoneId;
      this.traingleMarker.material.rotation = this.rotation;
      new TWEEN.Tween(this.traingleMarker.position).to(spritePosition, this.animationDelay).easing(TWEEN.Easing.Quadratic.InOut).start().onUpdate(() => this.navigatorManager.rerender = true);
    } else {
      this.createTraingle();
    }
  }
  public createTraingle() {
    this.navigatorUtility.makeImageSprite({
      imageUrl: "assets/navigator/triangle.svg",
      imageWidth: 2,
      imageHeight: 2,
      position: this.loadedPanoMarker.position
    }).then((marker: any) => {
      marker.material.depthTest = false;
      marker.material.depthWrite = false;
      marker.renderOrder = 1;

      marker.originalAltitude = this.loadedPanoMarker.originalAltitude;
      marker.layerId = this.loadedPanoMarker.layerId;
      marker.zoneId = this.loadedPanoMarker.zoneId;
      marker.material.rotation = this.rotation;

      marker.material.transparent = true;
      marker.material.opacity = 0.7;

      this.traingleMarker && this.scene.remove(this.traingleMarker);
      this.traingleMarker = null;
      this.traingleMarker = marker;
      this.scene.add(marker)
      this.navigatorManager.rerender = true;
    });
  }

  private async checkLoginToWebshareAndOpenPlacemark(placemarkId: string, layerId: string) {
    if (this.webshareAuthPending) {
      return;
    }
    this.webshareAuthPending = true;
    const sub = this.webshareApiSvc.webShareLoginInfoSubject()
      .subscribe( async ( success ) => {
        sub.unsubscribe();
        if (success) {
          try {
            await this.webshareApiSvc.checkUserAccessToWebshareProject(layerId);
            this.sendCommandToLoadPano(placemarkId, true);
          } catch (error) {
          }
        }
        this.webshareAuthPending = false;
      })
    this.webshareApiSvc.loginToWebshare();
  }

  private getIntersectedObjects(mouse: THREE.Vector2): any {
    const raycaster = new THREE.Raycaster();
    raycaster.setFromCamera(mouse, this.camera);
    let intersects = raycaster.intersectObjects(this.placemarksMarkers, true);
    return intersects.filter(o => o.object.visible);
  }
}