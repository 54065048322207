import {Component, HostListener} from '@angular/core';
import {SvgIconsService} from './services/svg-icons.service';
import {PermissionsManager} from './services/permissions-manager';
import {ServerApi} from './services/api.services/server.api';
import {IncomingMessagesService} from './services/external/incoming-messages.service';
import {SetLimitedAccessConf} from './Store/app.state/app.actions';
import {Store} from '@ngxs/store';
import {ThemeService} from './services/themes.service';

@Component({
  selector: 'ins-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {

  @HostListener('document:contextmenu', ['$event'])
  public rightClick(event: MouseEvent): void {
    if (event.target['type'] !== 'text' && event.target['type'] !== 'textarea') {
      event.preventDefault();
    }
  }

  constructor(private svgIconService: SvgIconsService, private serverApi: ServerApi, private incomingMessagesService: IncomingMessagesService, private store: Store,
    private themeSevice: ThemeService
  ) {
    PermissionsManager.init(serverApi);
    console.log('APP COM CTOR');
    this.svgIconService.init();

    this.store.dispatch(new SetLimitedAccessConf({
      disable3DModels: false,
      disableImportScans: false,
      limitSites: false,
      limitSiteCount: 3,
      disableTemplateManagement: false
    }));
  }

}
