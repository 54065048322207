import { ExternalId } from '../app.state/app.model';
import {ITreeNodeModel} from '../tree.state.helper';

export interface IRemovedZone {
  removedId: string;
  replacementId: string;
}

export enum ZONE_MODE {
  VISIBILITY_CHANGED, IDLE, REMOVED
}

export interface IZoneMode {
  objMode?: ZONE_MODE;
}

export interface IZone extends ITreeNodeModel, IZoneMode {
  visible: boolean;
  isDefault: boolean;
  description: string;
  defaultViewpointId: string;
  accessMode: string;
  visibleByDefault: boolean;
  externalId?: ExternalId[];
}
