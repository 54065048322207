import {Component, OnInit} from '@angular/core';
import {AppState} from '../../Store/app.state/app.state';
import {Select, Store} from '@ngxs/store';
import {APPLICATION_MODE, IAppConf, SCENE_MODE} from '../../Store/app.state/app.model';
import {Observable} from 'rxjs';

@Component({
  selector: 'ins-content-container',
  templateUrl: './content.container.html',
  styleUrls: ['./content.container.scss']
})
export class ContentContainer implements OnInit {

  @Select(AppState.getSelectedMode) selectedMode$: Observable<SCENE_MODE>;
  public sceneMode: SCENE_MODE;

  @Select(AppState.getOpenedPanel) pressedPanelName$: Observable<string>;
  public openedPanel: string;

  @Select(AppState.getAppConf) appConf$: Observable<IAppConf>;
  public appConf: IAppConf;
  public APPLICATION_MODES: any = APPLICATION_MODE;
  constructor(private store: Store) {
    this.appConf$.subscribe( (appConf: IAppConf) => {
      this.appConf = appConf;
    });

    this.selectedMode$.subscribe((mode: SCENE_MODE) => {
      this.sceneMode = mode;
    });
    this.pressedPanelName$.subscribe((name: string) => {
      this.openedPanel = name;
    });
  }

  ngOnInit(): void {
  }

}
