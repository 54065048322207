export enum SCENE_MODE {
  Facility, Map, Panoramic, Load
}

export enum APPLICATION_MODE {
  STANDALONE = 'Standalone',
  EMBEDDED = 'Embedded'
}

export interface IAppConf {
  appMode: APPLICATION_MODE;
  readonly: boolean;
  appHeader: boolean;
  sideBarContainer: boolean;
  webGLToolbar: boolean;
  panoToolbar: boolean;
  loadSpecificSite: boolean;
}

export interface ExternalId {
  externalId: string;
  application: string;
}

export const NO_MODELESS_DIALOG_OPEN: any = undefined;
