<div class="upperSection">
  <div class="menuTitleWithExit">
    <div class="menuTitle" style="padding-top: 11px">
      <div class="title">Zones</div>
    </div>
    <div class="menuTitle exitMenu">
      <mat-icon class="closeIcon" svgIcon="panel.close" (click)="closePanel.emit()"></mat-icon>
    </div>
  </div>
  <div class="treeControllers" *ngIf="enableFunctionalities">
    <ins-checkbox [cbCustomStyle]="{'font-size': '12px'}" [checkboxOnPanel]="true" style="max-width: 100%; width: 100%"
                  [label]="''" [topPad]="false" [checkboxText]="'Display all'" (checkboxChanged)="selectAll($event)" [checked]="allZoneSelected"
                  [ignoreValueManipulations]="true"></ins-checkbox>
  </div>
</div>

<div class="middleSection" [class.noContent]="zones.length === 0">
  <div *ngIf="zones.length === 0" class="noContentAvailable">No zone is available for this site</div>
  <perfect-scrollbar *ngIf="zones.length !== 0">
    <div *ngFor="let zone of zones; trackBy: trackByVisibility" class="zoneLine" (mouseover)="markHover(true, zone)" (mouseleave)="markHover(false, zone)">
      <ins-checkbox [cbCustomStyle]="cbCustomStyle(zone)" [checkboxOnPanel]="true" style="max-width: 100%; width: 100%"
                    insTooltip [delayMs]="700" [cfg]="{text: zone.description, position: {H: 'hLeft', V:'vTop'}, type: 'info'}"
                    [label]="''" [topPad]="false" [checkboxText]="zone.name" [(value)]="zone.visible" (checkboxChanged)="setZoneVisibility(zone, $event)"></ins-checkbox>
      <div *ngIf="hover[zone.id]" class="zoneHoverContent">
        <mat-icon *ngIf="isPermEditZone" class="controlButton zonesEdit" svgIcon="sites.edit" (click)="editZone(zone)"></mat-icon>
        <mat-icon *ngIf="isPermDeleteZone && !zone.isDefault" class="controlButton zonesDelete" svgIcon="sites.delete" (click)="deleteZone(zone)"></mat-icon>
      </div>
    </div>
  </perfect-scrollbar>
</div>

<div class="lowerSection itemControllers">
  <div *ngIf="isPermAddZone && enableFunctionalities" class="textButtonOnPanel" id="addZoneLink" (click)="addZone()">Add Zone</div>
</div>

