<div class="upperSection">
  <div class="menuTitleWithExit">
    <div class="menuTitle" style="padding-top: 11px">
      <div class="title">Layers</div>
    </div>
    <div class="menuTitle exitMenu">
      <mat-icon class="closeIcon" svgIcon="panel.close" (click)="closePanel.emit()"></mat-icon>
    </div>
  </div>
  <div class="treeControllers" *ngIf="enableFunctionalities">
    <span (click)="menu.expandAll()" class="textButtonOnPanel">Expand All</span>
    <span class="buttonSeperator"></span>
    <span (click)="menu.collapseAll()" class="textButtonOnPanel">Collapse All</span>
  </div>
</div>

<div class="middleSection layersMenu" [class.noContent]="layers.length === 0">
  <div *ngIf="layers.length === 0" class="noContentAvailable">No layer is available for this site</div>
  <perfect-scrollbar [class.hiddenSection]="layers.length === 0">
    <ins-tree-menu #menu [tree]="layers" [nodeStyle]="{}" [checkboxOnPanel]="true"
                   (propertyChanged)="layerPropertyChanged($event)" (hoveredNode)="hoveredLayer = $event" [hideEmptyGroupLayers]="false">
      <div leafHoverContent>
        <mat-icon *ngIf="isPermEditLayer" class="controlButton editLayerBtn" svgIcon="sites.edit" (click)="editLayer($event)" insTooltip [cfg]="{text: 'Edit', position: {H: 'hCenter', V:'vTop'}, type: 'info'}"></mat-icon>
        <mat-icon *ngIf="isAdmin" class="controlButton editLayerBtn" svgIcon="copy" (click)="copyLayer($event)" insTooltip [cfg]="{text: 'Copy', position: {H: 'hCenter', V:'vTop'}, type: 'info'}"></mat-icon>
        <mat-icon *ngIf="isPermDeleteLayer" class="controlButton deleteLayerBtn" svgIcon="sites.delete" (click)="removeLayer($event)" insTooltip [cfg]="{text: 'Delete', position: {H: 'hCenter', V:'vTop'}, type: 'info'}"></mat-icon>
      </div>
      <mat-icon *ngIf="isPermEditLayer" groupHoverContent class="controlButton editLayerBtn" svgIcon="sites.edit" (click)="editLayer($event)" insTooltip [cfg]="{text: 'Edit', position: {H: 'hCenter', V:'vTop'}, type: 'info'}"></mat-icon>
      <mat-icon *ngIf="isAdmin" groupHoverContent class="controlButton editLayerBtn" svgIcon="copy" (click)="copyLayer($event)" insTooltip [cfg]="{text: 'Copy', position: {H: 'hCenter', V:'vTop'}, type: 'info'}"></mat-icon>
      <mat-icon *ngIf="isPermDeleteLayer" groupHoverContent class="controlButton deleteLayerBtn" svgIcon="sites.delete" (click)="removeLayer($event)" insTooltip [cfg]="{text: 'Delete', position: {H: 'hCenter', V:'vTop'}, type: 'info'}"></mat-icon>
      <!-- <mat-icon *ngIf="isPermDeleteLayer" condGroupHoverContent class="controlButton deleteLayerBtn" svgIcon="sites.delete" (click)="removeLayer($event)" insTooltip [cfg]="{text: 'Delete', position: {H: 'hCenter', V:'vTop'}, type: 'info'}"></mat-icon> -->
    </ins-tree-menu>
  </perfect-scrollbar>
</div>

<div class="lowerSection itemControllers alignCenter">
  <div *ngIf="isPermCloneLayers && enableFunctionalities" id="cloneLayers" class="textButtonOnPanel" (click)="cloneLayersDialog()">Clone Layers</div>
  <div *ngIf="isPermCloneLayers && enableFunctionalities" class="buttonSeperator fontHeight"> </div>
  <div *ngIf="isPermDeleteAll && enableFunctionalities" id="deleteAllLayers" class="textButtonOnPanel" (click)="deleteAllLayers()">Delete All</div>
  <div *ngIf="isPermDeleteAll && enableFunctionalities" class="buttonSeperator fontHeight"> </div>
  <div *ngIf="isPermAddLayer && enableFunctionalities" id="addLayer" class="textButtonOnPanel" (click)="AddLayer()">Add Layer</div>
</div>
