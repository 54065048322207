import { Injectable } from '@angular/core';
import { Select, Store } from '@ngxs/store';
import { Observable } from 'rxjs';
import { AppState } from 'src/app/Store/app.state/app.state';
import { Set3DModel } from 'src/app/Store/models.state/models.actions';
import { I3DModel } from 'src/app/Store/models.state/models.model';
import { SetPlacemark } from 'src/app/Store/placemarks.state/placemarks.actions';
import { IPlacemark } from 'src/app/Store/placemarks.state/placemarks.model';
import { ISite } from 'src/app/Store/sites.state/sites.model';
import { ServerApi } from '../api.services/server.api';
import { MessageAndType, PriorityStack, StatusService } from '../status.service';

@Injectable({
  providedIn: 'root'
})
export class OutgoingMessagesService {
  public modelMovementInfo = new Map<string, any>();
  public includePlacemarkInPause = new Map<string, boolean>();

  public intositeLoadingStarted: boolean = false;

  @Select(AppState.getActiveSite) activeSite$: Observable<ISite>;
  public activeSite: ISite;
  constructor(private serverApi: ServerApi, private store: Store, private statusService: StatusService) {
    this.activeSite$.subscribe((site: ISite) => {
      if (site) {
        this.activeSite = site;
        this.siteChanged({siteId: site.id, siteName: site.name});
      } else {
        this.activeSite = null;
      }
    });
    this.statusService.registerStatus().subscribe((status: PriorityStack<MessageAndType>) => {
      if (status.isEmpty() || status.top().isOnTime) {
        if (this.intositeLoadingStarted) {
          const cmdWrapper = {
            cmd: 'intositeLoadingStatus',
            data: {loaded: true}
          };
          this.sendMsgToHost(cmdWrapper);
          this.intositeLoadingStarted = false;
        }
      } else {
        this.intositeLoadingStarted = true;
      }
    });
  }

  public sendMsgToHost(data) {
    data.origin = 'intosite';
    window.parent.postMessage(data, '*');
  }

  public siteChanged(data) {
    const cmdWrapper = {
        cmd: 'intositeSiteChanged',
        data: data
    };
    this.sendMsgToHost(cmdWrapper);
  }

  public shapeCreated(data) {
    const cmdWrapper = {
        cmd: 'shapeCreated',
        data: data
    };
    this.sendMsgToHost(cmdWrapper);
  }

  public modelLocationUpdated(data: any) {
    if (data.referenceId && data.referenceId == 'internal_move') {
      return;
    }
    if (data.action == "update" && this.modelMovementInfo.has(data.modelId)) {
      data.modelLocationInfo = this.modelMovementInfo.get(data.modelId);
      this.modelMovementInfo.delete(data.modelId);
    }

    const modelId = data.modelId;
    const models3d: I3DModel[] = this.store.selectSnapshot<I3DModel[]>((state: any) => state.StateModels.models3D);
    const model3D: I3DModel = models3d.find((model: I3DModel) => model.id == modelId);

    let isModelUpdated: boolean = false;

    if (data.rotation) {
      model3D.rotation = data.rotation;
      isModelUpdated = true;
    }

    if (data.action == "pause") {
      if (data.status == 'paused') {
        if (model3D) {
          if (this.includePlacemarkInPause.has(modelId) && this.includePlacemarkInPause.get(modelId)) {
            const placemarks: IPlacemark[] = this.store.selectSnapshot<IPlacemark[]>((state: any) => state.StatePlacemarks.placemarks);
            const placemark: IPlacemark = placemarks.find((placemark) => placemark.name === model3D.name);
            if (placemark) {
              placemark.positionProperty.x = data.currentPosition.x - this.activeSite.offsetX;
              placemark.positionProperty.z = data.currentPosition.z - this.activeSite.offsetY;
              this.serverApi.storeDispatch(new SetPlacemark(placemark));
            }
            this.includePlacemarkInPause.delete(modelId);
          }
          model3D.latitude = data.currentPosition.x;
          model3D.longitude = data.currentPosition.z;
          isModelUpdated = true;
        }
      } else {
        if (this.includePlacemarkInPause.has(modelId)) {
          this.includePlacemarkInPause.delete(modelId);
        }
      }
    }

    isModelUpdated && this.serverApi.storeDispatch(new Set3DModel(model3D));

    const cmdWrapper = {
      cmd: 'modelLocationUpdated',
      data: data
    };
    this.sendMsgToHost(cmdWrapper);
  }

  public intositeSessionExpired() {
    const cmdWrapper = {
      cmd: 'intositeSessionExpired',
      data: null
    };
    this.sendMsgToHost(cmdWrapper);
  }
}
