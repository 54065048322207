import {
  Component,
  ElementRef,
  HostBinding,
  HostListener,
  Input,
  OnChanges,
  Renderer2,
  SimpleChanges,
  ViewChild,
  SimpleChange
} from '@angular/core';
import {DialogComSvc} from '../../../services/modeless-com.service';
import {DialogService, DialogType} from '../../../services/dialogs.service';
import {HelloWorldModelessComponent} from '../../../common/Forms/Dialogs/hello-world-modeless/hello-world-modeless.component';
import {HelloWorldModalComponent} from '../../../common/Forms/Dialogs/hello-world-modal/hello-world.component';
import {DialogRef} from '../../../common/Forms/Dialog-types/dialog-ref';
import {Edges} from '../../../Directives/resizable/resizable.directive';
import {NotificationDialogComponent} from '../../../common/Forms/Dialogs/notification-dialog/notification-dialog.component';
import {ButtonInfo} from '../../../common/UI-Components/helperClasses/value-and-display.class';
import {DialogModel} from '../../../common/Models/Dialog/dialog.model';
import {InputsBindingsModel} from '../../../common/Models/Dialog/inputs-binding.model';
import {NewEditPlacemarkDialogComponent} from '../../../common/Forms/Dialogs/new-edit-placemark-dialog/new-edit-placemark-dialog.component';
import {DECIMAL_RADIX} from '../../../common/Definitions/shared.definitions';
import {NewEditAddressDialogComponent} from '../../../common/Forms/Dialogs/new-edit-address-dialog/new-edit-address-dialog.component';
import {NewEditPanoramicDialogComponent} from '../../../common/Forms/Dialogs/new-edit-panoramic-dialog/new-edit-panoramic-dialog.component';
import {DeleteLayerDialogComponent} from '../../../common/Forms/Dialogs/delete-layer-dialog/delete-layer-dialog.component';
import {DeleteZoneDialogComponent} from '../../../common/Forms/Dialogs/delete-zone-dialog/delete-zone-dialog.component';
import {Select, Store} from '@ngxs/store';
import {Observable} from 'rxjs';
import {AppState, AppStateModel} from '../../../Store/app.state/app.state';
import {ClosePanel, SetOpenedPanel} from '../../../Store/app.state/app.actions';
import {EditorMode} from '../../../common/Forms/Dialogs/dialog.helper';
import {APPLICATION_MODE, IAppConf, SCENE_MODE} from '../../../Store/app.state/app.model';
import {AddEditZoneDialogComponent} from '../../../common/Forms/Dialogs/add-zone-dialog/add-edit-zone-dialog.component';
import {LayersStateUtils} from '../../../Store/layers.state/layers.state.utils';
import {ISite} from '../../../Store/sites.state/sites.model';
import {SitesState} from '../../../Store/sites.state/sites.state';
import {CMD_ACTIONS, CMD_TARGETS, CmdRouterService, IActionCmd} from '../../../services/cmd-router.service';
import {GeneralLayerId} from '../../../Store/layers.state/layers.model';
import {SettingsStateModel} from '../../../Store/settings.state/settings.state';
import {ServerApi} from '../../../services/api.services/server.api';
import {SessionApiSvc} from '../../../services/api.services/session.api.svc';
import {IUserData, UserDetailsService} from '../../../services/user-details.service';
import {isNull, isNullOrUndefined, isUndefined} from 'util';
import {PermissionsManager} from '../../../services/permissions-manager';

export const generalLayersIds: number[] = [GeneralLayerId.ADDITIONAL_INFO_GROUP, GeneralLayerId.ADDRESS, GeneralLayerId.PANORAMIC, GeneralLayerId.QR_CODE];
enum LOAD_SITE_CONTEXT {
  LOAD_SITE = 'Load a site from the Sites panel',
  LOAD_SITE_PERMISSION = 'This site cannot be loaded because you lack permissions.\nLoad a site from the Sites panel.'
}
@Component({
  selector: 'ins-scene-container',
  templateUrl: './scene.container.html',
  styleUrls: ['./scene.container.scss']
})
export class SceneContainer implements OnChanges {

  get debugMode(): boolean {
    return (window as any).og === 1;
  }

  public SceneMode: any = SCENE_MODE;

  public currentUser: IUserData = null;

  public changeCount: number = 1;

  // Side bar Panels are parts of the scene.container due to the width properties needed
  // This following supports auto resize of footer according to the change in width of Panel
  _subMenuWidth: number = 270;
  _subMenuSearchWidth: number = 540;
  @ViewChild('sideBarSubMenu', {static: true}) _sideBarSubMenu: ElementRef;
  // ----------------------------------------------------------------------------------------
  @Input() public openedPanel: string;
  public lastOpenedPanel: string;

  @Input() public sceneMode: SCENE_MODE;

  @Select(AppState.getActiveSite) activeSite$: Observable<ISite>;
  @Select(SitesState.getSites) sites$: Observable<ISite[]>;
  @Select(AppState.getPartialBlockedMode) blockMode: Observable<boolean>;
  @Select(AppState.getRightMenuIsOpen) rightMenuOpen$: Observable<boolean>;
  @Select(AppState.getLogoutToggleIndex) logoutToggleIndex$: Observable<number>;
  @Select(AppState.getGoogleBlocked) googleBlocked$: Observable<boolean>;
  @HostBinding('class.inRegisterPointSelection') inRegisterPointSelection: boolean;

  activeSite: ISite;
  noSiteSelected: boolean = false;
  loadSiteContext: string = LOAD_SITE_CONTEXT.LOAD_SITE;
  public inSettingSelectionRegisterPointIndex: number = -1;

  @Select(AppState.getAppConf) appConf$: Observable<IAppConf>;
  public appConf: IAppConf;
  public accessibleSitesCount = -1;

  public APPLICATION_MODES: any = APPLICATION_MODE;

  constructor(private store: Store, public dialogService: DialogService,
              public dialogComService: DialogComSvc, private renderer: Renderer2, private serverApi: ServerApi,
              private layerUtils: LayersStateUtils, private cmdRouterSvc: CmdRouterService, private sessionApiSvc: SessionApiSvc, private userDetailsService: UserDetailsService) {
    this.initActionCmdHandler();
    this.inRegisterPointSelection = false;
    this.appConf$.subscribe( (appConf: IAppConf) => {
      this.appConf = appConf;
    });
    this.sessionApiSvc.accessibleSitesCount.subscribe( (siteCount: number) => {
      this.accessibleSitesCount = siteCount;
    })
    this.activeSite$.subscribe((activeSite: ISite) => {
      if (activeSite) {
        this.activeSite = activeSite;
        this.loadSiteContext = LOAD_SITE_CONTEXT.LOAD_SITE;
      } else if (isNull(activeSite)) {
        this.activeSite = undefined;
        this.noSiteSelected = true;
        // this.sceneMode = SCENE_MODE.Load;
        this.loadSiteContext = LOAD_SITE_CONTEXT.LOAD_SITE_PERMISSION;
        requestAnimationFrame(() => {
          const openedPanelFromStore: string = this.store.selectSnapshot<AppStateModel>((state: any) => state.StateApp).openedPanel;
          if (openedPanelFromStore !== 'sidebar.sites') {
            this.store.dispatch(new SetOpenedPanel('sidebar.sites'));
          }
        });
      } else if (isUndefined(activeSite)) {
        this.loadSiteContext = LOAD_SITE_CONTEXT.LOAD_SITE;
      }
    });
    this.sites$.subscribe((sites: ISite[]) => {
      // if we loaded all the sites but no Default site chosen change the text
      if (sites && sites.length > 0) {
        requestAnimationFrame(() => {
          if (isNullOrUndefined(this.activeSite)) {
            this.noSiteSelected = true;
            const openedPanelFromStore: string = this.store.selectSnapshot<AppStateModel>((state: any) => state.StateApp).openedPanel;
            if (openedPanelFromStore !== 'sidebar.sites') {
              this.store.dispatch(new SetOpenedPanel('sidebar.sites'));
            }
          }
        });
      }
    });
    this.logoutToggleIndex$.subscribe((index: number) => {
      if (index > 0) {
        this.activeSite = undefined;
        this.noSiteSelected = false;
        this.loadSiteContext = LOAD_SITE_CONTEXT.LOAD_SITE;
        PermissionsManager.cleanPermissions();
      }
    });
  }

  private initActionCmdHandler(): void {
    this.cmdRouterSvc.actionCmdListener$(CMD_TARGETS.SCENE)
      .subscribe((actionCmd: IActionCmd) => {
        switch (actionCmd.action) {
          case CMD_ACTIONS.SET_SELECTION_REGISTER_POINT: {
            this.inSettingSelectionRegisterPointIndex = actionCmd.options['registerPointIndexHandling'];
            this.inRegisterPointSelection = true;
            break;
          }
          case CMD_ACTIONS.REMOVE_LAST_ACTIVE_PANEL: {
            this.lastOpenedPanel = undefined;
            break;
          }
          case CMD_ACTIONS.CLOSE_ACTIVE_PANEL: {
            this.lastOpenedPanel = this.openedPanel;
            this.closePanel();
            break;
          }
          case CMD_ACTIONS.OPEN_LAST_ACTIVE_PANEL: {
            if (this.lastOpenedPanel) {
              this.store.dispatch(new SetOpenedPanel(this.lastOpenedPanel));
            }
            this.lastOpenedPanel = undefined;
            break;
          }
        }
      });
    this.userDetailsService.getUserDetailsAsync().subscribe((user: IUserData) => {
      this.currentUser = user;
    });
  }

  @HostListener('document:keydown.escape', ['$event']) onEscapePress(event: KeyboardEvent): void {
    this.inRegisterPointSelection = false;
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.openedPanel && this.appConf.sideBarContainer) {
      const openedPanelChanges: SimpleChange = changes.openedPanel;
      if (openedPanelChanges.currentValue === '') {
        this.renderer.setStyle(this._sideBarSubMenu.nativeElement, 'transform', `scaleX(0)`);
        this.renderer.setStyle(this._sideBarSubMenu.nativeElement, 'width', `0px`);
      } else if (openedPanelChanges.previousValue === '' || openedPanelChanges.currentValue === 'sidebar.search'
        || openedPanelChanges.previousValue === 'sidebar.search') {
        if (this.openedPanel === 'sidebar.search') {
          this.renderer.setStyle(this._sideBarSubMenu.nativeElement, 'width', `${this._subMenuSearchWidth}px`);
        } else {
          this.renderer.setStyle(this._sideBarSubMenu.nativeElement, 'width', `${this._subMenuWidth}px`);
        }
        this.renderer.setStyle(this._sideBarSubMenu.nativeElement, 'transform', `scaleX(1)`);
      }
    }
  }

  closePanel(): void {
    this.store.dispatch(new ClosePanel());
  }

  handleClickOutside(): void {
    const effectedPanelsWhenClickedOutside: string[] = ['panels.admin', 'panels.help', 'panels.settings', 'panels.profile'];
    if (effectedPanelsWhenClickedOutside.indexOf(this.openedPanel) !== -1) {
      this.closePanel();
    }
  }

  updateWidth(newWidth: string): void {
    if (this.openedPanel === 'sidebar.search') {
      this._subMenuSearchWidth = parseInt(newWidth, DECIMAL_RADIX);
    } else {
      this._subMenuWidth = parseInt(newWidth, DECIMAL_RADIX);
    }
  }

  tempSaveSettingsButton(): void {
    const settings: SettingsStateModel = this.store.selectSnapshot<SettingsStateModel>((state: any) => state.StateSettings);
    this.sessionApiSvc.saveSettings(settings);
  }

  /*genX(): void {
    for (let i = 1; i < 6; i++) {
      this.dialogComService.sendToDialog(i, {x_cord: 123});
    }
  }

  genY(): void {
    for (let i = 1; i < 6; i++) {
      this.dialogComService.sendToDialog(i, {y_cord: 4567});
    }
  }

  genXY(): void {
    for (let i = 1; i < 6; i++) {
      this.dialogComService.sendToDialog(i, {x_cord: 123456, y_cord: 654321});
    }
  }

  generateForAddNewSite(field: string): void {
    if (this.addNewSiteDialogID !== -1) {
      const randomNumber = Math.trunc(Math.random() * 10);
      const objectToSend = {};
      objectToSend[field] = randomNumber;
      this.dialogComService.sendToDialog(this.addNewSiteDialogID, objectToSend);
    }
  }*/

  createModal(): void {
    const inputsBinding: InputsBindingsModel = new Map([
      [ 'strToInput', 'baaaaa'],
      [ 'B', '2' ],
      [ 'C', '3' ]
    ]);
    const dialog: DialogRef = this.dialogService.createDialog(HelloWorldModalComponent, DialogType.Modal, inputsBinding);
    const dialogId: number = dialog.id;

    dialog.onClose$().subscribe((data: DialogModel) => {
      console.log(`Dialog MODAL ${dialogId} CLOSED`, data);
    });
  }

  createModeless(): void {
    const resizeableEdges: Edges = new Edges(true, true, true, true);
    const dialog: DialogRef = this.dialogService.createDialog(HelloWorldModelessComponent, DialogType.Modeless, null, resizeableEdges);
    const dialogComp: HelloWorldModelessComponent = (dialog.instance as HelloWorldModelessComponent);
    dialogComp.x_cord = 'bla';

    const dialogId: number = dialog.id;

    dialog.onChanges$().subscribe((model: DialogModel) => {
      let resString: string = `${dialogId} = ${this.changeCount++}. `;
      Object.keys(model).forEach((key) => {
        resString += `${key}: ${model[key]} `;
      });

      const infoDiv = document.createElement('div');
      const newContent = document.createTextNode(resString);
      infoDiv.appendChild(newContent);
      const debugCont: any = document.getElementById('debugCont');
      debugCont.insertBefore(infoDiv, debugCont.childNodes[5]);

    });

    dialog.onClose$().subscribe((data: DialogModel) => {
      console.log(`Dialog MODELESS ${dialogId} CLOSED`, data);
    });
  }

  onResizeEnd(event: any, thta: any): void {
    thta.style.position = 'fixed';
    thta.style.left = event.rectangle.left + 'px';
    thta.style.top = event.rectangle.top + 'px';
    thta.style.width = event.rectangle.width + 'px';
    thta.style.height = event.rectangle.height + 'px';
  }

  CreateNewPMDlg(): void {
    const inputsBinding: InputsBindingsModel = new Map()
      .set('dialogMode', EditorMode.NEW);
    const dialog: DialogRef = this.dialogService.createDialog(NewEditPlacemarkDialogComponent, DialogType.Modeless, inputsBinding);
    dialog.onClose$().subscribe((data: DialogModel) => {
      console.log(`Dialog NEW PM CLOSED`, data);
    });
  }

  CreateEditPMDlg_url(): void {
    const inputsBinding: InputsBindingsModel = new Map([
      [ 'dialogMode', EditorMode.EDIT],
      [ 'type', 'url']
    ]);
    const dialog: DialogRef = this.dialogService.createDialog(NewEditPlacemarkDialogComponent, DialogType.Modeless, inputsBinding);
  }

  CreateEditPMDlg_file(): void {
    const inputsBinding: InputsBindingsModel = new Map([
      [ 'dialogMode', EditorMode.EDIT],
      [ 'type', 'file']
    ]);
    const dialog: DialogRef = this.dialogService.createDialog(NewEditPlacemarkDialogComponent, DialogType.Modeless, inputsBinding);
  }

  UploadModelDlg(): void {
    // const dialog: DialogRef = this.dialogService.createDialog(UploadEditModelDialogComponent, DialogType.Modeless);
  }

  EditModelDlg(): void {
    // this.fillMock();
    // const inputsBinding: InputsBindingsModel = new Map([
    //   [ 'dialogMode', EditorMode.EDIT]
    // ]);
    // const dialog: DialogRef = this.dialogService.createDialog(UploadEditModelDialogComponent, DialogType.Modeless, inputsBinding);
    // this.editModelDialogId = dialog.id;
    // dialog.onChanges$().subscribe((value: DialogModel) => {
    //   if (value['isSelectionCleared']) {
    //     this.mockSelectedModelForEdit = '-1';
    //   }
    // });
    // dialog.onClose$().subscribe((args: DialogModel) => {
    //
    // });
  }

  NewEditAddressDlg(): void {
    const inputsBinding: InputsBindingsModel = new Map()
      .set('dialogMode',  EditorMode.EDIT);
    const dialog: DialogRef = this.dialogService.createDialog(NewEditAddressDialogComponent, DialogType.Modeless, inputsBinding);
  }

  NewEditPanoramicDlg(): void {
    const inputsBinding: InputsBindingsModel = new Map()
      .set('dialogMode', EditorMode.EDIT);
    const dialog: DialogRef = this.dialogService.createDialog(NewEditPanoramicDialogComponent, DialogType.Modeless, inputsBinding);
  }

  DeleteLayerDlg(): void {
    const inputsBinding: InputsBindingsModel = new Map()
      .set('layerName', 'test');
    const dialog: DialogRef = this.dialogService.createDialog(DeleteLayerDialogComponent, DialogType.Modeless, inputsBinding);
  }

  DeleteZoneDlg(): void {
    const inputsBinding: InputsBindingsModel = new Map()
      .set('zoneName', 'test');
    const dialog: DialogRef = this.dialogService.createDialog(DeleteZoneDialogComponent, DialogType.Modeless, inputsBinding);
  }

  newZone(): void {
    const inputsBinding: InputsBindingsModel = new Map([
      [ 'dialogMode', EditorMode.EDIT]
      ]);
    const dialog: DialogRef = this.dialogService.createDialog(AddEditZoneDialogComponent, DialogType.Modeless, inputsBinding);
  }
}
