import {CoordinatesType, ILocation, IPlacemark, IPosition} from '../../Store/placemarks.state/placemarks.model';
import {GeneralLayerId, ILayer, LAYER_MODE, LAYER_TYPES, LAYER_CREATED_BY_APP} from '../../Store/layers.state/layers.model';
import {DECIMAL_RADIX, DEFAULT_ALTITUE} from '../../common/Definitions/shared.definitions';
import {ISite} from '../../Store/sites.state/sites.model';
import {ICartesianPositionProperty, IViewpoint} from '../../Store/viewpoints.state/viewpoints.model';
import {ILayout} from '../../Store/models.state/models.model';
import {IZone} from '../../Store/zones.state/zones.model';
import {IComment} from '../../Store/discussions.state/discussions.model';
import {isUnicodeString, stringUnicodeConvertor} from '../../stringUnicodeConvertor';
import {statusIconSrcForPanel, checklistIconSrcForPanel, scanIconSrcForPanel} from '../../Store/layers.state/layers.const.utils';
import {ITemplate} from 'src/app/common/Models/UI/templates.interface';

export class ApiTools {
  static defaultSiteId: string;
  static userRoles: string[];
  static showLogsInWebGL: boolean = false;
  static isThroughLoginPage: boolean = false;
  static sessionIndex: number;

  static convertGeneralPMFromResponceToClient(pm: any, pmCategory: string): IPlacemark {
    let layerId: string;
    if (pmCategory === 'PANORAMIC') {
      layerId = GeneralLayerId.PANORAMIC + '';
    } else if (pmCategory === 'ADDRESS') {
      layerId = GeneralLayerId.ADDRESS + '';
    } else if (pmCategory === 'ADDITIONAL_INFO_GROUP') {
      layerId = GeneralLayerId.ADDITIONAL_INFO_GROUP + '';
    } else if (pmCategory === 'QR_CODE') {
      layerId = GeneralLayerId.QR_CODE + '';
    }
    const htmlUrl: string = pm.html.value;
    const locationRef: ILocation = pm.positionProperty ? undefined : {latitude: pm.location.latitude, longitude: pm.location.longitude};
    const positionRef: IPosition = pm.positionProperty ? {x: pm.positionProperty.x, y: pm.positionProperty.y, z: pm.positionProperty.z} : undefined;
    const generalPMData: IPlacemark = {altitude: pm.altitude.altitudeInMeter, category: pmCategory, coordinatesType: CoordinatesType[pm.coordinatesType] as CoordinatesType,
      creatorId: undefined, description: pm.description.value, html: htmlUrl, htmlFileName: pm.htmlFileName, id: pm.id.value,
      name: pm.name.value, parentLayerId: layerId, parentZoneId: pm.parentZoneId.value, placemarkUiType: pm.placemarkUiType,
      positionProperty: positionRef, location: locationRef, settings: {height: pm.settings.height, width: pm.settings.width, sizeType: pm.settings.sizeType},
      style: {showLabelAlways: pm.style ? pm.style.showLabelAlways : false, showLeg: pm.style ? pm.style.showLeg : false}, url: undefined, accessMode: pm.accessMode, speed: pm.speed,
      externalId: pm.externalId ? pm.externalId : []
    };

    return generalPMData;
  }

  static convertSiteFromResponseToClient(site: any): ISite {
    const imgUrltemp: string = site.image.value;
    const siteStruct: ISite = {id: parseInt(site.id.value, DECIMAL_RADIX),
      name: site.name.value, imgUrl: imgUrltemp, description: site.description.value, latitude: site.location.latitude,
      longitude: site.location.longitude, defaultView: site.defaultView.value,
      placemarkLOD: site.placemarkLod.value,
      radiusForPano: site.panoramicPlacemarksLinksRadius.value,
      radiusForPlacemarks: site.panoramicPlacemarksRadius.value, isDefault: site.default ? true : ApiTools.defaultSiteId === site.id.value, accessMode: site.accessMode,
      clustering: site.clustering.value, scale: site.scale.value, rotation: site.rotation.value,
      offsetX: site.offsetX.value, offsetY: site.offsetY.value, offsetZ: site.offsetZ.value,
      panoramicPlacemarksDefaultRotation: site.panoramicPlacemarksDefaultRotation.value,
      registrationPoints: site.registrationPoints, lastViewpoint: ApiTools.convertViewpointFromResponseToClient(site.last),
      webShareConfId: site.webShareConfId ? site.webShareConfId : 0,
      externalId: site.externalId ? site.externalId : []
    };

    // siteStruct.lastViewpoint = undefined;
    if (!siteStruct.lastViewpoint) {
      // fill in def viewpoint
      const defaultVP: IViewpoint = {
        parent: 'MAPS', id: '', name: '',
        parentZoneId: '', accessMode: 'UNDEFINED',
        altitude: DEFAULT_ALTITUE, coordinatesType: 'GIS',
        creatorId: '', description: '', latitude: siteStruct.latitude, longitude: siteStruct.longitude,
        parentLayerId: undefined, rotation: undefined, range: undefined, tilt: undefined, parentPanoramaId: undefined,
        isDefaultInZone: false, positionProperty: undefined, category: 'VIEWPOINT', showOnlySelectedZonesLayers: false
      };

      siteStruct.lastViewpoint = defaultVP;
    }

    return siteStruct;
  }

  static convertViewpointFromResponseToClient(vp: any): IViewpoint {
    if (!vp) {
      return undefined;
    }
    let position: ICartesianPositionProperty;
    if (vp.positionProperty) {
      position = {x: vp.positionProperty.x, y: vp.positionProperty.y, z: vp.positionProperty.z};
    }
    const vpStruct: IViewpoint = {parent: vp.viewpointType, id: vp.id.value, name: vp.name.value,
      parentZoneId: vp.parentZoneId.value, accessMode: vp.accessMode, altitude: vp.altitude.altitudeInMeter, coordinatesType: vp.coordinatesType,
      creatorId: vp.creatorId ? vp.creatorId.value : '', description: vp.description.value, latitude: vp.location.latitude, longitude: vp.location.longitude,
      parentLayerId: vp.parentLayerId, rotation: vp.viewpoint.heading, range: vp.viewpoint.range, tilt: vp.viewpoint.tilt,
      parentPanoramaId: vp.parentPanoramaId ? vp.parentPanoramaId.value : vp.parentPanoramaId,
      isDefaultInZone: vp.defaultInZone, positionProperty: position, category: vp.category, showOnlySelectedZonesLayers: vp.showOnlySelectedZonesLayers,
      selectedLayers: vp.selectedLayers ? new Map(Object.entries(vp.selectedLayers)) : new Map(), selectedZones: vp.selectedZones ? new Map(Object.entries(vp.selectedZones)) : new Map(),
      externalId: vp.externalId ? vp.externalId : []
    };

    return vpStruct;
  }

  static convertLayerFromResponseToClient(layer: any, layerMode: LAYER_MODE): ILayer {
    const isGroupLayer: boolean = layer.children != null && layer.layers != null;
    let layerResult: ILayer;
    let iconUrl: string;
    switch (layer.layerType) {
      case LAYER_TYPES.Status:
        // Status layer created by checklist app should have a different icon.
        if (layer.createdByApp && (layer.createdByApp.value === LAYER_CREATED_BY_APP.CHECKLIST)) {
          iconUrl = checklistIconSrcForPanel;
        } else {
        iconUrl = statusIconSrcForPanel;
        }
        break;
      case LAYER_TYPES.Site:
        iconUrl = layer.iconURL.value !== '' ? layer.iconURL.value : null;
        break;
      case LAYER_TYPES.Group: {
        iconUrl = null;
        break;
      }
      case LAYER_TYPES.Scan: {
        iconUrl = scanIconSrcForPanel;
      }
    }
    layerResult = {parent: layer.parent.empty ? undefined : layer.parent.value,
      id: layer.id.value,
      name: layer.name.value,
      visible: layer.state.visible,
      layerType: isGroupLayer ? LAYER_TYPES.Group : layer.layerType,
      description: layer.description.value,
      expanded: layer.state.expanded,
      logicType: layer.logicType.value,
      statusColors: layer.statusColors.map((color) => color != null ? color.value : null),
      libIconId: layer.libIconId != null ? layer.libIconId.value : null,
      visibleByDefault: layer.visibleByDefault != null ? layer.visibleByDefault.value : true,
      layerStatus: layer.layerStatus,
      accessMode: layer.accessMode,
      scanProjectName: layer.scanProjectName ? layer.scanProjectName.value : null,
      scanProjectBaseUrl: layer.scanProjectBaseUrl ? layer.scanProjectBaseUrl.value : null,
      scanProjectUUID: layer.scanProjectUUID ? layer.scanProjectUUID.value : null,
      pointCloudUUID: layer.pointCloudUUID ? layer.pointCloudUUID.value : null,
      scanResolution: layer.scanResolution,
      iconURL: iconUrl,
      layerMode,
      createdByApp: layer.createdByApp != null ? layer.createdByApp.value : null,
      visualizationMethod: layer.visualizationMethod.value ? layer.visualizationMethod.value : null,
      visualizationConf: layer.visualizationConf ? layer.visualizationConf : null,
      externalId: layer.externalId ? layer.externalId : []
    };
    return layerResult;
  }

  static convertPlacemarkFromResponse(pm: any): IPlacemark {
    const htmlUrl: string = pm.html.value;
    const contentRef: string = pm.url ? pm.url.value : htmlUrl;
    const locationRef: ILocation = pm.coordinatesType === CoordinatesType.GIS ? {latitude: pm.location.latitude, longitude: pm.location.longitude} : undefined;
    const positionRef: IPosition = pm.coordinatesType === CoordinatesType.FACILITY ? {x: pm.positionProperty.x, y: pm.positionProperty.y, z: pm.positionProperty.z} : undefined;
    const name = isUnicodeString(pm.name.value) ? stringUnicodeConvertor(pm.name.value) : pm.name.value;
    const pmData: IPlacemark = {
      altitude: pm.altitude.altitudeInMeter,
      category: pm.category,
      coordinatesType: CoordinatesType[pm.coordinatesType] as CoordinatesType,
      creatorId: pm.creatorId ? pm.creatorId.value : pm.creatorId,
      description: pm.description.value,
      html: htmlUrl,
      htmlFileName: contentRef,
      id: pm.id.value,
      name,
      parentLayerId: pm.parentLayerId.value,
      parentZoneId: pm.parentZoneId.value,
      placemarkUiType: pm.placemarkUiType,
      positionProperty: positionRef,
      location: locationRef,
      speed: pm.speed,
      settings: {
        height: pm.settings.height, width: pm.settings.width, sizeType: pm.settings.sizeType
      },
      style: {
        showLabelAlways: pm.style ? pm.style.showLabelAlways : false,
        showLeg: pm.style ? pm.style.showLeg : false
      },
      url: contentRef,
      accessMode: pm.accessMode,
      showVisualization: pm.showVisualization,
      placemarkData: pm.placemarkData,
      visualizationConf: pm.visualizationConf,
      externalId: pm.externalId ? pm.externalId : [],
      templateId: pm.templateId ? pm.templateId.value : null,
      templateName: pm.templateName ? pm.templateName : '',
    };
    if (pm.statusType) {
      pmData['statusType'] = pm.statusType;
      pmData['selectedStatus'] = pm.selectedStatus;
      pmData['issues'] = pm.issues;
      pmData['eventInfo'] = pm.eventInfo;
    }
    if (pm.scanUUID) {
      pmData.scanUUID = pm.scanUUID;
      pmData.scanResolution = pm.scanResolution;
    }
    return pmData;
  }

  static convertLayoutFromResponceToClient(layout: any): ILayout {
    if (!layout) {
      return undefined;
    }
    const layoutData: ILayout = {accessMode: layout.accessMode, category: layout.category, creatorId: layout.creatorId ? layout.creatorId.value : null,
      description: layout.description.value, id: layout.id.value, imageUrl: layout.imageUrl.value, isLayout: layout.isLayout.value,
      isTiled: layout.isTiled.value, name: layout.name.value, offsetX: layout.offsetX.value, offsetY: layout.offsetY.value, offsetZ: layout.offsetZ.value,
      opacity: layout.opacity.value, parentLayerId: layout.parentLayerId.value, parentZoneId: layout.parentZoneId.value, physicalHeight: layout.physicalHeight.value,
      physicalWidth: layout.physicalWidth.value, rotation: layout.rotation.value, scale: layout.scale.value, tilingLOD: layout.tilingLOD.value, altitude: layout.altitude.altitudeInMeter,
      region: layout.region, geoMapInfo: layout.geoMapInfo, externalId: layout.externalId ? layout.externalId : []};

    return layoutData;
  }

  static convertZoneFromResponceToClient(zone: any): IZone {
    const defaultViewpointIdRes: string = zone.defaultViewpoint ? zone.defaultViewpoint.id.value : undefined;
    const zoneData: IZone = {parent: undefined, id: zone.id.value, name: zone.name.value,
      visible: zone.visible, isDefault: zone.homeZone, description: zone.description.value,
      defaultViewpointId: defaultViewpointIdRes, accessMode: zone.accessMode, visibleByDefault: zone.visibleByDefault,
      externalId: zone.externalId ? zone.externalId : []};

    return zoneData;
  }

  static convertCommentFromResponseToClient(commentObject: any): IComment {
    // console.log('convert comment from: ', commentObject);
    const comment: IComment = {id: commentObject.id.value, accessMode: commentObject.accessMode,
      userId: commentObject.userId.value, userName: commentObject.userName, userFamily: commentObject.userFamily,
      userEmail: commentObject.userEmail, anchorId: commentObject.anchorId.value, creationDate: commentObject.creationDate.value, text: commentObject.text.value,
      isActive: commentObject.isActive.value, parentId: commentObject.parentId.value, numLikes: commentObject.numLikes,
      isCommentLikedByUser: commentObject.commentLikedByUser};
    // console.log('convert to: ', comment);
    return comment;
  }

  static convertTemplateFromResponseToClient(templateObj: any): any {
    const template: ITemplate = {
      name: templateObj.name.value,
      id: templateObj.id.value,
      uiSize: {
        width: templateObj.uiSize.width,
        height: templateObj.uiSize.height
      },
      fileName: templateObj.templatePath.fileName.value,
      originalFile: templateObj.templatePath.originalFile.value,
      templateUrl: templateObj.templateUrl,
      file: null
    };
    return template;
  }

  static isValidUrl(url: string): boolean {
    const regExp: RegExp = RegExp('^(http|https)://(.)+');
    return regExp.test(url);
  }
}
