/* SVG_ICONS is the list of all available icons for use with Mat-Icon object */
/* Using a new SVG in the code requires adding the file to the following object */

export const SVG_ICONS: Map<string, string> = new Map ()
  .set('intosite.logo', 'IntositeLogo.svg')
  .set('intosite.logo.icon', 'IntositeLogoIcon.svg')
  .set('intosite.favicon', 'favicon.png')
  // .set('app.grid-menu', 'app.grid-menu.svg')
  .set('app.siemens-logo', 'app.siemens-logo.svg')
  // .set('app.search', 'app.sidebar.search.svg')
  .set('dropdown.expand-less', 'arrow.drop.up.svg')
  .set('dropdown.expand-more', 'arrow.drop.down.svg')
  .set('arrow.right', 'arrow.right.svg')

  // .set('sidebar.edit', 'sidebar.edit.svg')
  // .set('sidebar.open', 'sidebar.open.svg')
  // .set('sidebar.close', 'sidebar.close.svg')
  // .set('sidebar.open.close.new', 'sidebar.open.close.new.svg')
  // .set('tree.expand-more', 'tree.expand-more.svg')
  // .set('tree.expanded', 'tree.expanded.svg')
  .set('tree.open-group', 'tree.open-group.svg')
  // .set('tree.close-group', 'tree.close-group.svg')

  .set('triangle.layer-add', 'triangle.layer-add.svg')

  .set('sites.load', 'sites_panel/sites.load.new.svg')
  .set('sites.edit', 'sites_panel/sites.edit.new.svg')
  .set('sites.delete', 'sites_panel/sites.delete.new.svg')
  .set('sites.search', 'sites_panel/sites.search.new.svg')

  .set('open.tour', 'tour_management/open.tour.svg')
  .set('copy.link', 'tour_management/copy.link.svg')
  .set('delete.tour', 'tour_management/delete.tour.svg')
  .set('public.tour', 'tour_management/public.tour.svg')

  // .set('app.admin-menu', 'admin_panel/app.admin-menu.svg')
  // .set('menu.activity-report', 'admin_panel/menu.activity-report.svg')
  // .set('menu.user-man', 'admin_panel/menu.user-man.svg')
  // .set('menu.role-man', 'admin_panel/menu.role-man.svg')
  // .set('menu.template-man', 'admin_panel/menu.template-man.svg')
  // .set('menu.tours', 'admin_panel/menu.tours.svg')
  .set('import.obj', 'admin_panel/import.obj.new.svg')

  // .set('app.edit-menu', 'edit_menu/menu.edit.svg')
  // .set('menu.2d', 'edit_menu/menu.2d.svg')
  // .set('menu.3d', 'edit_menu/menu.3d.svg')
  // .set('menu.import', 'edit_menu/menu.import.svg')
  // .set('menu.export', 'edit_menu/menu.export.svg')
  // .set('menu.submenu', 'menu.submenu.svg')

  // .set('app.pm-menu', 'placemark_menu/app.pm-menu.svg')
  // .set('menu.address-pm', 'placemark_menu/menu.address-pm.new.svg')
  // .set('menu.placemark-red', 'placemark_menu/menu.address-pm.svg')
  // .set('menu.pano-pm', 'placemark_menu/menu.pano-pm.new.svg')
  // .set('menu.status-pm', 'placemark_menu/menu.status-pm.svg')
  // .set('menu.qr-pm', 'placemark_menu/menu.qr-pm.svg')

  // .set('app.list-menu', 'home_menu/menu.home.svg')
  // .set('menu.default.user', 'home_menu/menu.user.def.svg')
  // .set('menu.help', 'home_menu/menu.help.svg')
  // .set('menu.link', 'home_menu/menu.link.svg')
  // .set('menu.logout', 'home_menu/menu.logout.new.svg')
  // .set('menu.settings', 'home_menu/menu.settings.svg')
  // .set('menu.admin', 'home_menu/menu.admin.svg')
  // .set('menu.get-link', 'home_menu/menu.get-link.svg')

  // .set('app.fit-screen', 'app.fit-screen.svg')
  // .set('app.ruler', 'app.ruler.svg')

  .set('mode.map', 'viewpoint_mode/map.viewpoints.mode.new.svg')
  .set('mode.facility', 'viewpoint_mode/facility.viewpoints.mode.new.svg')
  .set('mode.panoramic', 'viewpoint_mode/panoramic.viewpoints.mode.new.svg')

  .set('warning', 'dialogs/warning.message.indicator.svg')
  .set('app.datepicker', 'app.datepicker.svg')
  .set('app.arrow.left', 'app.arrow.left.svg')
  .set('app.arrow.right', 'app.arrow.right.svg')
  .set('app.open.date-picker', 'app.open.date-picker.svg')

  .set('app.delete', 'app.delete.svg')
  .set('app.preview', 'app.preview.svg')

  // .set('menu.models', 'models_menu/menu.models.svg')

  // .set('menu.import-objects', 'missing.icon.svg')
  .set('panel.close', 'close/app.close.new.svg')
  .set('dialog.close', 'close/app.close.new.svg')

  .set('alert.info', 'dialogs/info.message.indicator.svg')
  .set('alert.warning', 'dialogs/warning.message.indicator.svg')
  .set('alert.err', 'dialogs/error.message.indicator.svg')
  .set('alert.err.outline', 'dialogs/error.message.outline.indicator.svg')

  .set('password.show', 'dialogs/password.show.svg')
  .set('password.hide', 'dialogs/password.hide.svg')

  .set('input-file.browse', 'ui_components/input-file.browse.svg')

  .set('help.tooltip', 'help.tooltip.svg')
  // .set('drag.indication', 'drag.indication.svg')

  .set('add.placemark.submenu', 'app_right_controllers/add.placemark.submenu.new.svg')
  .set('models.submenu', 'app_right_controllers/menu.models.new.svg')
  .set('measure.action', 'app_right_controllers/measure.action.new.svg')
  .set('fit.action', 'app_right_controllers/fit.action.new.svg')
  .set('move.action', 'app_right_controllers/move.action.svg')
  .set('arrow.submenu', 'app_right_controllers/arrow.submenu.svg')
  .set('expand.menu', 'app_right_controllers/mat.expand.menu.svg')

  .set('sidebar.search', 'side_bar/search.new.svg')
  .set('sidebar.sites', 'side_bar/sites.new.svg')
  .set('sidebar.zones', 'side_bar/zones.new.svg')
  .set('sidebar.layers', 'side_bar/layers.new.svg')
  .set('sidebar.viewpoints', 'side_bar/viewpoints.new.svg')
  .set('sidebar.discussion', 'side_bar/discussion.new.svg')
  .set('panels.admin', 'side_bar/admin.new.svg')
  .set('panels.help', 'side_bar/help.new.svg')
  .set('panels.settings', 'side_bar/settings.new.svg')
  .set('panels.profile', 'side_bar/user.svg')
  .set('panels.profile.light', 'side_bar/user.light.svg')

  .set('facility.mode', 'app_right_controllers/cmdPlantView24.svg')
  .set('map.mode', 'app_right_controllers/cmdMapView24.svg')

  .set('template.management', 'admin_panel/template.management.svg')
  // .set('tours.management', 'admin_panel/tours.management.svg')

  .set('documentation', 'help_panel/documentation.svg')
  .set('tutorials', 'help_panel/tutorials.svg')

  .set('signout', 'home_menu/menu.logout.new.svg')

  // .set('1', 'layers-mock/1.svg')
  // .set('2', 'layers-mock/2.svg')
  // .set('3', 'layers-mock/3.svg')
  // .set('4', 'layers-mock/4.svg')
  // .set('5', 'layers-mock/5.svg')
  // .set('6', 'layers-mock/6.svg')
  // .set('7', 'layers-mock/7.svg')
  // .set('8', 'layers-mock/8.svg')
  // .set('9', 'layers-mock/9.svg')
  // .set('10', 'layers-mock/10.svg')
  // .set('11', 'layers-mock/11.svg')
  // .set('12', 'layers-mock/12.svg')
  // .set('13', 'layers-mock/13.svg')
  // .set('14', 'layers-mock/14.svg')

  .set('cmd.remove', 'uploadEditLayoutDialog/cmdRemove16.svg')

  .set('edit.right.click.placemark', 'right_click_menu_placemark/edit.right.click.placemark.svg')
  .set('delete.right.click.placemark', 'right_click_menu_placemark/delete.right.click.placemark.svg')
  .set('history.right.click.placemark', 'right_click_menu_placemark/history.right.click.placemark.svg')
  .set('discussion.right.click.placemark', 'right_click_menu_placemark/discussion.right.click.placemark.svg')
  .set('print.right.click.placemark', 'right_click_menu_placemark/print.right.click.placemark.svg')
  .set('eventinfo.right.click.placemark','right_click_menu_placemark/eventinfo.right.click.placemark.svg')

  .set('like.discussion', 'discussion/like.discussion.svg')
  .set('comment.discussion', 'discussion/comment.discussion.svg')
  .set('delete.discussion', 'discussion/delete.svg')
  .set('reply.discussion', 'discussion/reply.svg')

  /*.set('addres.pin.search.result', 'search/addres.pin.search.result.svg')
  .set('facility.viewpoint.search.result', 'search/facility.viewpoint.search.result.svg')
  .set('placemark.search.result', 'search/placemark.search.result.svg')*/

  .set('placemarks.search.filter', 'search_panel/indicatorPlacemarkResultLine16.svg')
  .set('address.search.filter', 'search_panel/indicatorAddressResultLine16.svg')
  .set('panoramic.search.filter', 'search_panel/indicatorPanoramicResultLine16.svg')
  .set('viewpoints.search.filter', 'search_panel/indicatorFacilityViewPointLine16.svg')
  .set('model.search.filter', 'search_panel/indicatorFacilityModelLine16.svg')

  .set('placemarks.search.result', 'search_panel/indicatorPlacemarkResult16.svg')
  .set('address.search.result', 'search_panel/indicatorAddressResult16.svg')
  .set('panoramic.search.result', 'search_panel/indicatorPanoramicResult16.svg')
  .set('viewpoints.search.result', 'search_panel/indicatorFacilityViewPoint16.svg')
  .set('mapLocation.search.result', 'search_panel/indicatorMapViewPoint16.svg')
  .set('scanPlacemark.search.result', 'search_panel/indicatorScanPlacemarkResult16.svg')
  .set('model.search.result', 'search_panel/indicatorModelResult16.svg')

  .set('intositeBlack', 'login_page/IntositeLogoBlack.svg')
  .set('sie.logo', 'login_page/sieLogo.svg')
  .set('playTutorials', 'login_page/playTutorials.svg')
  .set('successIcon', 'login_page/requestSubmitted.svg')
  .set('chromeIcon', 'login_page/chrome.svg')
  .set('barchart', 'dynamic_pm/bar_chart.svg')
  .set('piechart', 'dynamic_pm/pie_chart.svg')
  .set('table', 'dynamic_pm/table.svg')
  .set('statustag', 'dynamic_pm/status_icon.svg')
  .set('pencilIcon', 'dynamic_pm/pencil_icon.svg')
  .set('showIcon', 'dynamic_pm/show_icon.svg')
  .set('hideIcon', 'dynamic_pm/hide_icon.svg')
  .set('addIcon', 'dynamic_pm/add_icon.svg')
  .set('removeIcon', 'dynamic_pm/remove_icon.svg')
  .set('copy', 'copy.svg')
  .set('scan.layer', 'layers-icons/scan_layer.svg')
  .set('pointCloudConf.link', 'point_cloud_conf_link.svg')
  .set('connect', 'connect.svg')
  .set('downloadIcon', 'download.svg')
  // .set('operation.error', 'operation_status_board/errorOutline.svg')
  // .set('operation.success', 'operation_status_board/successOutline.svg')
  // .set('operation.inprogress', 'operation_status_board/inprogressOutline.svg')
  .set('refresh', 'refresh.svg')
  .set('operation.error', 'operation_status_board/status.failed.svg')
  .set('operation.success', 'operation_status_board/status.completed.svg')
  .set('operation.inprogress', 'operation_status_board/status.inprogress.svg')
  .set('status.link', 'operation_status_board/status.link.svg')
;
