import {IAppConf, SCENE_MODE} from './app.model';
import {ISite} from '../sites.state/sites.model';
import {IViewpoint} from '../viewpoints.state/viewpoints.model';
import {ObjectsOnWebGLConsumingLoadingTime} from './app.state';
import {ILimitedAccessConf} from 'src/app/common/Models/UI/limited-access-conf.model';
import {ICompanyInfo, IWebShareConf} from 'src/app/common/Models/UI/company-info.interface';

export class SetSelectedMode {
  static readonly type: string = '[App] SetSelectedMode';

  constructor(public payload: SCENE_MODE, public saveNewViewpoint: boolean = true) {}
}

export class SetOpenedPanel {
  static readonly type: string = '[App] SetOpenedPanel';

  constructor(public payload: string) {}
}

export class SetDiscussionOpenedPanel {
  static readonly type: string = '[App] SetDiscussionOpenedPanel';

  constructor(public payload: string, public isOpen: boolean) {}
}

export class SetCompanyInfo {
  static readonly type: string = '[App] SetCompanyInfo';

  constructor(public companyInfo: ICompanyInfo) {}
}

export class SetWebshareSession {
  static readonly type: string = '[App] SetWebshareSession';

  constructor(public webshareSession: IWebShareConf) {}
}


export class RemoveWebshareSession {
  static readonly type: string = '[App] RemoveWebshareSession';

  constructor() {}
}

export class SetActiveSite {
  static readonly type: string = '[App] SetActiveSite';

  constructor(public site: ISite, public openInMode?: SCENE_MODE) {}
}

// use this with caution, this action operates the 'loadSitePlugin' which cleans all the store!!!
// if you are not sure, please consult with or.gal@siemnes.com
export class LoadSite {
  static readonly type: string = '[App] LoadSite';

  constructor(public site: ISite, public openInMode?: SCENE_MODE) {}
}

export class SetGoogleBlocked {
  static readonly type: string = '[App] SetGoogleBlocked';

  constructor(public blocked: boolean) {}
}

export class SetAppConf {
  static readonly type: string = '[App] SetAppConf';

  constructor(public appConf: IAppConf) {}
}

export class SetLimitedAccessConf {
  static readonly type: string = '[App] SetLimitedAccessConf';

  constructor(public limitedAccessConf: ILimitedAccessConf) {}
}

export class ClosePanel {
  static readonly type: string = '[App] ClosePanel';

  constructor() {}
}

export class SetActiveModelessDialogID {
  static readonly type: string = '[App] SetActiveModelessDialogID';

  constructor(public payload: number) {}
}

export class ClearScene {
  static readonly type: string = '[App] ClearScene';

  constructor() {}
}

/*export class SetCurrViewpoint {
  static readonly type: string = '[App] SetCurrViewpoint';

  constructor(public payload: IViewpoint) {}
}*/

export class UpdateCurrViewpointParameters {
  static readonly type: string = '[App] UpdateCurrViewpointParameters';

  constructor(public params: any) {}
}

export class GoToProxyViewpoint {
  static readonly type: string = '[App] GoToProxyViewpoint';

  constructor(public vp: IViewpoint) {}
}

export class ToggleRightMenu {
  static readonly type: string = '[App] ToggleRightMenu';

  constructor() {}
}

export class Logout {
  static readonly type: string = '[App] Logout';

  constructor() {}
}

export class SetObjectsNumberToBeLoaded {
  static readonly type: string = '[App] SetObjectsNumberToBeLoaded';
  constructor(public objectsOnWebGLConsumingLoadingTime: ObjectsOnWebGLConsumingLoadingTime, public num: number) {}
}

export class SetEmptyActiveSite {
  static readonly type: string = '[App] SetEmptyActiveSite';
  constructor() {}
}
