import {getActionTypeFromInstance} from '@ngxs/store';
import {LoadSite, Logout} from './app.state/app.actions';
import {SettingsStateModel} from './settings.state/settings.state';
import {ZonesStateModel} from './zones.state/zones.state';
import {LayerStateModel} from './layers.state/layers.state';
import {PlacemarksStateModel} from './placemarks.state/placemarks.state';
import {ViewpointStateModel} from './viewpoints.state/viewpoints.state';
import {AppStateModel} from './app.state/app.state';
import {ModelsStateModel} from './models.state/models.state';
import {SitesStateModel} from './sites.state/sites.state';
import {isNullOrUndefined} from 'util';
import {ApiTools} from '../services/api.services/api.tools';

export function cleanStorePlugin(state: any, action: any, next: any): any {

  if (getActionTypeFromInstance(action) === LoadSite.type || getActionTypeFromInstance(action) === Logout.type) {
    const loadSiteAction: LoadSite = action;

    // all states should be return to their default status
    state.StateModels = new ModelsStateModel();
    const searchMode: boolean = !isNullOrUndefined(loadSiteAction.openInMode);
    state.StateApp = new AppStateModel(searchMode ? 'sidebar.search' : undefined,
      state.StateApp.googleBlocked, state.StateApp.logoutToggleIndex, state.StateApp.limitedAccessConf,
      state.StateApp.companyInfo, state.StateApp.webshareSession, state.StateApp.appConf);
    state.StateSettings = new SettingsStateModel();
    state.StateZones = new ZonesStateModel();
    state.StateLayers = new LayerStateModel();
    state.StatePlacemarks = new PlacemarksStateModel();
    state.StateViewpoints = new ViewpointStateModel();

  }

  if (getActionTypeFromInstance(action) === Logout.type) {
    state.StateSites = new SitesStateModel();
    if(ApiTools.defaultSiteId) {
      ApiTools.defaultSiteId = undefined;
      ApiTools.userRoles = undefined;
    }
  }
  // return the next function with the current state
  return next(state, action);
}
