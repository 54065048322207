/// <reference types="@types/googlemaps" />
import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild
} from '@angular/core';
import {ISite} from '../../../../Store/sites.state/sites.model';
import {CMD_ACTIONS, CMD_TARGETS, CmdRouterService, IActionCmd} from '../../../../services/cmd-router.service';
import {DialogComSvc} from '../../../../services/modeless-com.service';
import {DialogRef} from '../../../../common/Forms/Dialog-types/dialog-ref';
import {DialogModel} from '../../../../common/Models/Dialog/dialog.model';
import {PlacemarksState} from '../../../../Store/placemarks.state/placemarks.state';
import {Select, Store} from '@ngxs/store';
import {Observable, interval} from 'rxjs';
import {
  CoordinatesType,
  ILocation,
  IPlacemark,
  TEMP_PLACEMARK_ICON,
  WEBGL_OBJECT_MODE
} from '../../../../Store/placemarks.state/placemarks.model';
import {ILayer, LAYER_TYPES, LAYER_VISIBILITY} from '../../../../Store/layers.state/layers.model';
import {LibIconsService} from '../../../../services/lib-icons.service';
import {PlacemarkPreviewService} from '../../../../services/placemark-preview.service';
import {LayersState} from '../../../../Store/layers.state/layers.state';
import {ZonesState} from '../../../../Store/zones.state/zones.state';
import {IZone} from '../../../../Store/zones.state/zones.model';
import {generalLayersIds} from '../scene.container';
import {IViewpoint} from '../../../../Store/viewpoints.state/viewpoints.model';
import {DEFAULT_ZOOM_LEVEL, ViewpointsHelper} from '../../../../Store/viewpoints.state/viewpoints.helper';
import {debounceAtEnd} from '../../../../helper';
import {UpdateCurrViewpointParameters} from '../../../../Store/app.state/app.actions';
import {SiteWhenPlacemarksAreVisible} from '../../../../Store/sites.state/sites.utils';
import {ContainersHelper} from '../webgl-container/containers-helper';
import {environment} from '../../../../../environments/environment';
import {ServerApi} from '../../../../services/api.services/server.api';
import {StatusService} from '../../../../services/status.service';
import {SessionApiSvc} from '../../../../services/api.services/session.api.svc';
import {StatusCommentsIcon} from '../../../../Store/discussions.state/discussions.model';
import {SetPlacemarksWithComments} from '../../../../Store/discussions.state/discussions.actions';
import {SingleSearchResult} from '../../side-bar.container/subMenus/search-res-menu/search-res-menu.component';
import {PlacemarksApiSvc} from '../../../../services/api.services/placemarks.api.svc';
import {cloneDeep} from 'lodash';
import {ApiTools} from '../../../../services/api.services/api.tools';
// import GMap = google.maps.Map;
// import InfoWindow = google.maps.InfoWindow;
// import Marker = google.maps.Marker;
// import LatLng = google.maps.LatLng;
// import Projection = google.maps.Projection;
// import ControlPosition = google.maps.ControlPosition;
// import Icon = google.maps.Icon;
// import Point = google.maps.Point;
// import MapsEventListener = google.maps.MapsEventListener;
// import LatLngBounds = google.maps.LatLngBounds;
// import Geometry = google.maps.geometry;
import {AppState} from '../../../../Store/app.state/app.state';
import {isNullOrUndefined} from 'util';
import {ObjectSiteValidatorService} from '../../../../services/object-site-validator.service';
import {DiscussionsState} from '../../../../Store/discussions.state/discussions.state';
import {SitesLoaderSvc} from '../../../../services/api.services/sites.loader.svc';
import {SCENE_MODE} from 'src/app/Store/app.state/app.model';
import {DynamicPlacemarkService} from 'src/app/services/dynamic-placemark.service';
import {take} from 'rxjs/operators';

const DEFAULT_SINGLE_PM_ZOOM_LEVEL: number = 20;
const PLACEMARK_SIZE: number = 32;
const enum Z_INDEX_CONSTS {
  SITE = 100000,
  PLACEMARK,
  CURRENT_SITE,
  SEARCH_RESULT,
  EDIT_CREATE
}
@Component({
  selector: 'ins-maps-container',
  templateUrl: './maps-container.component.html',
  styleUrls: ['./maps-container.component.scss']
})
export class MapsContainerComponent implements OnInit, OnChanges {

  private _draggablePin: google.maps.Marker;
  private _sitesMarkers: Map<number /*siteId*/, google.maps.Marker> = new Map<number, google.maps.Marker>();
  private placemarkMarkers: Map<string, google.maps.Marker> = new Map();
  private searchResultsMarkers: Map<string, google.maps.Marker> = new Map();
  private tempPM: IPlacemark[] = [];
  private _rightClickLat: number;
  private actionListeners: Map<string, google.maps.MapsEventListener> = new Map<string, google.maps.MapsEventListener>();
  private zoomLevel: number = 18;
  private placemarkBasicVisibilityMap: Map<string, boolean> = new Map();
  private readonly RADIUS: number = 6371000;
  private readonly NEAR_DISTANCE_ZOOMLEVELL_2: number = 484548;
  private readonly DISTANCE_BETWEEN_PLACEMARKS_RATIO: number = 2;
  private infoWindow: google.maps.InfoWindow;
  private inMesaureMode: boolean = false;
  private placemarkInfoWindow: google.maps.InfoWindow;
  set rightClickLat(val: number) {
    this._rightClickLat = val;
  }
  get rightClickLat(): number {
    const temp = this._rightClickLat;
    this._rightClickLat = undefined;
    return temp;
  }

  private _rightClickLng: number;
  set rightClickLng(val: number) {
    this._rightClickLng = val;
  }
  get rightClickLng(): number {
    const temp = this._rightClickLng;
    this._rightClickLng = undefined;
    return temp;
  }

  @Output() openContextMenu: EventEmitter<any> = new EventEmitter();
  @ViewChild('mapContent', {static: true}) _mapContent: ElementRef;
  @Input() sites: ISite[];
  @Input() activeSiteInfo: ISite;
  @Input() openedPanel: string;
  public lastOpenedPanel: string;
  @Select(PlacemarksState.getPlacemarksByType(CoordinatesType.GIS, WEBGL_OBJECT_MODE.NEW)) newPlacemarks$: Observable<IPlacemark[]>;
  @Select(PlacemarksState.getPlacemarksByType(CoordinatesType.GIS, WEBGL_OBJECT_MODE.UPDATED)) updatePlacemarks$: Observable<IPlacemark[]>;
  @Select(LayersState.getVisiblityChangedLayers) layers$: Observable<ILayer[]>;
  @Select(ZonesState.getVisibleZones) visibleZones$: Observable<IZone[]>;
  @Select(LayersState.getChangedLayers) changedLayers$: Observable<ILayer[]>;
  @Select(AppState.getLogoutToggleIndex) logoutToggleIndex$: Observable<number>;
  @Select(DiscussionsState.getSelectedPlacemark) getSelectedPlacemark$: Observable<IPlacemark>;


  public mapRef: google.maps.Map;
  public overlayView: google.maps.OverlayView = new google.maps.OverlayView();

  public sceneMode: SCENE_MODE;
  @Select(AppState.getSelectedMode) selectedMode$: Observable<SCENE_MODE>;
  constructor(private cmdRouterSvc: CmdRouterService, private dialogComSvc: DialogComSvc, public libIconSrv: LibIconsService, public store: Store,
              private placemarkPreviewService: PlacemarkPreviewService, private containersHelper: ContainersHelper, public serverApi: ServerApi,
              private statusBar: StatusService, public sessionApiSvc: SessionApiSvc, public placemarksApiSvc: PlacemarksApiSvc,
              private objectSiteValidatorService: ObjectSiteValidatorService, public sitesLoaderSvc: SitesLoaderSvc, private dynamicPlacemarkService: DynamicPlacemarkService) {
    this.logoutToggleIndex$.subscribe((index: number) => {
      if (index > 0) {
        if (this._sitesMarkers) {
          this._sitesMarkers.forEach((marker: google.maps.Marker) => {
            this.removeMarker(marker.get('id'));
          });
        }
      }
    });
    this.selectedMode$.subscribe((mode: SCENE_MODE) => {
      this.sceneMode = mode;
      this.updateDynamicPlacemarkProperties(this.placemarkMarkers);
    });
  }

  private clearAllPlacemarks(): void {
    this.placemarkMarkers.forEach((marker: google.maps.Marker, id: string) => {
      this.removePMMarker(id);
    });
    this.dynamicPlacemarkService.deleteAllPlacemarks();
  }

  private initActionCmdHandler(): void {
    this.cmdRouterSvc.actionCmdListener$(CMD_TARGETS.MAP_MANAGER)
      .subscribe((actionCmd: IActionCmd) => {
        switch (actionCmd.action) {
          case CMD_ACTIONS.CLEAR_SCENE: {
            this.clearAllPlacemarks();
            break;
          }
          case CMD_ACTIONS.FIT: {
            this.resetZoom();
            break;
          }
          case CMD_ACTIONS.CREATE_EDIT_SITE_MODE: {
            const siteId: number = actionCmd.options['siteId'];
            const site: ISite = actionCmd.options['site'];
            if (siteId) {
              // for editing site mode
              const existingMarker: google.maps.Marker = this._sitesMarkers.get(siteId);
              existingMarker.setIcon({url: `${environment.baseHref}/assets/map/yellow.pin.site.map.indicator.png`, scaledSize: {height: PLACEMARK_SIZE, width: PLACEMARK_SIZE}} as google.maps.Icon);
              existingMarker.setZIndex(Z_INDEX_CONSTS.EDIT_CREATE); // Priority display pin marker above other markers
              this._draggablePin = existingMarker
              this._draggablePin.setDraggable(true);
              this._draggablePin.setIcon({url: `${environment.baseHref}/assets/map/yellow.pin.site.map.indicator.png`, scaledSize: {height: PLACEMARK_SIZE, width: PLACEMARK_SIZE}} as google.maps.Icon);
              this._draggablePin.addListener('drag', (event: any) => {
                this.dialogComSvc.sendToActiveDialog({latitude: event.latLng.lat(), longitude: event.latLng.lng()});
              });

              // // Uncomment if Viewpoint needs to change while updating site.
              // let viewpoints: IViewpoint[] = this.serverApi.storeSelectSnap<IViewpoint[]>((state: any) => state.StateViewpoints.viewpoints);
              // viewpoints = viewpoints.filter((vp: IViewpoint) => vp.parent === 'MAPS' && vp.isDefaultInZone);
              const center: google.maps.LatLng = new google.maps.LatLng(site.latitude, site.longitude);
              this.mapRef.setCenter(center);
              this.mapRef.setZoom(ViewpointsHelper.altitudeToZoom(92000));
            } else {
              // for new site mode
              this.createDraggableMarker(`${environment.baseHref}/assets/map/yellow.pin.site.map.indicator.png`, true, );
            }

            const dialogRef: DialogRef = actionCmd.options['dialogRef'];
            dialogRef.onChanges$().subscribe((data: DialogModel) => {
              // Update lat long when change the value in the text box.
              // The site needs to move on the map
              this.coordinatesChanged(data['latitude'], data['longitude']);
            });

            dialogRef.onClose$().subscribe((data: DialogModel) => {
              if (siteId) {
                // for editing site mode
                const existingMarker: google.maps.Marker = this._sitesMarkers.get(siteId);
                if (data.userAction !== 'add') {
                  existingMarker.setPosition({lat: site.latitude, lng: site.longitude});
                }
                const isActiveSite: boolean = this.activeSiteInfo.id === siteId;
                const urlToSiteIcon = isActiveSite ? `${environment.baseHref}/assets/map/blue.pin.site.map.indicator.png` : `${environment.baseHref}/assets/map/red.pin.site.map.indicator.png`;
                existingMarker.setIcon({url: urlToSiteIcon, scaledSize: {height: PLACEMARK_SIZE, width: PLACEMARK_SIZE}} as google.maps.Icon);
                existingMarker.setZIndex(isActiveSite ? Z_INDEX_CONSTS.CURRENT_SITE : Z_INDEX_CONSTS.SITE);
                this.removeDraggableMarker(false);
              } else {
                // for new site mode
                this.removeDraggableMarker(true);
              }
            });
            break;
          }
          case CMD_ACTIONS.DELETE_SITE: {
            const siteId: number = actionCmd.options['siteId'];
            this.removeMarker(siteId);
            break;
          }
          case CMD_ACTIONS.DELETE_PM: {
            const pmId: string = actionCmd.options['pmId'];
            this.removePMMarker(pmId);
            this.dynamicPlacemarkService.deletePlacemark(pmId);
            break;
          }
          case CMD_ACTIONS.COORDINATES_CHANGED: {
            this.coordinatesChanged(actionCmd.options['latitude'], actionCmd.options['longitude']);
            break;
          }

          case CMD_ACTIONS.VP_NEW_EDIT_MODE: {
            const dialogRef: DialogRef = actionCmd.options['dialogRef'];
            this.applyMapViewpointChangesListener(true);
            dialogRef.onClose$().subscribe((data: DialogModel) => {
              // get out of the listen change mode
              this.applyMapViewpointChangesListener(false);
            });
            break;
          }
          case CMD_ACTIONS.GO_TO_VP_MODE: {
            const vp: IViewpoint = actionCmd.options['selectedVP'];
            if (vp !== undefined) {
              if (vp.coordinatesType === CoordinatesType.GIS) {
                const lat: number = actionCmd.options['lat'];
                const lng: number = actionCmd.options['lng'];
                if (!isNullOrUndefined(lat) && !isNullOrUndefined(lng)) {
                  this.goToViewpoint(lat, lng, DEFAULT_ZOOM_LEVEL);
                } else {
                  this.goToViewpoint(vp.latitude, vp.longitude, ViewpointsHelper.altitudeToZoom(vp.altitude));
                }
              }
            }
            break;
          }
          case CMD_ACTIONS.CREATE_PLACEMARK: {
            const newMode: boolean = actionCmd.options['newMode'];
            const pmId: string = actionCmd.options['pmId'];
            // TODO: url change
            this.createDraggableMarker(environment.windowOrigin + '/' + TEMP_PLACEMARK_ICON, newMode, pmId);

            const lat = this.rightClickLat;
            const lng = this.rightClickLng;
            if (lat != null && lng != null) {
              this.dialogComSvc.sendToActiveDialog({latitude: lat, longitude: lng});
              this.coordinatesChanged(lat, lng);
            }
            const dialogRef: DialogRef = actionCmd.options['dialogRef'];
            dialogRef.onChanges$().subscribe((data: DialogModel) => {
              if ((data['latitude']  && !isNaN(Number(data['latitude']))) || (data['longitude'] && !isNaN(Number(data['longitude'])))) {
                this.coordinatesChanged(data['latitude'], data['longitude']);
              } else if (data['name']) {
                this.setTitleForDraggable(data['name']);
              } else if (data['layerId']) {
                this.setIconForDraggable(this.getPlacemarkIconForLayer(data['layerId']));
              }
            });
            dialogRef.onClose$().subscribe((data: DialogModel) => {
              if (data.userAction === 'cancel' && !newMode) {
                const beforeUpdatePMlocationProperty: ILocation = actionCmd.options['location'];
                this.coordinatesChanged(beforeUpdatePMlocationProperty.latitude, beforeUpdatePMlocationProperty.longitude);
                const oldPm: IPlacemark  = actionCmd.options['oldPm'];
                if (oldPm != null) {
                  this.setTitleForDraggable(oldPm.name);
                  if (!oldPm.loadedOnDemand) {
                    const imageURL: string = this.getPlacemarkIconForLayer(oldPm.parentLayerId, oldPm);

                    // If we in discussion panel and edit PM that have discussion and cancel the edit, we need to comeback to the discussion PM icon
                    if (this.openedPanel === '' && this.lastOpenedPanel === 'sidebar.discussion') {
                      let statusIconUrl: string;
                      this.getSelectedPlacemark$.subscribe((pmId: IPlacemark) => {
                        var isSelectedPlacemark: boolean = pmId.id === oldPm.id ? true : false;

                        if (isSelectedPlacemark) {
                          if (oldPm.statusType === 'STATUS' || oldPm.statusType === 'CHECKLIST') {
                            statusIconUrl =
                              imageURL.substring(0, imageURL.lastIndexOf('.')) + StatusCommentsIcon.COMMENTS_SELECTED;
                          } else {
                            statusIconUrl =
                              imageURL.substring(0, imageURL.lastIndexOf('/') + 1) + StatusCommentsIcon.STATUS + StatusCommentsIcon.COMMENTS_SELECTED
                              + imageURL.substring(imageURL.indexOf('?'));
                          }
                        } else {
                          if (oldPm.statusType === 'STATUS' || oldPm.statusType === 'CHECKLIST') {
                            statusIconUrl =
                              imageURL.substring(0, imageURL.lastIndexOf('.')) + StatusCommentsIcon.COMMENTS;
                          } else {
                            statusIconUrl =
                              imageURL.substring(0, imageURL.lastIndexOf('/') + 1) + StatusCommentsIcon.STATUS + StatusCommentsIcon.COMMENTS
                              + imageURL.substring(imageURL.indexOf('?'));
                          }
                        }

                        this.setIconForDraggable(statusIconUrl);
                      }).unsubscribe();

                    } else {
                      this.setIconForDraggable(imageURL);
                    }

                  }
                }
                this.updateDynamicPlacemarkProperties(this.placemarkMarkers);
              }

              this.removeDraggableMarker(newMode);
            });
            break;
          }
          case CMD_ACTIONS.GO_TO_PM_MODE: {
            if (actionCmd.options['latitude'] || actionCmd.options['longitude']) {
              this.coordinatesChanged(actionCmd.options['latitude'], actionCmd.options['longitude']);
            }
            break;
          }
          case CMD_ACTIONS.CLOSE_MAP_INFO_WINDOW: {
            if (this.infoWindow) {
              this.infoWindow.close();
            }
            break;
          }
          case CMD_ACTIONS.FILL_VIEWPOINT_PROPERTIES : {
            const center: google.maps.LatLng = this.mapRef.getCenter();
            const params: any = {
              parent: 'MAPS',
              coordinatesType: 'GIS',
              latitude: center.lat(),
              longitude: center.lng(),
              altitude: ViewpointsHelper.zoomToAltitude(this.mapRef.getZoom()),
            };
            this.store.dispatch(new UpdateCurrViewpointParameters(params));
            break;
          }
          case CMD_ACTIONS.DISPLAY_PM_COMMENTS_ICONS: { // Change placemark icon for discussion panel
            const placemarkToEdit: IPlacemark = actionCmd.options['placemarkEdit'];
            const isSelectedPlacemark: boolean = actionCmd.options['isSelectedPlacemark'];
            this.showPlacemarkCommentsIcon(placemarkToEdit, isSelectedPlacemark);
            break;
          }
          case CMD_ACTIONS.CANCEL_DISPLAY_PM_COMMENTS_ICONS: { // Cancel changed placemark icon for discussion panel
            const placemarksWithComments: Map<string, string> = actionCmd.options['placemarksWithComments'];
            this.cancelCommentIconOnPlacemark(placemarksWithComments);
            break;
          }
          case CMD_ACTIONS.SHOW_SEARCH_RESULTS: {
            const searchResults: SingleSearchResult[] = actionCmd.options['searchResults'];
            this.addSearchResultPlacemarks(searchResults);
            break;
          }
          case CMD_ACTIONS.CENTER_SEARCH_RESULT: {
            const lat: number = actionCmd.options['lat'];
            const lng: number = actionCmd.options['lng'];
            this.goToViewpoint(lat, lng, DEFAULT_SINGLE_PM_ZOOM_LEVEL);
            break;
          }
          case CMD_ACTIONS.REMOVE_SEARCH_RESULTS: {
            this.removeSearchPMMarkers();
            break;
          }
          case CMD_ACTIONS.OPEN_CENTER_SEARCH_RESULT: {
            const pm: IPlacemark = actionCmd.options['pm'];
            this.showPlacemarkContent(pm);
            break;
          }
          case CMD_ACTIONS.MEASURE: {
            this.inMesaureMode = true;
            const radius: number = 1609;
            const currentSite: ISite = this.sites.find((site: ISite) => site.id === +ApiTools.defaultSiteId);
            const origin: google.maps.LatLng = new google.maps.LatLng(currentSite.latitude, currentSite.longitude);
            const circle = new google.maps.Circle({
              fillColor: '#FF0000',
              strokeColor: '#FF0000',
              fillOpacity: 0.35,
              radius,
              center: origin
            });
            circle.setMap(this.mapRef);
            const markerPosition: google.maps.LatLng = google.maps.geometry.spherical.computeOffset(new google.maps.LatLng(currentSite.latitude, currentSite.longitude), radius, 90);
            const dragMarker: google.maps.Marker = new google.maps.Marker({
              clickable: true,
              position: markerPosition,
              icon: `${environment.baseHref}/assets/map/red.dot.png`,
              draggable: true
            });
            dragMarker.setMap(this.mapRef);
            const infoWindowForMeasure: google.maps.InfoWindow = new google.maps.InfoWindow();
            infoWindowForMeasure.setContent(`<div style="color: black;text-align:center; height:20px;width:70px">${Math.round(radius * 0.0621371) / 100.0} miles</div>`);
            infoWindowForMeasure.open(this.mapRef, dragMarker);
            const dragMarkerListener: any = dragMarker.addListener('drag', () => {
              const currPos: google.maps.LatLng = dragMarker.getPosition();
              const dist: number = google.maps.geometry.spherical.computeDistanceBetween(currPos, origin);
              circle.setRadius(dist);
              infoWindowForMeasure.setContent(`<div style="color: black;text-align:center; height:20px;width:70px">${Math.round(dist * 0.0621371) / 100.0} miles</div>`);
            });
            const closeClickListener: any = infoWindowForMeasure.addListener('closeclick', () => {
              circle.setMap(null);
              dragMarker.setMap(null);
              google.maps.event.removeListener(closeClickListener);
              google.maps.event.removeListener(dragMarkerListener);
            });
            break;
          }
          case CMD_ACTIONS.REMOVE_LAST_ACTIVE_PANEL: {
            this.lastOpenedPanel = undefined;
            break;
          }
          case CMD_ACTIONS.CLOSE_ACTIVE_PANEL: {
            this.lastOpenedPanel = this.openedPanel;
            break;
          }
        }
      });
  }

  private cancelCommentIconOnPlacemark(placemarksWithComments: Map<string, string>): void {
    placemarksWithComments.forEach((iconUrl, pmId) => {
      const marker = this.placemarkMarkers.get(pmId);
      if (marker) {
        marker.setIcon({url: iconUrl, scaledSize: {height: PLACEMARK_SIZE, width: PLACEMARK_SIZE}} as google.maps.Icon);
        this.placemarkMarkers.set(pmId, marker);
      }
    });
  }

  private showPlacemarkCommentsIcon(placemarkToEdit: IPlacemark, isSelectedPlacemark: boolean = false): void {
    const marker = this.placemarkMarkers.get(placemarkToEdit.id);
    if (marker) {
      let statusIconUrl: string;
      const imageURL: string = this.getPlacemarkIconForLayer(placemarkToEdit.parentLayerId, placemarkToEdit);
      this.store.dispatch(new SetPlacemarksWithComments(placemarkToEdit.id, imageURL));
      if (isSelectedPlacemark) {
        if (placemarkToEdit.statusType === 'STATUS' || placemarkToEdit.statusType === 'CHECKLIST' ||
          placemarkToEdit.category === 'PANORAMIC' || placemarkToEdit.category === 'ADDRESS') {
          statusIconUrl =
            imageURL.substring(0, imageURL.lastIndexOf('.')) + StatusCommentsIcon.COMMENTS_SELECTED;
        } else {
          statusIconUrl =
            imageURL.substring(0, imageURL.lastIndexOf('/') + 1) + StatusCommentsIcon.STATUS + StatusCommentsIcon.COMMENTS_SELECTED
            + imageURL.substring(imageURL.indexOf('?'));
        }
      } else {
        if (placemarkToEdit.statusType === 'STATUS' || placemarkToEdit.statusType === 'CHECKLIST' ||
          placemarkToEdit.category === 'PANORAMIC' || placemarkToEdit.category === 'ADDRESS') {
          statusIconUrl =
            imageURL.substring(0, imageURL.lastIndexOf('.')) + StatusCommentsIcon.COMMENTS;
        } else {
          statusIconUrl =
            imageURL.substring(0, imageURL.lastIndexOf('/') + 1) + StatusCommentsIcon.STATUS + StatusCommentsIcon.COMMENTS
            + imageURL.substring(imageURL.indexOf('?'));
        }
      }

      if (statusIconUrl && statusIconUrl !== '') {
        marker.setIcon({url: statusIconUrl, scaledSize: {height: PLACEMARK_SIZE, width: PLACEMARK_SIZE}} as google.maps.Icon);
        this.placemarkMarkers.set(placemarkToEdit.id, marker);
      }
    }
  }

  private getPlacemarkIconForLayer(layerId: string, pm?: IPlacemark): string {
    const layers: ILayer[] = this.store.selectSnapshot<ILayer[]>((state: any) => state.StateLayers.layers);
    const currentLayer = layers.find((layer: ILayer) => layer.id === layerId);
    let imageURL: string;

    if (currentLayer.layerType !== LAYER_TYPES.Status) {
      imageURL = currentLayer.libIconId ? this.libIconSrv.getLibIconUrlForScene(currentLayer.libIconId) : currentLayer.iconURL;
    } else if (pm) {
      imageURL = this.containersHelper.getStatusPMIcon(pm, currentLayer);
    } else {
      console.log('Strange situation- should be checked! Zvikaa');
      return '';
    }
    return imageURL;
  }

  private coordinatesChanged(_lat: number, _lng: number): void {
    const position: google.maps.LatLng = this._draggablePin.getPosition();
    const lat: number = _lat !== undefined ? _lat : position.lat();
    const lng: number = _lng !== undefined ? _lng : position.lng();
    this._draggablePin.setPosition(new google.maps.LatLng(lat, lng));
  }

  private setTitleForDraggable(title: string): void {
        this._draggablePin.setTitle(title);
  }

  private setIconForDraggable(iconUrl: string): void {
    this._draggablePin.setIcon({url: iconUrl, scaledSize: {height: PLACEMARK_SIZE, width: PLACEMARK_SIZE}} as google.maps.Icon);
  }

  private applyMapViewpointChangesListener(listen: boolean): void {
    if (listen) {
      // Send initial data for case if the map will not moved
      let center: google.maps.LatLng = this.mapRef.getCenter();
      this.dialogComSvc.sendToActiveDialog({x_coord: center.lat(), y_coord: center.lng()});
      this.dialogComSvc.sendToActiveDialog({z_coord: this.mapRef.getZoom()});

      const centerChangedListener: google.maps.MapsEventListener = this.mapRef.addListener('center_changed', () => {
        center = this.mapRef.getCenter();
        this.dialogComSvc.sendToActiveDialog({x_coord: center.lat(), y_coord: center.lng()});
      });
      this.actionListeners.set('editVp_centerChanged', centerChangedListener);

      const zoomChangedListener: google.maps.MapsEventListener = this.mapRef.addListener('zoom_changed', () => {
        this.dialogComSvc.sendToActiveDialog({z_coord: this.mapRef.getZoom()});
      });
      this.actionListeners.set('editVp_zoomChanged', zoomChangedListener);
    } else {
      let existingListener: google.maps.MapsEventListener = this.actionListeners.get('editVp_centerChanged');
      if (existingListener !== undefined) {
        google.maps.event.removeListener(existingListener);
        this.actionListeners.delete('editVp_centerChanged');
      }
      existingListener = this.actionListeners.get('editVp_zoomChanged');
      if (existingListener !== undefined) {
        google.maps.event.removeListener(existingListener);
        this.actionListeners.delete('editVp_zoomChanged');
      }
    }
  }

  private addSitesMarkers(sites: ISite[]): void {
    sites.forEach((site: ISite) => {
      this.addSiteMarker(site);
    });
  }

  private convertMapCoordinatesToScreen(latLng: google.maps.LatLng): any {
    // const numTiles: number = 1 << this.mapRef.getZoom();
    // const projection: google.maps.Projection = this.mapRef.getProjection();
    // const worldCoordinates: google.maps.Point = projection.fromLatLngToPoint(latLng);
    // const pixelCoordinate: google.maps.Point = new google.maps.Point(worldCoordinates.x * numTiles, worldCoordinates.y * numTiles);
    // const topLeft: google.maps.LatLng = new google.maps.LatLng(this.mapRef.getBounds().getNorthEast().lat(),
    //   this.mapRef.getBounds().getSouthWest().lng());
    // const topLeftWorldCoordinate: google.maps.Point = projection.fromLatLngToPoint(topLeft);

    // const topLeftPixelCoordinate: google.maps.Point = new google.maps.Point(
    //   topLeftWorldCoordinate.x * numTiles,
    //   topLeftWorldCoordinate.y * numTiles);
    // return ({x: pixelCoordinate.x - topLeftPixelCoordinate.x,
    //   y: pixelCoordinate.y - topLeftPixelCoordinate.y});

    const projection = this.overlayView.getProjection();
    const position = projection.fromLatLngToContainerPixel(latLng);
    return position;
  }

  private getZoomlevelDistance(): number {
    let dist: number = 0;
    let tempDist: number = this.NEAR_DISTANCE_ZOOMLEVELL_2;
    for (let i: number = 2; i < this.mapRef.getZoom(); i++) {
      dist = tempDist / this.DISTANCE_BETWEEN_PLACEMARKS_RATIO;
      tempDist = dist;
    }
    return Math.round(dist);
  }

  ngOnInit(): void {
    this.mapRef = new google.maps.Map(this._mapContent.nativeElement, {
      zoom: 2,
      center: {lat: 0, lng: 0},
      streetViewControl: false,
      fullscreenControl: false,
      gestureHandling: 'greedy',
      zoomControlOptions: {
        position: google.maps.ControlPosition.TOP_LEFT
      }
    });

    this.overlayView.setMap(this.mapRef);

    google.maps.event.addListener(this.mapRef, 'drag',  this.throttleFunction(() => {
      this.updateDynamicPlacemarkProperties(this.placemarkMarkers);
    }, 100/30));

    addEventListener("resize", (event) => setTimeout(() => this.updateDynamicPlacemarkProperties(this.placemarkMarkers), 100));

    this.mapRef.addListener('rightclick', (event) => {
      if (this.infoWindow) {
        this.infoWindow.close();
      }
      const screenCoordinates: any = this.convertMapCoordinatesToScreen(event.latLng);
      this.openContextMenu.emit({x: screenCoordinates.x, y: screenCoordinates.y});
      this.rightClickLng = event.latLng.lng();
      this.rightClickLat = event.latLng.lat();
    });
    this.mapRef.addListener('click', (event) => {
      if (this.infoWindow) {
        this.infoWindow.close();
      }
      this.showDynamicPlacemarkOnHover(false, null, null);
    });
    this.mapRef.addListener('zoom_changed', (event) => {
      this.updatePlacemarksVisibility();
    });
    this.initMapsGeneralListeners();
    this.initActionCmdHandler();
    this.setPlacemarksOnMap();
    this.setLayersVisibility();
    this.setZonesVisibility();
    this.registerUpdatePlacemark();
  }

  updatePlacemarksVisibility(): void {
    const isVisible: boolean = this.mapRef.getZoom() >= this.zoomLevel;
    this.placemarkMarkers.forEach((marker: google.maps.Marker, id: string) => {
      marker.setVisible(isVisible && this.placemarkBasicVisibilityMap.get(id));
    });
  }

  initMapsGeneralListeners(): void {
    google.maps.event.addListener(this.mapRef, 'center_changed', debounceAtEnd(() => {

      const center: google.maps.LatLng = this.mapRef.getCenter();
      const params: any = {
        parent: 'MAPS',
        coordinatesType: 'GIS',
        latitude: center.lat(),
        longitude: center.lng(),
        altitude: ViewpointsHelper.zoomToAltitude(this.mapRef.getZoom()),
      };
      this.store.dispatch(new UpdateCurrViewpointParameters(params));
      this.updateDynamicPlacemarkProperties(this.placemarkMarkers);
    }, 400));
  }

  setLayersVisibility(): void {
    this.layers$.subscribe((layers: ILayer[]) => {
      const zones: IZone[] = this.store.selectSnapshot<IZone[]>((state: any) => state.StateZones.zones);
      if (this.tempPM.length > 0) {
        this.handleTempPlacemarks(zones, layers);
      } else {
        this.handlePlacemarks(zones, layers);
      }
    });

    this.changedLayers$.subscribe((changedLayers: ILayer[]) => {
      const placemarks: IPlacemark[] = this.store.selectSnapshot<IPlacemark[]>((state: any) => state.StatePlacemarks.placemarks);
      changedLayers.forEach((layer: ILayer) => {
        if  (layer.layerType === LAYER_TYPES.Status) {
          const changedPlacemarks: IPlacemark[] = placemarks.filter((pm: IPlacemark) => pm.parentLayerId === layer.id);
          changedPlacemarks.forEach((pm: IPlacemark) => {
            if (pm.coordinatesType === CoordinatesType.GIS) {
              const imageURL: string = this.containersHelper.getStatusPMIcon(pm, layer);
              const marker = this.placemarkMarkers.get(pm.id);
              marker.setIcon({url: imageURL, scaledSize: {height: PLACEMARK_SIZE, width: PLACEMARK_SIZE}} as google.maps.Icon);
              this.placemarkMarkers.set(pm.id, marker);
            }
          });
          this.dynamicPlacemarkService.updatePlacemarkOnLayerUpdate(layer.id, layer.visualizationMethod, layer.visualizationConf);
        }
      });
    });
  }
  setZonesVisibility(): void {
    this.visibleZones$.subscribe((zones: IZone[]) => {
      const layers: ILayer[] = this.store.selectSnapshot<ILayer[]>((state: any) => state.StateLayers.layers);
      if (this.tempPM.length > 0) {
        this.handleTempPlacemarks(zones, layers);
      } else {
        this.handlePlacemarks(zones, layers);
      }
    });
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (!this.mapRef) {
      return;
    }
    if (changes.activeSiteInfo && changes.activeSiteInfo.currentValue != null) {
      switch (this.activeSiteInfo.placemarkLOD) {
        case SiteWhenPlacemarksAreVisible.Always: {
          this.zoomLevel = 0;
          break;
        }
        case SiteWhenPlacemarksAreVisible.Close: {
          this.zoomLevel = 20;
          break;
        }
        case SiteWhenPlacemarksAreVisible.Far: {
          this.zoomLevel = 16;
          break;
        }
        case SiteWhenPlacemarksAreVisible.Intermediate: {
          this.zoomLevel = 18;
          break;
        }
      }
    }
    if (changes.openedPanel) {
      if (this.openedPanel !== 'sidebar.search') {
        this.hideSearchPMMarkers();
      } else {
        this.displaySearchPMMarkers();
      }
    }
    this.updatePlacemarksVisibility();
    /*if (changes.activeSiteInfo && changes.activeSiteInfo.currentValue) {
      this.resetZoom();
    }*/
    if (this.sites && changes.sites) {
      this.addSitesMarkers(this.sites);
    }
  }

  public setPlacemarksOnMap(): void {
    this.newPlacemarks$.subscribe((pms: IPlacemark[]) => {
      const layers: ILayer[] = this.store.selectSnapshot<ILayer[]>((state: any) => state.StateLayers.layers);
      const zones: IZone[] = this.store.selectSnapshot<IZone[]>((state: any) => state.StateZones.zones);
      if (pms.length > 0) {
        // counting pms that are in map mode and subtract from all calculation
        this.cmdRouterSvc.sendActionCmd(CMD_TARGETS.WEBGL_MANAGER, CMD_ACTIONS.SUBTRACT_GIS_PMS, {number: pms.length});
      }
      pms.forEach((pm: IPlacemark) => {
        if (this.objectSiteValidatorService.validateObjectToActiveSite(pm.id)) {
          this.tempPM.push(pm);
          this.handleTempPlacemarks(zones, layers);
        }

      });
    });
  }

  handlePlacemarks(zones: IZone[], layers: ILayer[]): void {
    const pmToEdit: IPlacemark[] = this.store.selectSnapshot<IPlacemark[]>((state: any) => state.StatePlacemarks.placemarks);
    pmToEdit.filter((pm: IPlacemark) => pm.coordinatesType === CoordinatesType.GIS && pm.objMode === WEBGL_OBJECT_MODE.IDLE)
      .forEach((pm: IPlacemark) => {
        const layer: ILayer = layers.find((layer1: ILayer) => layer1.id === pm.parentLayerId);
        if (layer != null) {
          let isGeneralLayer: boolean = false;
          let zone: IZone = null;
          if (layer && generalLayersIds.indexOf(Number(layer.id)) < 0) {
            zone = zones.find((zone1: IZone) => zone1.id === pm.parentZoneId);
          } else {
            isGeneralLayer = true;
          }
          const marker = this.placemarkMarkers.get(pm.id);
          if (true) {
            marker.setPosition({lat: pm.location.latitude, lng: pm.location.longitude});
            marker.setTitle(pm.name);
            let visibility: boolean = false;
            if ((!isGeneralLayer && zone && zone.visible && layer && layer.visible === LAYER_VISIBILITY.VISIBLE) || (isGeneralLayer && layer && layer.visible === LAYER_VISIBILITY.VISIBLE)) {
              visibility = true;
            }
            this.placemarkBasicVisibilityMap.set(pm.id, visibility);
            marker.setVisible(visibility && this.mapRef.getZoom() >= this.zoomLevel);
            if (pm.statusType == 'STATUS' && pm.showVisualization) {
              const placemark = {placemarkId: pm.id, visible: visibility && this.mapRef.getZoom() >= this.zoomLevel};
              this.dynamicPlacemarkService.updatePlacemarkVisibility(placemark);
            }
            if (layer) {
              const iconUrl: string = this.getPlacemarkIconForLayer(layer.id, pm);
              marker.setIcon({url: iconUrl, scaledSize: {height: PLACEMARK_SIZE, width: PLACEMARK_SIZE}} as google.maps.Icon);
            }
            // This is not required as above flow gets called when visibility of layer or zones changes and due to this below code was
            // adding 'click' handler as many times as this function gets called.
            // marker.addListener('click', () => {
            //   this.updatePlacemarkContent(pm, marker);
            // });
            marker.setZIndex(Z_INDEX_CONSTS.PLACEMARK);
            this.placemarkMarkers.set(pm.id, marker);
          }
        }
      });
  }

  async addSearchResultPlacemarks(searchResults: SingleSearchResult[]): Promise<void> {
    if (this.openedPanel == 'sidebar.search') {
      this.removeSearchPMMarkers();
      for(const result of searchResults){
        await this.addSearchResultPlacemark(result);
      }
      if (this.searchResultsMarkers.size > 0) {
        const bounds: google.maps.LatLngBounds = new google.maps.LatLngBounds();
        this.searchResultsMarkers.forEach((value: google.maps.Marker, id: string) => {
          bounds.extend(value.getPosition());
        });
        this.mapRef.fitBounds(bounds);
      }
    }
  }

  async addSearchResultPlacemark(searchResult: SingleSearchResult): Promise<void> {
    const placemarkMarker: google.maps.Marker = new google.maps.Marker();
    let id: string = searchResult.searchObjId;
    placemarkMarker.setMap(this.mapRef);
    if (searchResult.type === 'GOOGLE_PLACE') {
      placemarkMarker.setPosition({lat: searchResult.location.latitude, lng: searchResult.location.longitude});
      placemarkMarker.setTitle(searchResult.name);
    } else {
      const placemarks: IPlacemark[] = this.store.selectSnapshot<IPlacemark[]>((state: any) => state.StatePlacemarks.placemarks);
      let origPm: IPlacemark = placemarks.find((pm: IPlacemark) => pm.id === searchResult.originalObjId);
      if (!origPm) {
        // we will get here if pm is part of unloaded Zone where we didn't load the placemark to store
        if(searchResult.type === "PLACE"){
          origPm = await this.placemarksApiSvc.getAddressPlacemarkById(searchResult.originalObjId);
        } else {
          origPm = await this.placemarksApiSvc.getPlacemarkById(searchResult.originalObjId, searchResult.type);
        }
      } else {
        origPm = cloneDeep(origPm);
      }
      placemarkMarker.addListener('click', () => {
        this.updatePlacemarkContent(origPm, placemarkMarker);
        // this.showPlacemarkContent(origPm);
      });
      placemarkMarker.addListener('rightclick', (event) => {
        this.handleRightClickEvent(event, origPm, placemarkMarker);
      });
      placemarkMarker.setPosition({lat: searchResult.location.latitude, lng: searchResult.location.longitude});
      placemarkMarker.setTitle(searchResult.name);
      // if there is exisitng marker, bind them together
      const origMarker: google.maps.Marker = this.placemarkMarkers.get(searchResult.originalObjId);
      if (origMarker) {
        placemarkMarker.bindTo('position', origMarker);
      }
      id = searchResult.originalObjId;
    }
    const iconUrl: string = `${environment.baseHref}/assets/search_panel/letters/placemarkResult64_${searchResult.letterIndex}.png`;
    placemarkMarker.setIcon({url: iconUrl, scaledSize: {height: PLACEMARK_SIZE, width: PLACEMARK_SIZE}} as google.maps.Icon);
    placemarkMarker.setZIndex(Z_INDEX_CONSTS.SEARCH_RESULT);
    placemarkMarker.set('id', searchResult.searchObjId);
    this.searchResultsMarkers.set(id, placemarkMarker);
  }
  registerUpdatePlacemark(): void {
    this.updatePlacemarks$.subscribe((pms: IPlacemark[]) => {
      pms.forEach((pm: IPlacemark) => {
        const marker = this.placemarkMarkers.get(pm.id);
        marker.setPosition({lat: pm.location.latitude, lng: pm.location.longitude});
        marker.setTitle(pm.name);
        marker.setZIndex(Z_INDEX_CONSTS.PLACEMARK);
        google.maps.event.clearListeners(marker, 'click');
        marker.addListener('click', (event) => {
          this.updatePlacemarkContent(pm, marker);
        });

        let isZoneVisible: boolean;
        let isLayerVisible: boolean;
        let isGeneralLayer: boolean;
        const visibleZones: IZone[] = this.store.selectSnapshot<IZone[]>((state: any) => state.StateZones.zones)
          .filter((zone: IZone) => zone.visible);
        isZoneVisible = visibleZones.find((zone: IZone) => zone.id === pm.parentZoneId) != null;
        const visibleLayers: ILayer[] = this.store.selectSnapshot<ILayer[]>((state: any) => state.StateLayers.layers)
          .filter((layer: ILayer) => layer.visible === LAYER_VISIBILITY.VISIBLE);
        isLayerVisible = visibleLayers.find((layer: ILayer) => layer.id === pm.parentLayerId) != null;
        isGeneralLayer = generalLayersIds.indexOf(Number(pm.parentLayerId)) !== -1;
        let visibility: boolean = false;
        if ((!isGeneralLayer && isZoneVisible && isLayerVisible) || (isGeneralLayer && isLayerVisible)) {
            visibility = true;
          }
        this.placemarkBasicVisibilityMap.set(pm.id, visibility);
        marker.setVisible(visibility && this.mapRef.getZoom() >= this.zoomLevel);
        const iconUrl: string = this.getPlacemarkIconForLayer(pm.parentLayerId, pm);
        let layer: ILayer = this.store.selectSnapshot<ILayer[]>((state: any) => state.StateLayers.layers)
          .find((layer: ILayer) => layer.id === pm.parentLayerId);
        this.handlePlacemarkInfoWindow(marker, pm, layer);

        marker.addListener('mouseover', () => {
          this.onPlacemarkMarkerHover(true, pm, marker);
        });

        marker.addListener('mouseout', () => {
          this.onPlacemarkMarkerHover(false, pm, marker);
        });

        if (this.openedPanel === 'sidebar.discussion') {
          let statusIconUrl: string;
          this.getSelectedPlacemark$.subscribe((pmId: IPlacemark) => {
            var isSelectedPlacemark: boolean = pmId.id === pm.id ? true : false;

            if (!isSelectedPlacemark) {
              if (pm.statusType === 'STATUS' || pm.statusType === 'CHECKLIST') {
                statusIconUrl =
                  iconUrl.substring(0, iconUrl.lastIndexOf('.')) + StatusCommentsIcon.COMMENTS;
              } else {
                statusIconUrl =
                  iconUrl.substring(0, iconUrl.lastIndexOf('/') + 1) + StatusCommentsIcon.STATUS + StatusCommentsIcon.COMMENTS
                  + iconUrl.substring(iconUrl.indexOf('?'));
              }
            }

            marker.setIcon({url: statusIconUrl, scaledSize: {height: PLACEMARK_SIZE, width: PLACEMARK_SIZE}} as google.maps.Icon);
          }).unsubscribe();

        } else {
          marker.setIcon({url: iconUrl, scaledSize: {height: PLACEMARK_SIZE, width: PLACEMARK_SIZE}} as google.maps.Icon);
        }
        this.placemarkMarkers.set(pm.id, marker);
        this.updateDynamicPlacemark(cloneDeep(pm), marker);

      });
    });
  }

  handleTempPlacemarks(zones: IZone[], layers: ILayer[]): void {
    this.tempPM = this.tempPM.filter((pm: IPlacemark) => {
      const layer: ILayer = layers.find((layer1: ILayer) => layer1.id === pm.parentLayerId);
      if (!layer) {
        return true;
      }
      let isGeneralLayer: boolean = false;
      let zone: IZone = null;
      if (generalLayersIds.indexOf(Number(layer.id)) < 0) {
        zone = zones.find((zone1: IZone) => zone1.id === pm.parentZoneId);
        if (!zone) {
          return true;
        }
      } else {
        isGeneralLayer = true;
      }

      this.addPlacemarkMarker(pm, layer, isGeneralLayer, zone);
      return false;
    });
  }

  addPlacemarkMarker(pm: IPlacemark, layer: ILayer, isGeneralLayer: boolean, zone: IZone): void {
      const placemarkMarker: google.maps.Marker = new google.maps.Marker();
      placemarkMarker.setMap(this.mapRef);
      placemarkMarker.addListener('click', (event) => {
        this.updatePlacemarkContent(pm, placemarkMarker);
      });
      placemarkMarker.addListener('rightclick', (event) => {
        this.handleRightClickEvent(event, pm, placemarkMarker);
      });
      placemarkMarker.setPosition({lat: pm.location.latitude, lng: pm.location.longitude});
      if (pm.style.showLabelAlways) {
        placemarkMarker.setLabel(pm.name);
      } else {
        placemarkMarker.setTitle(pm.name);
      }
      let visibility: boolean = false;
      if ((!isGeneralLayer && zone.visible && layer.visible === LAYER_VISIBILITY.VISIBLE) || (isGeneralLayer && layer.visible === LAYER_VISIBILITY.VISIBLE)) {
        visibility = true;
      }
      this.placemarkBasicVisibilityMap.set(pm.id, visibility);
      placemarkMarker.setVisible(visibility && this.mapRef.getZoom() >= this.zoomLevel);
      const iconUrl: string = this.getPlacemarkIconForLayer(layer.id, pm);
      placemarkMarker.setIcon({url: iconUrl, scaledSize: {height: PLACEMARK_SIZE, width: PLACEMARK_SIZE}} as google.maps.Icon);
      placemarkMarker.setZIndex(Z_INDEX_CONSTS.PLACEMARK);
      placemarkMarker.set('id', pm.id);
      this.placemarkMarkers.set(pm.id, placemarkMarker);
      this.handlePlacemarkInfoWindow(placemarkMarker, pm, layer);

      placemarkMarker.addListener('mouseover', (event) => {
        this.onPlacemarkMarkerHover(true, pm, placemarkMarker);
      });

      placemarkMarker.addListener('mouseout', (event) => {
        this.onPlacemarkMarkerHover(false, pm, placemarkMarker);
      })

      if (pm.statusType === 'STATUS') {
        if (pm.showVisualization) {
            if (pm.visualizationConf.visibilityType == 'iconAndvalues' || pm.visualizationConf.visibilityType == 'valuesOnCollapseIcon') {
                this.addDynamicPlacemark(cloneDeep(pm), placemarkMarker);
            }
        }
      }
  }

  handlePlacemarkInfoWindow(placemarkMarker: google.maps.Marker, pm: IPlacemark, layer: ILayer) {
    google.maps.event.clearListeners(placemarkMarker, 'mouseover');
    google.maps.event.clearListeners(placemarkMarker, 'mouseout');
    google.maps.event.clearListeners(placemarkMarker, 'domready');
    if (pm.statusType === 'CHECKLIST') {
      google.maps.event.addListener(placemarkMarker, 'mouseover', () => {
        this.placemarkInfoWindow = new google.maps.InfoWindow({
          content: `
          <div class="mapStatusContainer">
            <div class="statusName" style="font-family: 'Open Sans', sans-serif;
              font-weight: 600;
              font-size: 14px;
              text-align: center;
              overflow: hidden;
              text-overflow: ellipsis;
              color: #333333;
              letter-spacing: 0;
              padding-bottom: 6px;">
            ${pm.name}
            </div>
            ${layer.statusColors.map((val, index)=>{
              return (val !== '' ? this.createplacemarkInfoRow(val, pm.issues[index]) : "")
            }).join("")}
          </div>`
        });
        this.placemarkInfoWindow.open(this.mapRef, placemarkMarker);
        google.maps.event.addListener(this.placemarkInfoWindow, 'domready', function(){
          document.querySelector(".mapStatusContainer").closest(".gm-style-iw").parentElement.querySelector('button').style.display = "none";
        });
      });

      google.maps.event.addListener(placemarkMarker, 'mouseout', () => {
        this.placemarkInfoWindow.close();
      });
    }
  }

  createplacemarkInfoRow(statusColor: string, statusVal: number): string {
    return `
      <div class="statusrow" style="width: 90px;
        clear: both;
        padding: 0px 15px;
        margin: 0 auto;">
        <div class="issuesSVG" style="width: 22px;
          height: 22px;
          margin-right: 20px;
          margin-bottom: 10px;
          float: left;
          clear: both;">
          <svg class="svg" style="width: 22px;
            height: 22px;">
            <circle cx="11" cy="11" r="11" fill="#${statusColor}" />
          </svg>
        </div>
        <div class="numIssues" style="float: right;
          text-align: center;
          width: 10px;
          font-family: 'Open Sans', sans-serif;
          font-size: 16px;
          color: #6C6C6B;">
          ${statusVal}
        </div>
      </div>`
  }

  updatePlacemarkContent(pm: IPlacemark, placemarkMarker: google.maps.Marker): void {
    if (this.infoWindow) {
      this.infoWindow.close();
    }
    event.preventDefault();
    const isSearchPlacemark = placemarkMarker.get('id').includes('SEARCH_ENGINE_RESULT');
    const closestPlacemarks: google.maps.Marker[] = this.getPlacemarksInRadius(pm, isSearchPlacemark);
    !isSearchPlacemark && closestPlacemarks.push(placemarkMarker);

    if (closestPlacemarks.length === 1) {
      this.showPlacemarkContent(pm);
    } else {
      this.showMultiplePlacemarkMenu(pm, placemarkMarker,closestPlacemarks);
    }
  }

  showMultiplePlacemarkMenu(pm, placemarkMarker,closestPlacemarks): void {
    //sort placemark first 
    closestPlacemarks.sort( (a, b) => this.getTitleForPlacemark(a.get('id')).localeCompare(this.getTitleForPlacemark(b.get('id'))));
    // show list
    this.infoWindow = new google.maps.InfoWindow();
    let wContent: string = `<div class="dropdown-content">`;
    for (const closePlacemark of closestPlacemarks) {
      const currentMarkerId: string = closePlacemark.get('id');
      if ( currentMarkerId.includes("SEARCH_ENGINE_RESULT") ) continue;

      wContent = wContent + `<div id="span_PlacemarksMenu">
            <div id='placemarkRecord${currentMarkerId}' class="placemarkRecord" style="display: flex; align-items: center">
              <img id="img_PlacemarksMenu" src="${this.getIconForPlacemark(currentMarkerId)}" width="32" height="32"/>
              <div>&nbsp;&nbsp;&nbsp; ${this.getTitleForPlacemark(currentMarkerId)}</div></div><br>
           </div>`;
    }
    wContent = wContent + '</div>';
    this.infoWindow.close();
    this.infoWindow.setContent(wContent);
    this.infoWindow.open(this.mapRef, this.placemarkMarkers.get(pm.id));
    google.maps.event.addListener(this.infoWindow, 'domready', () => {
      for (const closePlacemark of closestPlacemarks) {
        const currentMarkerId: string = closePlacemark.get('id');
        if ( currentMarkerId.includes("SEARCH_ENGINE_RESULT") ) continue;
        const element = document.getElementById('placemarkRecord' + currentMarkerId);

        const pmMarker = this.placemarkMarkers.get(currentMarkerId);
        const placemarks: IPlacemark[] = this.store.selectSnapshot<IPlacemark[]>((state: any) => state.StatePlacemarks.placemarks);
        const origPm: IPlacemark = placemarks.find((pm: IPlacemark) => pm.id === pmMarker.get('id'));

        element.addEventListener('click', () => {
          this.infoWindow.close();
          this.showPlacemarkContentFromMultipleMenu(currentMarkerId);
          this.onPlacemarkMarkerHover(false, origPm, placemarkMarker, true);
        });
        element.addEventListener('contextmenu', (event: any) => {
          event.preventDefault();
          const latLng: google.maps.LatLng = new google.maps.LatLng(origPm.location.latitude, origPm.location.longitude);
          
          this.rightClickLng = latLng.lng();
          this.rightClickLat = latLng.lat();

          // this.infoWindow.close();
          // this.showRightClickContent({latLng}, origPm);

          const boundingBox =  event.currentTarget.getBoundingClientRect()
          this.openContextMenu.emit({x: boundingBox.left + boundingBox.width - 40,
            y: boundingBox.top - boundingBox.height - 15, placemarkId: origPm.id});


        });
        element.addEventListener('mouseover', (event) => {
          const element = (event.target as HTMLInputElement).parentElement;
          const positionX = element.offsetWidth + element.getBoundingClientRect().left - 50;
          const positionY = element.getBoundingClientRect().top - 40;
          this.onPlacemarkMarkerHover(true, origPm, pmMarker, true, positionX, positionY);
        })

        element.addEventListener('mouseleave', () => {
          this.onPlacemarkMarkerHover(false, origPm, placemarkMarker, true);
        })
      }
    });
    interval(300).pipe(take(3)).subscribe(() => this.updateDynamicPlacemarkProperties(this.placemarkMarkers));
  }

  getIconForPlacemark(markerId: string): string {
    return (this.placemarkMarkers.get(markerId).getIcon() as google.maps.Icon).url;
  }

  getTitleForPlacemark(markerId: string): string {
    return this.placemarkMarkers.get(markerId).getTitle();
  }

  showPlacemarkContent(pm: IPlacemark): void {
    if (pm.category === 'PANORAMIC') {
      this.cmdRouterSvc.sendActionCmd(CMD_TARGETS.PANORAMIC_MANAGER, CMD_ACTIONS.GO_TO_PANO, {pmId: pm.id});
    } else if (pm.category === 'QR_CODE') {
      const existingPMMarker: google.maps.Marker = this.placemarkMarkers.get(pm.id);
      if (existingPMMarker) {
        this.openPlacemarkImageWindow(pm.html, existingPMMarker);
      }
    } else if (pm.category !== 'ADDRESS') {
      this.placemarkPreviewService.showPlacemarkContent(pm.category, pm.placemarkUiType, pm.name, pm.settings.width, pm.settings.height, pm.url, pm.html);
    } else if (pm.category === 'ADDRESS') {
      if (pm.description && pm.description !== '') {
        this.openPlacemarkAddressWindow(pm.settings.width, pm.settings.height, pm.description, this.placemarkMarkers.get(pm.id));
      }
    }
  }

  openPlacemarkAddressWindow(width: number, height: number, description: string, marker: google.maps.Marker): void {
    const percent: number = 0.50;
    const windowContent: string = '<div style=\"color: black;text-align:center; height:' + height + 'px;width:' + (width * percent) + 'px\">' + description + '</div>';
    if (this.infoWindow) {
      this.infoWindow.close();
    } else {
      this.infoWindow = new google.maps.InfoWindow();
    }
    this.infoWindow.setContent(windowContent);
    this.infoWindow.open(this.mapRef, marker);
  }

  openPlacemarkImageWindow(content: string, marker: google.maps.Marker): void {
    const windowContent: string = '<div style=\"width:100%;height:100%;text-align:center;\"><div><img src=\"' + content + '\"' + '></div></div>';
    if (this.infoWindow) {
      this.infoWindow.close();
    } else {
      this.infoWindow = new google.maps.InfoWindow();
    }
    this.infoWindow.setContent(windowContent);
    this.infoWindow.open(this.mapRef, marker);
  }

  showPlacemarkContentFromMultipleMenu(pmId: string): void {
    const pmFound: IPlacemark = this.store.selectSnapshot<IPlacemark[]>((state: any) => state.StatePlacemarks.placemarks).find((pm: IPlacemark) => pm.id === pmId);
    this.showPlacemarkContent(pmFound);
  }

  handleRightClickEvent(position: any, pm: IPlacemark, placemarkMarker: google.maps.Marker): void {
    if (this.infoWindow) {
      this.infoWindow.close();
    }

    // event.preventDefault();
    const isSearchPlacemark = placemarkMarker.get('id').includes('SEARCH_ENGINE_RESULT');
    const closestPlacemarks: google.maps.Marker[] = this.getPlacemarksInRadius(pm, isSearchPlacemark);
    !isSearchPlacemark && closestPlacemarks.push(placemarkMarker);
    if (closestPlacemarks.length === 1) {
      this.showRightClickContent(position, pm);
    } else {
      this.showDynamicPlacemarkOnHover(false, null, null);
      this.showMultiplePlacemarkMenu(pm, placemarkMarker,closestPlacemarks);
    }
  }

  public showRightClickContent(position, pm: IPlacemark): void {
    this.showDynamicPlacemarkOnHover(false, null, null);

    const screenCoordinates: any = this.convertMapCoordinatesToScreen(position.latLng);
    this.openContextMenu.emit({x: screenCoordinates.x,
      y: screenCoordinates.y, placemarkId: pm.id});
    this.rightClickLng = position.latLng.lng();
    this.rightClickLat = position.latLng.lat();
  }

  public addSiteMarker(site: ISite): void {

    const defSite = this.activeSiteInfo ? this.activeSiteInfo.id === site.id : false;
    const existingSiteMarker: google.maps.Marker = this._sitesMarkers.get(site.id);

    let siteMarker: google.maps.Marker = new google.maps.Marker();
    if (!existingSiteMarker) {
      siteMarker.setMap(this.mapRef);
      siteMarker.addListener('dblclick', () => {
        let isSiteLoaded = this.activeSiteInfo ? this.activeSiteInfo.id === site.id : false;
        if (!isSiteLoaded) {
          this.sitesLoaderSvc.loadSite(site, SCENE_MODE.Map);
        }
      });
      siteMarker.addListener('click', () => {
        this.infoWindow = new google.maps.InfoWindow();
        const closeSiteMarkers: google.maps.Marker[] = this.getSitesInRadius(site);
        closeSiteMarkers.push(this._sitesMarkers.get(site.id));
        if (closeSiteMarkers.length > 1) {
          let wContent: string = '<div class="dropdown-content">';
          closeSiteMarkers.forEach((_siteMarker: google.maps.Marker) => {
            const currentSite: ISite = this.sites.find((searchedSite: ISite) => searchedSite.id === _siteMarker.get('id'));
            wContent = wContent + `<div class="siteMenu"><div id="siteRecord${currentSite.id}" style="display: flex; align-items: center"><a id='a_PlacemarksMenu'>
              <img id="img_PlacemarksMenu" src=${currentSite.imgUrl} width="32" height="32"/>&nbsp;&nbsp;&nbsp
              ${currentSite.name}</a></div></div><br>`;
          });
          wContent = wContent + '</div>';
          this.infoWindow.close();
          this.infoWindow.setContent(wContent);
          this.infoWindow.open(this.mapRef, this._sitesMarkers.get(site.id));
          google.maps.event.addListener(this.infoWindow, 'domready', () => {
            for (const closeSite of closeSiteMarkers) {
              const currentMarkerId: string = closeSite.get('id');
              document.getElementById('siteRecord' + currentMarkerId).addEventListener('click', () => {
                this.infoWindow.close();
                const selectedSite: ISite = this.sites.find((siteInLoop: ISite) => siteInLoop.id === Number(currentMarkerId));
                
                let isSiteLoaded = this.activeSiteInfo ? this.activeSiteInfo.id === selectedSite.id : false;
                if (isSiteLoaded) {
                  this.sitesLoaderSvc.toggleSceneMode(SCENE_MODE.Facility);
                } else {
                  this.sitesLoaderSvc.loadSite(selectedSite);
                }

              });
            }
          });
        } else {
          let isSiteLoaded = this.activeSiteInfo ? this.activeSiteInfo.id === site.id : false;
          if (isSiteLoaded) {
            this.sitesLoaderSvc.toggleSceneMode(SCENE_MODE.Facility);
          }
        }
      });
    } else {
      siteMarker = existingSiteMarker;
    }

    siteMarker.setPosition({lat: site.latitude, lng: site.longitude});
    siteMarker.setTitle(site.name);
    const urlToSiteIcon = defSite ? `${environment.baseHref}/assets/map/blue.pin.site.map.indicator.png` : `${environment.baseHref}/assets/map/red.pin.site.map.indicator.png`;
    siteMarker.setIcon({url: urlToSiteIcon, scaledSize: {height: PLACEMARK_SIZE, width: PLACEMARK_SIZE}} as google.maps.Icon);
    siteMarker.setZIndex(defSite ? Z_INDEX_CONSTS.CURRENT_SITE : Z_INDEX_CONSTS.SITE);
    siteMarker.set('id', site.id);
    this._sitesMarkers.set(site.id, siteMarker);
  }

  public createDraggableMarker(iconUrl: string, newMode: boolean, pmId?: string): void {
    if (newMode) {
      this._draggablePin = new google.maps.Marker({
        position: this.mapRef.getCenter(),
        map: this.mapRef,
        zIndex: Z_INDEX_CONSTS.EDIT_CREATE,
        draggable: true});
      this._draggablePin.setIcon({url: iconUrl, scaledSize: {height: PLACEMARK_SIZE, width: PLACEMARK_SIZE}} as google.maps.Icon);
    } else {
      this._draggablePin = this.placemarkMarkers.get(pmId);
      if (!this._draggablePin) {
        this._draggablePin = this.searchResultsMarkers.get(pmId);
      }
      this._draggablePin.setDraggable(true);
    }
    this.dialogComSvc.sendToActiveDialog({latitude: this._draggablePin.getPosition().lat(), longitude: this._draggablePin.getPosition().lng()});

    this._draggablePin.addListener('drag', (event: any) => {
      if (!newMode) {
        this.throttleFunction(() => {
          let placemarkMarkers: Map<string, google.maps.Marker> = new Map();
          placemarkMarkers.set(pmId, this._draggablePin);
          this.updateDynamicPlacemarkProperties(placemarkMarkers);
        }, 100/30)();
      }

      this.dialogComSvc.sendToActiveDialog({latitude: event.latLng.lat(), longitude: event.latLng.lng()});
    });
  }

  public removeDraggableMarker(newMode: boolean): void {
    if (newMode) {
      this._draggablePin.setMap(null);
    } else {
      this._draggablePin.setDraggable(false);
    }
    this._draggablePin = undefined;
  }

  public removeMarker(siteId: number): void {
    const existingSiteMarker: google.maps.Marker = this._sitesMarkers.get(siteId);
    if (existingSiteMarker) {
      existingSiteMarker.setMap(null);
      this._sitesMarkers.delete(siteId);
    }
  }

  public removePMMarker(pmId: string): void {
    let existingPMMarker: google.maps.Marker = this.placemarkMarkers.get(pmId);
    if (existingPMMarker) {
      existingPMMarker.setMap(null);
      existingPMMarker = undefined;
      this.placemarkMarkers.delete(pmId);
      this.placemarkBasicVisibilityMap.delete(pmId);
    }
    existingPMMarker = this.searchResultsMarkers.get(pmId);
    if (existingPMMarker) {
      existingPMMarker.setMap(null);
      existingPMMarker = undefined;
      this.searchResultsMarkers.delete(pmId);
    }
  }
  public removeSearchPMMarkers(): void {
    this.hideSearchPMMarkers();
    this.searchResultsMarkers = new Map<string, google.maps.Marker>();
  }
  public hideSearchPMMarkers(): void {
    this.searchResultsMarkers.forEach((value: google.maps.Marker, id: string) => {
      /*value.setVisible(false); // */
      value.setMap(null);
      value.setVisible(true);
    });
  }
  public displaySearchPMMarkers(): void {
    this.searchResultsMarkers.forEach((value: google.maps.Marker, id: string) => {
      /*value.setVisible(true); // */
      value.setMap(this.mapRef);
      value.setVisible(true);
    });
  }

  public resetZoom(): void {
    const center: google.maps.LatLng = new google.maps.LatLng(this.activeSiteInfo.latitude, this.activeSiteInfo.longitude);
    this.mapRef.setCenter(center);
    this.mapRef.setZoom(DEFAULT_ZOOM_LEVEL);
  }

  goToViewpoint(lat: number, long: number, alt: number): void {
    const existingListener: google.maps.MapsEventListener = this.actionListeners.get('goToVpListener');
    if (existingListener !== undefined) {
      google.maps.event.removeListener(existingListener);
      this.actionListeners.delete('goToVpListener');
    }
    const center: google.maps.LatLng = new google.maps.LatLng(lat, long);
    this.mapRef.setCenter(center);
    this.mapRef.setZoom(alt);
    const centerChangedListener: google.maps.MapsEventListener = google.maps.event.addListenerOnce(this.mapRef, 'center_changed', () => {
        this.cmdRouterSvc.sendActionCmd(CMD_TARGETS.VIEWPOINTS_PANEL, CMD_ACTIONS.REMOVE_SELECTED_VP);
    });
    this.actionListeners.set('goToVpListener', centerChangedListener);
  }

  isPlacemarkClose(pm1: google.maps.Marker, pm2: google.maps.Marker): boolean {
    const latitude1: number = pm1.getPosition().lat();
    const latitude2: number = pm2.getPosition().lat();
    const longitude1: number = pm1.getPosition().lng();
    const longitude2: number = pm2.getPosition().lng();

    const delta1 = this.toRadians(latitude1 - latitude2);
    const delta2 = this.toRadians(longitude1 - longitude2);

    const a: number = Math.sin(delta1 / 2) * Math.sin(delta1 / 2) + Math.cos(this.toRadians(latitude1))
      * Math.cos(this.toRadians(latitude2)) * Math.sin(delta2 / 2) * Math.sin(delta2 / 2);

    const c: number = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));

    const dist: number = this.RADIUS * c;

    const zlDist: number = this.getZoomlevelDistance();

    if (dist <= zlDist) {

      return true;
    }
    return false;
  }

  toRadians(num: number): number {
    return (Math.PI / 180) * num;
  }

  getPlacemarksInRadius(pm: IPlacemark, includeSourceMarker: boolean): google.maps.Marker[] {
    const result: google.maps.Marker[] = [];
    if (this.placemarkMarkers.size > 0) {
      this.placemarkMarkers.forEach((value: google.maps.Marker, id: string) => {
        if (value.getVisible() && (includeSourceMarker || pm.id !== id)) {
          if (this.isPlacemarkClose(this.placemarkMarkers.get(pm.id), value)) {
            result.push(value);
          }
        }
      });
    }
    return result;
  }

  getSitesInRadius(site: ISite): google.maps.Marker[] {
    const result: google.maps.Marker[] = [];
    if (this._sitesMarkers.size > 0) {
      this._sitesMarkers.forEach((value: google.maps.Marker, id: number) => {
        if (value.getVisible() && site.id !== id) {
          if (this.isPlacemarkClose(this._sitesMarkers.get(site.id), value)) {
            result.push(value);
          }
        }
      });
    }
    return result;
  }

  private onPlacemarkMarkerHover(mouseOver: boolean, pm: IPlacemark, placemarkMarker, multiple = false, offsetX = null, offsetY = null): void {
    if (pm.statusType == 'STATUS' && pm.showVisualization) {
      if (pm.visualizationConf.visibilityType == 'iconOnHoverValues' || multiple) {
        this.showDynamicPlacemarkOnHover(mouseOver, pm, placemarkMarker, multiple,  offsetX, offsetY);
      }
    }
  }

  private showDynamicPlacemarkOnHover(mouseOver, pm: IPlacemark, placemarkMarker, multiple = false, offsetX = null, offsetY = null) {
    if (mouseOver) {
      const placemark = this.prepareDynamicPlacemarkObj(pm, placemarkMarker);
      const position: any = this.convertMapCoordinatesToScreen(placemarkMarker.getPosition());
      position.y = position.y - 20;
      if (multiple) {
        position.x = offsetX;
        position.y = offsetY;
      }
      this.dynamicPlacemarkService.showDynamicPlacemarkDataOnHover(true, placemark, position);
    } else {
      setTimeout(() => this.dynamicPlacemarkService.showDynamicPlacemarkDataOnHover(false, null, null), 100);
    }
  }

  private addDynamicPlacemark(pm: IPlacemark, placemarkMarker: google.maps.Marker) {
    const position: any = this.convertMapCoordinatesToScreen(placemarkMarker.getPosition());
    position.y = position.y - 20;
    const placemark = this.prepareDynamicPlacemarkObj(pm, placemarkMarker);
    this.dynamicPlacemarkService.addPlacemark(placemark, position, this.getScaleForDynamicPlacemark());
  }

  private updateDynamicPlacemark(pm, placemarkMarker) {
    let placemark = this.prepareDynamicPlacemarkObj(pm, placemarkMarker)
    let position = null;
    if (placemark.showVisualization && ( placemark.visibilityType === 'iconAndvalues' || placemark.visibilityType === 'valuesOnCollapseIcon' )) {
      position = this.convertMapCoordinatesToScreen(placemarkMarker.getPosition());
      position.y = position.y - 20;
    }
    this.dynamicPlacemarkService.updatePlacemark(placemark, position);
  }

  private updateDynamicPlacemarkProperties(placemarkMarkers) {
    const scale = this.getScaleForDynamicPlacemark();
    placemarkMarkers.forEach( (placemarkMarker, key) => {
      const placemark = {placemarkId: placemarkMarker.get('id')};
      const position: any = this.convertMapCoordinatesToScreen(placemarkMarker.getPosition());
      position.y = position.y - 20;
      this.dynamicPlacemarkService.updatePlacemarkProperties(placemark, position, scale);
    });
  }

  private getScaleForDynamicPlacemark(): number {
    let scale = 1;
    if (this.sceneMode === SCENE_MODE.Map) {
      if (this.mapRef) {
        let zoomLevel = this.mapRef.getZoom();
        zoomLevel = zoomLevel > 5 ? 5 : zoomLevel;
        scale = zoomLevel < 1 ? 0.5 : ((zoomLevel - 1) * 0.1) + 0.6;
      }
      return scale;
    } else {
      return 0;
    }
  }

  private prepareDynamicPlacemarkObj(pm: IPlacemark, placemarkMarker: google.maps.Marker) {
    return {
        name: pm.name,
        placemarkId: pm.id,
        visible: placemarkMarker.getVisible(),
        layerId: pm.parentLayerId,
        zoneId: pm.parentZoneId,
        showVisualization: pm.showVisualization,
        placemarkData: pm.placemarkData,
        visibilityType: pm.visualizationConf ? pm.visualizationConf.visibilityType : null
    }
  }

  private throttleFunction(func, delay) {
    let prev = 0;
    return (...args) => {
      const now = new Date().getTime();
      if (now - prev > delay) {
        prev = now;
        return func(...args);
      }
    }
  }
}
