<div class="dialog-header">
    <div class="dialogTitle">Operation Status Board</div>
    <mat-icon class="dialogCloseIcon" svgIcon="dialog.close" (click)="close()"></mat-icon>
  </div>
  <div class="dialog-content">
    <div id="topHeader">
      <div class="refreshButton" (click)="refresh()">
        <mat-icon class="refreshIcon" svgIcon="refresh" insTooltip [delayMs]="1000" [cfg]="{text: 'Refresh', position: {H: 'hCenter', V:'vBottom'}, type: 'info'}"></mat-icon>
      </div>
      <div class="statusFilterControl">
        <div class="label">Status: </div>
        <div class="statusSelectionControl">
          <ins-selection-box class="componentInDialog" [disabled]="pageRefreshInprogress" [options]="statusFilterOptions" [(value)]="selectedStatusFilter" [label]="''" (valueChange)="statusFilterChanged($event)"></ins-selection-box>
        </div>
      </div>
    </div>
    <div class="ins-table-Container">
      <div class="overlay" *ngIf="pageRefreshInprogress">
        <ins-loading-circle [size]="32"></ins-loading-circle>
      </div>
      <perfect-scrollbar class="scrollbar">
        <table mat-table [dataSource]="tableDataSource" matSort class="ins-table">
          <!-- Operation Name Column -->
          <ng-container matColumnDef="name">
            <th mat-header-cell class="header-cell" *matHeaderCellDef mat-sort-header> Operation Name </th>
            <td mat-cell class="body-cell" *matCellDef="let element" [delayMs]="700" #nameDiv insTooltip [cfg]="getToolTipConf(nameDiv, element.name)"> {{element.name}} </td>
          </ng-container>

          <!-- Status Column -->
          <ng-container matColumnDef="status">
            <th mat-header-cell class="header-cell" *matHeaderCellDef mat-sort-header [disabled]="statusSortDisabled"> Status </th>
            <td mat-cell class="body-cell" [class]="getStatusClass(element.status)" *matCellDef="let element"> 
              <mat-icon class="statusIcon" *ngIf="element.status == OperationStatus.FAILED" svgIcon="operation.error"></mat-icon>
              <mat-icon class="statusIcon" *ngIf="element.status == OperationStatus.COMPLETED" svgIcon="operation.success"></mat-icon>
              <mat-icon class="statusIcon" *ngIf="element.status == OperationStatus.IN_PROGRESS" svgIcon="operation.inprogress"></mat-icon>
              {{ operationStatusTextMap.get(element.status) }} 
            </td>
          </ng-container>
        
          <!-- Details Column -->
          <ng-container matColumnDef="details">
            <th mat-header-cell class="header-cell" *matHeaderCellDef> Details </th>
            <td mat-cell class="body-cell" *matCellDef="let element" [delayMs]="700" #detailsDiv insTooltip [cfg]="getToolTipConf(detailsDiv, element.details)"> {{element.details}} </td>
          </ng-container>
          
          <!-- Site Name -->
          <ng-container matColumnDef="siteName">
            <th mat-header-cell class="header-cell" *matHeaderCellDef> Site Name </th>
            <td mat-cell class="body-cell" *matCellDef="let element" [class]="element.siteDeleted ? 'siteDeleted' : ''" [delayMs]="700" #siteNameDiv insTooltip [cfg]="getToolTipConf(siteNameDiv, element.siteName)"> {{element.siteName}} </td>
          </ng-container>
        
          <!-- Start Time Date -->
          <ng-container matColumnDef="startTime">
            <th mat-header-cell class="header-cell" *matHeaderCellDef mat-sort-header> Start Time </th>
            <td mat-cell class="body-cell" *matCellDef="let element">{{element.startTime| date:'dd.MM.yyyy HH:mm:ss'}} </td>
          </ng-container>

          <!-- End Time Date -->
          <ng-container matColumnDef="endTime">
            <th mat-header-cell class="header-cell" *matHeaderCellDef mat-sort-header> End Time </th>
            <td mat-cell class="body-cell" *matCellDef="let element">{{element.endTime| date:'dd.MM.yyyy HH:mm:ss'}} </td>
          </ng-container>
    
          <!-- Download -->
          <ng-container matColumnDef="downloadUrl">
            <th mat-header-cell class="header-cell" *matHeaderCellDef> &nbsp; &nbsp; </th>
            <td mat-cell class="body-cell" *matCellDef="let element">
              <mat-icon *ngIf="element.downloadUrl" class="downloadIcon" svgIcon="downloadIcon" (click)="downloadReport(element)" insTooltip [delayMs]="1000" [cfg]="{text: 'Download', position: {H: 'hCenter', V:'vBottom'}, type: 'info'}"></mat-icon>
            </td>
          </ng-container>
        
          <tr mat-header-row class="header-row" *matHeaderRowDef="displayedProjectColumns; sticky: true"></tr>
          <tr mat-row class="body-row" 
            [ngClass]="[ row.status == OperationStatus.IN_PROGRESS ? 'inprogress' : '', row.status == OperationStatus.COMPLETED ? 'success' : '', row.status == OperationStatus.FAILED ? 'error' : '']" 
            *matRowDef="let row; columns: displayedProjectColumns | paginate: {id: 2, itemsPerPage: pageSize, currentPage: pageIndex, totalItems: totalRecords}">
          </tr>
          
        </table>

        <div *ngIf="!showLoading && noData | async" class="no-records" style="line-height: 25;">
          No results found.
        </div>
    
        <div *ngIf="showLoading" class="no-records" style="line-height: 25;">
          Loading...
        </div>
      </perfect-scrollbar>
    </div>
    <pagination-template #p="paginationApi" *ngIf="operationsData.length > 0" (pageChange)="pageChangeEvent($event)" [id]="2" [maxSize]="5">
      <div class="custom-pagination" *ngIf="p.pages.length > 1" [class.btn-disabled]="pageRefreshInprogress">
        <div class="pagination-previous page-number" [class.disabled]="p.isFirstPage()" (click)="p.previous()"> < </div>
        <div class="page-number" *ngFor="let page of p.pages" [class.current]="p.getCurrent() === page.value" (click)="p.setCurrent(page.value)">
          {{page.label}}
        </div>
        <div class="pagination-next page-number" [class.disabled]="p.isLastPage()" (click)="p.next()"> > </div>
      </div>
    </pagination-template>
  
  </div>
  <div class="dialog-footer">
    <button id="closeButton" class="actionButton minWidth blue"  (click)="close(buttonsInfo[0].value)">{{buttonsInfo[0].displayValue}}</button>
  </div>
  