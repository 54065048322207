import { ServerApi } from './api.services/server.api';
import { Injectable } from '@angular/core';

@Injectable()
export class DownloadFileHelper {

  constructor(private serverApi: ServerApi) {}

  static downloadFile(urlObject: string, fileName: string): void {
    const link: any = document.createElement('a');
    link.href = urlObject;
    link.download = fileName;
    document.getElementById('buttonDownloadContainer').appendChild(link);
    link.click();
    document.getElementById('buttonDownloadContainer').removeChild(link);
  }

  downloadFileBlob(operation: any, fileName: string): void {
    this.serverApi.sendGetToServer(operation.downloadUrl, {
      responseType: 'blob'
    }).subscribe((response: any) => {
      console.log(response);
      const url = window.URL.createObjectURL(response);
      const link = document.createElement('a');
      link.href = url;
      link.download = fileName;
      link.click();
      window.URL.revokeObjectURL(url);
    }, (err) => {
      console.log(err);
    });
  }

}
