<div class="dialog-header">
  <div class="dialogTitle">
    <span>Placemark Event Information</span>
  </div>
  <mat-icon class="dialogCloseIcon" svgIcon="dialog.close" (click)="close()"></mat-icon>
</div>
<div class="dialog-content">
  <mat-tab-group *ngIf="eventInfo" animationDuration="0ms">
      <mat-tab label="Asset Information">
          <div class="mat-tab-content">
            <span class="text-field">Asset Type</span>: <span class="text-value">
              {{eventInfo.assetType}}<span *ngIf="!eventInfo.assetType" class="not-applicable">NA</span>
            </span><br>

            <span class="text-field">Aspect</span>: <span class="text-value">
                {{eventInfo.assetAspect}}<span *ngIf="!eventInfo.assetAspect" class="not-applicable">NA</span>
            </span><br>

            <span class="text-field">Name</span>: <span class="text-value">
                {{eventInfo.assetName}}<span *ngIf="!eventInfo.assetName" class="not-applicable">NA</span>
            </span><br>

            <span class="text-field">Description</span>: <span class="text-value">
                {{eventInfo.assetDesc}}<span *ngIf="!eventInfo.assetDesc" class="not-applicable">NA</span>
            </span><br>
            <hr>
            <ins-expansion-box [titleHeight]="'auto'"
                [elementOnPanel]="false" [leftPositionedArrow]="false" [titleStyle]="{width: '100%', 'align-items': 'flex-end'}" *ngIf="eventInfo.assetLoc">
                <div titleContent class="expansion-title">Location</div>
                <div content class="expansionEntries">
                  <div>
                    <span class="text-field">Country</span>: <span class="text-value">{{eventInfo.assetLoc.country}}</span>
                  </div>
                  <div>
                    <span class="text-field">Region</span>: <span class="text-value">{{eventInfo.assetLoc.region}}</span>
                  </div>
                  <div>
                    <span class="text-field">Locality</span>: <span class="text-value">{{eventInfo.assetLoc.locality}}</span>
                  </div>
                  <div>
                    <span class="text-field">Street Address</span>: <span class="text-value">{{eventInfo.assetLoc.streetAddress}}</span>
                  </div>
                  <div>
                    <span class="text-field">Postal Code</span>: <span class="text-value">{{eventInfo.assetLoc.postalCode}}</span>
                  </div>
                  <div>
                    <span class="text-field">Longitude</span>: <span class="text-value">{{eventInfo.assetLoc.longitude}}</span>
                  </div>
                  <div>
                    <span class="text-field">Latitude</span>: <span class="text-value">{{eventInfo.assetLoc.latitude}}</span>
                  </div>
                </div>
            </ins-expansion-box>
            <span class="text-field" *ngIf="!eventInfo.assetLoc">Location:</span> <span *ngIf="!eventInfo.assetLoc" class="text-value not-applicable">NA<br></span>
          </div>
      </mat-tab>
      <mat-tab label="Event Information">
        <div class="mat-tab-content">
            <span class="text-field">Event Name</span>: <span class="text-value">
                {{ eventInfo.ruleName }}<span *ngIf="!eventInfo.ruleName" class="not-applicable">NA</span>
            </span><br>

            <span class="text-field">Source of Event</span>: <span class="text-value">
              {{ eventInfo.source }}<span *ngIf="!eventInfo.source" class="not-applicable">NA</span>
            </span><br>
            
            <ng-container *ngIf="eventInfo.ruleProps">
              <span class="text-field">Rule Variable name and value</span>: <span class="text-value" *ngFor="let rule of eventInfo.ruleProps; let i=index"> {{rule.ruleVariable}}, {{rule.ruleVariableValue}}</span><span *ngIf="!eventInfo.ruleProps" class="not-applicable text-value">NA</span><br>
            </ng-container>

            <ng-container *ngIf="ruleCondition">
              <span class="text-field">Rule condition</span>: <span class="text-value">
                <span *ngFor="let rule of ruleCondition"><a href="{{eventInfo.ruleUrl}}" target="_blank" class="url-link"> "property":"{{rule.property}}","condition":"{{rule.condition}}","threshold":"{{rule.threshold}}" </a><br><span class="not-applicable" *ngIf="!eventInfo.ruleUrl">NA</span></span>
              </span>
            </ng-container>

            <span class="text-field">Action Severity</span>: <span class="text-value">
                {{eventInfo.actionSeverity}}<span *ngIf="!eventInfo.actionSeverity" class="not-applicable">NA</span>
            </span><br>

            <span class="text-field">Time stamp</span>: <span class="text-value">
              {{ eventInfo.timestamp }}<span *ngIf="!eventInfo.timestamp" class="not-applicable">NA</span>
            </span><br>

            <span class="text-field">Action Description</span>: <span class="text-value">
                {{eventInfo.actionDesc}}<span *ngIf="!eventInfo.actionDesc" class="not-applicable">NA</span>
            </span><br>

            <span class="text-field">Acknowledged</span>: <span class="text-value">
              {{eventInfo.acknowledged}}<span *ngIf="eventInfo.acknowledged == undefined" class="not-applicable">NA</span>
            </span><br>
        </div>
      </mat-tab>
  </mat-tab-group>
  <div class="dialog-footer">
      <button class="actionButton blue" (click)="close()">Close</button>
  </div>
</div>
