import {Component, EventEmitter, Input, Output} from '@angular/core';
import {BaseUIController} from '../base-ui-controller';

@Component({
  selector: 'ins-checkbox',
  templateUrl: './checkbox.component.html',
  styleUrls: ['./checkbox.component.scss', './../shared-UI-components.scss']
})
export class CheckboxComponent extends BaseUIController {

  @Input()
  ignoreValueManipulations: boolean = false;
  // inputs for ignoring mode
  @Input()
  checked: boolean = true;

  @Input()
  checkboxText: string = 'checkbox1';
  @Input()
  enabled: boolean = true;
  @Input()
  topPad: boolean = true;
  @Input()
  cbCustomStyle: any = {};
  @Input() 
  checkboxOnPanel: boolean = false;
  @Output()
  checkboxChanged: EventEmitter<any> = new EventEmitter();

  public set value(value: any) {
    if (value !== undefined && this._value !== value) {
      const oldValue: boolean = this._value;
      this._value = value;
      this.valueChange.emit(this._value);
      this.checkboxChanged.emit({old: oldValue, new: value});
    }
  }

  public get value(): any {
    return this._value;
  }

  constructor() {
    super();
  }

  onChange($event: any): void {
    this.checkboxChanged.emit({old: !$event.checked, new: $event.checked});
  }
}
