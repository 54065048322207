import {Component, EventEmitter, OnInit, Output} from '@angular/core';
import {Select, Store} from '@ngxs/store';
import {ISite} from '../../../../../Store/sites.state/sites.model';
import {SitesState} from '../../../../../Store/sites.state/sites.state';
import {Observable, Subject} from 'rxjs';
import {map, takeWhile} from 'rxjs/operators';
import {DialogRef} from '../../../../../common/Forms/Dialog-types/dialog-ref';
import {NewEditSiteDialogComponent} from '../../../../../common/Forms/Dialogs/new-edit-site-dialog/new-edit-site.dialog.component';
import {DialogService, DialogType} from '../../../../../services/dialogs.service';
import {DialogModel} from '../../../../../common/Models/Dialog/dialog.model';
import {InputsBindingsModel} from '../../../../../common/Models/Dialog/inputs-binding.model';
import {EditorMode} from '../../../../../common/Forms/Dialogs/dialog.helper';
import {ValueAndDisplay} from '../../../../../common/UI-Components/helperClasses/value-and-display.class';
import {ServerApi} from '../../../../../services/api.services/server.api';
import {SetSelectedMode} from '../../../../../Store/app.state/app.actions';
import {SCENE_MODE} from '../../../../../Store/app.state/app.model';
import {CMD_ACTIONS, CMD_TARGETS, CmdRouterService} from '../../../../../services/cmd-router.service';
import {IViewpoint} from '../../../../../Store/viewpoints.state/viewpoints.model';
import {SettingsState} from '../../../../../Store/settings.state/settings.state';
import {SetSettingsSitesExpandedObj} from '../../../../../Store/settings.state/settings.actions';
import {MessagesBank, StatusService} from '../../../../../services/status.service';
import {ViewpointsHelper} from '../../../../../Store/viewpoints.state/viewpoints.helper';
import {SitesApiSvc} from '../../../../../services/api.services/sites.api.svc';
import {ACTION_TYPE, Actions, PermissionsManager} from '../../../../../services/permissions-manager';
import {AppState} from '../../../../../Store/app.state/app.state';
import {SitesLoaderSvc} from '../../../../../services/api.services/sites.loader.svc';
import {ILimitedAccessConf} from 'src/app/common/Models/UI/limited-access-conf.model';
import {SiemensAnalyticsService} from 'src/app/services/siemens-analytics.service';
import {cloneDeep} from 'lodash';
import {UpdateCurrViewpointParameters} from '../../../../../Store/app.state/app.actions';

const NO_SITES_AVAILABLE: string = 'No site is available for this company.';
const NO_RESULTS_FOUND: string = 'No results found.';

@Component({
  selector: 'ins-sites-menu',
  templateUrl: './sites-menu.component.html',
  styleUrls: ['./sites-menu.component.scss', './../submenus-shared-design.scss',
    './../../../../../common/UI-Components/shared-UI-components.scss']
})
export class SitesMenuComponent implements OnInit {

  private sitesStatesloaded: boolean = false;
  public filterStr: string = '';

  public isPermAddSite: boolean = false;
  public isPermEditSite: boolean = false;
  public isPermDeleteSite: boolean = false;
  @Select(AppState.getLimitedAccessConf) limitedAccess$: Observable<ILimitedAccessConf>;
  private limitedAccess: ILimitedAccessConf;
  @Select(AppState.getGoogleBlocked) googleBlocked$: Observable<boolean>;
  public googleBlocked: boolean = false;
  public loadSiteTooltip: string = 'Load Site';

  get noContentAvailableStr(): string {
    if (this.filterStr.trim() === '') {
      // no filter done
      return NO_SITES_AVAILABLE;
    } else {
      // filter done, no results
      return NO_RESULTS_FOUND;
    }
  }

  @Select(SitesState.getSites) sites$: Observable<ISite[]>;
  @Select(SettingsState.getSitesExpandState) SitesExpandState$: Observable<any>;
  sitesExpandedState: any;

  @Output()
  public closePanel: EventEmitter<void> = new EventEmitter();

  notifyExpandAll: Subject<boolean> = new Subject();

  public originalViewpoint: IViewpoint;

  constructor(private store: Store, public dialogService: DialogService,
              private serverApi: ServerApi, private cmdRouterSvc: CmdRouterService, private sitesApiSvc: SitesApiSvc,
              private statusBar: StatusService,
              private siteLoaderSvc: SitesLoaderSvc, private siemensAnalyticsService: SiemensAnalyticsService) {

    PermissionsManager.isPermitted$(Actions.SITES_PERM, ACTION_TYPE.CREATE).subscribe((isPerm: boolean) => {
      this.isPermAddSite = isPerm; });
    PermissionsManager.isPermitted$(Actions.SITES_PERM, ACTION_TYPE.UPDATE).subscribe((isPerm: boolean) => {
      this.isPermEditSite = isPerm; });
    PermissionsManager.isPermitted$(Actions.SITES_PERM, ACTION_TYPE.DELETE).subscribe((isPerm: boolean) => {
      this.isPermDeleteSite = isPerm; });
    this.googleBlocked$.subscribe((blocked: boolean) => this.googleBlocked = blocked);
    this.limitedAccess$.subscribe((limitedAccess: ILimitedAccessConf) => this.limitedAccess = limitedAccess);
  }

  ngOnInit(): void {
    this.SitesExpandState$.pipe(takeWhile(() => !this.sitesStatesloaded)).subscribe((expandedList: any) => {
      if (Object.keys(expandedList).length > 0 ) {
        this.sitesStatesloaded = true;
      }
      this.sitesExpandedState = expandedList;
    });
  }

  public async addNewSite(): Promise<any> {
    if (this.limitedAccess.limitSites) {
      const siteCount = await this.sitesApiSvc.getCompanySiteCount();
      if ( siteCount >= this.limitedAccess.limitSiteCount ) {
        const err = new Error(`In the Intosite limited version you can create up to ${this.limitedAccess.limitSiteCount} sites.`);
        this.serverApi.createNotifiactionDialogForHttpCrisis(err,`In the Intosite limited version you can create up to ${this.limitedAccess.limitSiteCount} sites.`);
        return;
      }
    }

    if (!this.googleBlocked) {
      this.store.dispatch(new SetSelectedMode(SCENE_MODE.Map, false));
    }
    const dialog: DialogRef = this.dialogService.createDialog(NewEditSiteDialogComponent, DialogType.Modeless);
    if (!dialog) {
      return;
    }
    this.cmdRouterSvc.sendActionCmd(CMD_TARGETS.MAP_MANAGER, CMD_ACTIONS.CREATE_EDIT_SITE_MODE, {dialogRef: dialog});
    this.cmdRouterSvc.sendActionCmd(CMD_TARGETS.MAP_MANAGER, CMD_ACTIONS.VP_NEW_EDIT_MODE, {dialogRef: dialog});

    dialog.onClose$().subscribe((data: DialogModel) => {
      if (data.userAction === 'add') {
        this.statusBar.addNewStatus(MessagesBank.ADDING_SITE);
        this.cmdRouterSvc.sendActionCmd(CMD_TARGETS.SCENE, CMD_ACTIONS.REMOVE_LAST_ACTIVE_PANEL);
        this.cmdRouterSvc.sendActionCmd(CMD_TARGETS.WEBGL_MANAGER, CMD_ACTIONS.REMOVE_LAST_ACTIVE_PANEL);
        this.cmdRouterSvc.sendActionCmd(CMD_TARGETS.MAP_MANAGER, CMD_ACTIONS.REMOVE_LAST_ACTIVE_PANEL);
        // Prevent undefined description when it's empty
        const desc: string = data.getData('description') ? data.getData('description') : '';
        const newImage: File = data.getData('image');
        const alt: number = data.getData('z_coord') ? ViewpointsHelper.zoomToAltitude(data.getData('z_coord')) : undefined;
        const lat: number = data.getData('x_coord') ? data.getData('x_coord') : undefined;
        const long: number = data.getData('y_coord') ? data.getData('y_coord') : undefined;
        const lastVP: IViewpoint = {
          parent: 'MAPS', id: '', name: '',
          parentZoneId: '', accessMode: 'UNDEFINED',
          altitude: alt, coordinatesType: 'GIS',
          creatorId: '', description: '', latitude: lat, longitude: long,
          parentLayerId: undefined, rotation: undefined, range: undefined, tilt: undefined, parentPanoramaId: undefined,
          isDefaultInZone: false, positionProperty: undefined, category: 'VIEWPOINT', showOnlySelectedZonesLayers: false
        };
        const newSite: ISite = {id: -1, name: data.getData('name'), imgUrl: '',
          description: desc, latitude: data.getData('latitude'),
          longitude: data.getData('longitude'), defaultView: data.getData('defaultView'),
          placemarkLOD: data.getData('placemarkLOD'),
          radiusForPano: data.getData('radiusForPano'),
          radiusForPlacemarks: data.getData('radiusForPlacemarks'), isDefault: false,
          accessMode: 'UNDEFINED', clustering: false,
          scale: undefined, rotation: undefined,
          offsetX: undefined, offsetY: undefined, offsetZ: undefined,
          panoramicPlacemarksDefaultRotation: undefined,
          registrationPoints: undefined, lastViewpoint: lastVP,
          webShareConfId: data.getData('webShareConfId')
        };

        this.sitesApiSvc.addNewSite(newImage, newSite);
      }
    });
  }

  public editSite(site: ISite): void {
    this.copyOriginalViewpoint();
    // Prevent undefined description when it's empty
    site.description = site.description ? site.description : '';
    const inputsBinding: InputsBindingsModel = new Map();
    inputsBinding.set('dialogMode', EditorMode.EDIT);
    inputsBinding.set('siteId', site.id);
    inputsBinding.set('name', site.name);
    inputsBinding.set('description', site.description);
    inputsBinding.set('image', site.imgUrl);
    inputsBinding.set('defaultView', site.defaultView);
    inputsBinding.set('placemarkLOD', site.placemarkLOD);
    inputsBinding.set('latitude', site.latitude);
    inputsBinding.set('longitude', site.longitude);
    inputsBinding.set('radiusForPlacemarks', site.radiusForPlacemarks);
    inputsBinding.set('radiusForPano', site.radiusForPano);
    inputsBinding.set('webShareConfId', site.webShareConfId);
    if (!this.googleBlocked) {
      this.store.dispatch(new SetSelectedMode(SCENE_MODE.Map, false));
    }
    const dialog: DialogRef = this.dialogService.createDialog(NewEditSiteDialogComponent, DialogType.Modeless, inputsBinding);
    if (!dialog) {
      return;
    }
    // // Uncomment if Viewpoint needs to change while updating site.
    // this.cmdRouterSvc.sendActionCmd(CMD_TARGETS.MAP_MANAGER, CMD_ACTIONS.VP_NEW_EDIT_MODE, {dialogRef: dialog});
    this.cmdRouterSvc.sendActionCmd(CMD_TARGETS.MAP_MANAGER, CMD_ACTIONS.CREATE_EDIT_SITE_MODE, {dialogRef: dialog, siteId: site.id, site: site});

    dialog.onClose$().subscribe((data: DialogModel) => {
      if (data.userAction === 'add') {
        this.statusBar.addNewStatus(MessagesBank.EDITING_SITE);
        let imageFile: File = data.getData('image');
        let isFileNew = false;
        if (site.imgUrl !== imageFile + '') {
          isFileNew = true;
        }

        let lastVP: IViewpoint = site.lastViewpoint ? site.lastViewpoint : null;
        const updateSite: ISite = {id: site.id, name: data.getData('name'), imgUrl: imageFile + '',
          description: data.getData('description'), latitude: data.getData('latitude'),
          longitude: data.getData('longitude'), defaultView: data.getData('defaultView'),
          placemarkLOD: data.getData('placemarkLOD'),
          radiusForPano: data.getData('radiusForPano'),
          radiusForPlacemarks: data.getData('radiusForPlacemarks'), isDefault: site.isDefault,
          accessMode: site.accessMode, clustering: site.clustering,
          scale: site.scale, rotation: site.rotation,
          offsetX: site.offsetX, offsetY: site.offsetY, offsetZ: site.offsetZ,
          panoramicPlacemarksDefaultRotation: site.panoramicPlacemarksDefaultRotation,
          registrationPoints: site.registrationPoints, lastViewpoint: lastVP,
          webShareConfId: data.getData('webShareConfId'),
          externalId: site.externalId
        };

        if (!isFileNew) {
          imageFile = undefined;
        }
        this.sitesApiSvc.updateSite(imageFile, updateSite);
      }
      if (this.originalViewpoint) {
        this.revertBacktoOriginalViewpoint();
      }
    });
  }

  copyOriginalViewpoint(): void {
    this.originalViewpoint = cloneDeep(this.store.selectSnapshot<any>((state: any) => state.StateApp.currViewpoint)) ;
  }

  revertBacktoOriginalViewpoint(): void {
    if (this.originalViewpoint.parent === 'MAPS') {
      this.store.dispatch(new SetSelectedMode(SCENE_MODE.Map));
      this.cmdRouterSvc.sendActionCmd(CMD_TARGETS.MAP_MANAGER, CMD_ACTIONS.GO_TO_VP_MODE, {selectedVP: this.originalViewpoint});
    } else if (this.originalViewpoint.parent === 'WEBGL') {
      this.store.dispatch(new SetSelectedMode(SCENE_MODE.Facility));
    }
    this.originalViewpoint = null;
  }

  public loadSite(site: ISite): void {
    this.siteLoaderSvc.loadSite(site);

     // Log Siemens Analytics event
     this.siemensAnalyticsService.logEvent('INS_LoadOfSite');
  }

  public deleteSite(site: ISite): void {
    const notificationInputsBinding: InputsBindingsModel = new Map<string, any>([
      [ 'type', 'warning'],
      [ 'title', 'Delete Site' ],
      [ 'message', `Site '${site.name}' with all of its content will be permanently deleted.<br>Do you wish to continue?`],
      [ 'buttonsInfo', [new ValueAndDisplay('cancel', 'Cancel'),
        new ValueAndDisplay('yes', 'Yes')]]
    ]);
    const notificationDialog: DialogRef = this.dialogService.createNotificationDialog(notificationInputsBinding);

    notificationDialog.onClose$().subscribe((data: DialogModel) => {
      if (data.userAction === 'yes') {
        this.statusBar.addNewStatus(MessagesBank.DELETING_SITE);
        this.sitesApiSvc.deleteSite(site.id);
        this.cmdRouterSvc.sendActionCmd(CMD_TARGETS.MAP_MANAGER, CMD_ACTIONS.DELETE_SITE, {siteId: site.id});
      }
    });
  }

  public onActionClick(event: any, type: string): void {
    event.stopPropagation();
    window.alert('Action: ' + type);
  }

  public expandAll(): void {
    this.notifyExpandAll.next(true);
  }

  public collapseAll(): void {
    this.notifyExpandAll.next(false);
  }

  public setFilterStr(event: Event): void {
    this.filterStr = (event.target as any).value;
  }

  public clearFilterStr(): void {
    this.filterStr = '';
  }

  public getSites(): Observable<ISite[]> {
    return this.sites$.pipe(
      map((sites: ISite[]) => {
        return sites.filter((site: ISite) =>
          site.name.toLowerCase().indexOf(this.filterStr.toLowerCase()) !== -1);
      }
    ));
  }

  public expansionChanged(siteId: string, expanded: boolean): void {
    // we are updating this status locally because due to performance we only want to subscribe for this section in store ONCE
    this.sitesExpandedState[siteId] = expanded;
    this.store.dispatch(new SetSettingsSitesExpandedObj(this.sitesExpandedState));
  }
}
