import {AfterViewInit, Component, EventEmitter, Input, OnDestroy, Output} from '@angular/core';
import {Subject} from 'rxjs';
import {takeUntil} from 'rxjs/operators';

@Component({
  selector: 'ins-expansion-box',
  templateUrl: './expansion-box.component.html',
  styleUrls: ['./expansion-box.component.scss', './../shared-UI-components.scss']
})
export class ExpansionBoxComponent implements AfterViewInit, OnDestroy {

  public hover: boolean = false;

  @Input() expanded: boolean = false;

  @Input() titleHeight: string = '20px';

  @Input() leftPositionedArrow: boolean = false;

  @Input() titleStyle: any = {};

  @Input() elementOnPanel: boolean = false;

  @Input() forcePadLeft: boolean = false;

  @Input() expandedListener: Subject<boolean> = new Subject<boolean>();

  @Output() expandChanged: EventEmitter<boolean> = new EventEmitter();

  public unsubscribe: Subject<void> = new Subject();

  constructor() { }

  ngOnDestroy(): void {
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }

  ngAfterViewInit(): void {
    this.expandedListener.asObservable().pipe(takeUntil(this.unsubscribe)).subscribe((value: boolean) => {
      this.expanded = value;
      this.expandChanged.emit(this.expanded);
    });
  }

  getIconName(): string {
    if (this.leftPositionedArrow) {
      return this.expanded ? 'dropdown.expand-more' : 'arrow.right';
    }
    return this.expanded ? 'dropdown.expand-less' : 'dropdown.expand-more';
  }

  toggleExpansion(): void {
    this.expanded = !this.expanded;
    this.expandChanged.emit(this.expanded);
  }

  markHover(hover: boolean): void {
    this.hover = hover;
  }

  stopPropagationOfHoverContentClick(ev: MouseEvent): void {
    ev.stopPropagation();
    this.markHover(false);
  }
}
