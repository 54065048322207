import {Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {environment} from '../../../environments/environment';
import {HttpErrorResponse} from '@angular/common/http';
import {Select, Store} from '@ngxs/store';
import {ApiTools} from '../../services/api.services/api.tools';
import {DialogRef} from '../../common/Forms/Dialog-types/dialog-ref';
import {DialogService, DialogType} from '../../services/dialogs.service';
import {DialogModel} from '../../common/Models/Dialog/dialog.model';
import {ChangePasswordComponent} from '../../common/Forms/Dialogs/change-password.component/change-password.component';
import {InputsBindingsModel} from '../../common/Models/Dialog/inputs-binding.model';
import {ResetPassDialogComponent} from '../../common/Forms/Dialogs/reset-pass-dialog/reset-pass-dialog.component';
import {RegisterUserDialogComponent} from '../../common/Forms/Dialogs/register-user-dialog/register-user-dialog.component';
import {isNullOrUndefined} from 'util';
import {IValidator} from '../../Directives/directives.helper';
import {DeviceDetectorService} from 'ngx-device-detector';
import {InputBoxComponent} from '../../common/UI-Components/input-box/input-box.component';
import {ServerApi} from '../../services/api.services/server.api';
import {SessionApiSvc} from '../../services/api.services/session.api.svc';
import {ApplicationView, ICompanyInfo, IntositeLoginMethod} from 'src/app/common/Models/UI/company-info.interface';
import {APPLICATION_MODE, IAppConf} from 'src/app/Store/app.state/app.model';
import {IntositeEmbeddedService} from 'src/app/services/external/intosite-embedded.service';
import {Observable} from 'rxjs';
import {AppState} from 'src/app/Store/app.state/app.state';

const CHROME: string = 'Chrome';
const FF: string = 'Firefox';
const IE: string = 'IE';
const EDGE: string = 'Edge'; // chromium based edge - New Edge version (maybe we need to use 'Edg' and not 'Edge'

@Component({
  selector: 'ins-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit, OnDestroy {

  @ViewChild('userNameRef') private userNameRef: InputBoxComponent;
  @ViewChild('companyRef') private companyRef: InputBoxComponent;
  @ViewChild('passRef') private passRef: InputBoxComponent;

  isChrome: boolean = false;
  isFF: boolean = false;
  isEdge: boolean = false;

  username: string = '';
  password: string = '';
  company: string = '';
  _rememberMe: boolean = true;

  tourId: string = '';
  appView: ApplicationView = ApplicationView.Main;
  applicationViews = ApplicationView;

  displayErrorMsg: boolean = false;

  requiredField_name: IValidator = {objectID: 'input', regEx: '', displayText: 'Missing username. Please fill and retry'};
  requiredField_pass: IValidator = {objectID: 'input', regEx: '', displayText: 'Missing password. Please fill and retry'};
  requiredField_company: IValidator = {objectID: 'input', regEx: '', displayText: 'Missing company name. Please fill and retry'};

  get rememberMe(): boolean {
    return this._rememberMe;
  }
  set rememberMe(value: boolean) {
    this._rememberMe = value;
    this.setUserDetailsToCache(this._rememberMe);
  }

  @Select(AppState.getAppConf) appConf$: Observable<IAppConf>;
  public appConf: IAppConf;
  
  constructor(private router: Router, private store: Store, private activatedRoute: ActivatedRoute, public dialogService: DialogService,
              private deviceService: DeviceDetectorService, private serverApi: ServerApi, public sessionApiSvc: SessionApiSvc,
            private intositeEmbeddedService: IntositeEmbeddedService) {

    this.isChrome = this.deviceService.browser === CHROME;
    this.isFF = this.deviceService.browser === FF;
    this.isEdge = this.deviceService.browser.includes(EDGE);

    window.addEventListener('beforeunload', () => localStorage.setItem('ins-rememberMe', this.rememberMe + ''));
    window.addEventListener('keydown', this.keyDown.bind(this));

    this.appConf$.subscribe( (appConf: IAppConf) => {
      this.appConf = appConf;
    });

    this.activatedRoute.queryParams.subscribe( (params) => {
      if (params.id) {
        this.tourId = params.id;
        this.appView = ApplicationView.Tour;
      }
      if (params.setnewpassword) {
        if (params.errorCode) {
          let message = "There was an internal error. Please contact the System Administrator.";
          switch(params.errorCode) {
            case "1000":
              message = "Change password link is invalid";
              break;
            case "1001":
              message = "Change password link is expired";
              break;
          }
          requestAnimationFrame(() => this.serverApi.createNotifiactionDialogForHttpCrisis(new Error(message), message));
        } else if (params.token) {
          requestAnimationFrame(() => this.openChangePasswordDialog("", params.user, params.company, params.token));
        }
      }
    });
  }

  keyPressed(keyEv: KeyboardEvent ): void {
    if (keyEv.keyCode === 13 /*Enter*/) {
      this.loginUser();
    }
  }

  keyDown(event$: any): void {
    if (event$.ctrlKey && event$.altKey && event$.key === 'e') {
      alert('WebGL Loaded logs are enabled. To disable it, please reload Intosite');
      ApiTools.showLogsInWebGL = true;
    }
  }

  ngOnInit(): void {
    const prevRemMe: string = localStorage.getItem('ins-rememberMe');
    this.rememberMe = isNullOrUndefined(prevRemMe) ? true : (prevRemMe === 'true');
    if (this.rememberMe && !isNullOrUndefined(prevRemMe)) {
      this.username = localStorage.getItem('ins-username');
      this.username = isNullOrUndefined(this.username) ? '' : this.username;
      this.company = localStorage.getItem('ins-comp');
      this.company = isNullOrUndefined(this.company) ? '' : this.company;
    }
  }

  ngOnDestroy(): void {
    localStorage.setItem('ins-rememberMe', this.rememberMe + '');
  }

  onInputsChange(): void {
    this.displayErrorMsg = false;
  }

  setUserDetailsToCache(keepInputs: boolean): void {
    if (!keepInputs) {
      localStorage.removeItem('ins-username');
      localStorage.removeItem('ins-pass');
      localStorage.removeItem('ins-comp');
      localStorage.setItem('ins-rememberMe', 'false');
    } else {
      if (this.username !== '' && this.company !== '') {
        localStorage.setItem('ins-username', this.username);
        // localStorage.setItem('ins-pass', this.password);
        localStorage.setItem('ins-comp', this.company);
      }
      localStorage.setItem('ins-rememberMe', 'true');
    }
  }

  loginUser(): void {
    if (!this.isAllFieldsFilled()) {
      return;
    }
    let loginData;
    // Temp Fix to not invalidate session when UI fails to send Heartbeats in case of Intopsite used in embedded mode 
    const clientType = this.appConf.appMode === APPLICATION_MODE.EMBEDDED ? 'API' : 'UI';

    if (this.appView == ApplicationView.Tour) {
      loginData = `{"userName":"${this.username}","userPassword":"${btoa(this.password)}","company":"${this.company}","tourId":"${this.tourId}"}`;
    } else {
      loginData = `{"userName":"${this.username}","userPassword":"${btoa(this.password)}","company":"${this.company}", "clientType":"${clientType}"}`;
    }
    const loginUrl: string = `${environment.serverUrl}/services/UserServices/login`;
    this.doLogin(loginUrl, loginData);
  }

  isAllFieldsFilled(): boolean {
    this.username = this.username.trim();
    this.password = this.password.trim();
    this.company = this.company.trim();
    if (this.username === '' || this.password === '' || this.company === '') {
      this.userNameRef.recheckValidity(true);
      this.passRef.recheckValidity(true);
      this.companyRef.recheckValidity(true);
      return false;
    }

    return true;
  }

  doLogin(loginUrl: string, loginData: string): void {
    this.serverApi.sendPostToServer(loginUrl, loginData).subscribe((res: any) => {
      let companyInfo: ICompanyInfo = res.companyInfo;
      if (companyInfo.ssoLogin) {
        if (companyInfo.intositeLoginMethod == IntositeLoginMethod.Enterprise) {
          this.router.navigate(['ssologin'], { queryParams: { company: companyInfo.name, Enterprise: 'true' }, queryParamsHandling: 'merge' });
        } else if (companyInfo.intositeLoginMethod == IntositeLoginMethod.Sphere) {
          this.router.navigate(['ssologin'], { queryParams: { company: companyInfo.name, Sphere: 'true' }, queryParamsHandling: 'merge' });
        } else {
          this.router.navigate(['ssologin'], { queryParams: { company: companyInfo.name }, queryParamsHandling: 'merge' });
        }
      } else {
        if (res['sessionId']) {
          console.log('RES', res['sessionId'].value);
          this.setUserDetailsToCache(this.rememberMe);
          if (this.appConf.appMode === APPLICATION_MODE.EMBEDDED) {
            this.intositeEmbeddedService.embeddedLoginDone.next({alreadyLoggedIn: false, res: res});
          } else {
            this.sessionApiSvc.loginSucceeded(res, this.appView);
          }
        }
      }

      }, (err: HttpErrorResponse) => {
      if (err.status === 500 || err.status === 401) {
          // 500 is internal error in server - some details are wrong
          this.displayErrorMsg = true;
        } else if (this.appView == ApplicationView.Tour && err.status == 403) {
          this.serverApi.createNotifiactionDialogForHttpCrisis(err, 'You do not have permission to access the Intosite tour.');
        } else {
          window.alert('The server encountered a problem. Please contact your system administrator');
        }
      }
    );
  }

  openChangePasswordDialog(newPassword: string, userName: string, company: string, token: string): void {
    const inputsBinding: InputsBindingsModel = new Map([
      ['newPassword', newPassword],
      ['confirmNewPassword', newPassword ],
      ['username', userName],
      ['company', company],
      ['token', token]]);
    const dialog: DialogRef = this.dialogService.createDialog(ChangePasswordComponent, DialogType.Modal, inputsBinding);
    dialog.onClose$().subscribe((model: DialogModel) => {
      if (model.userAction === 'login') {
        const response = model.getData('responseFromPostCall');
        this.sessionApiSvc.loginSucceeded(response, this.appView);
      }
    });
  }
  openResetPasswordDialog(): void {
    this.dialogService.createDialog(ResetPassDialogComponent, DialogType.Modal);
  }
  openRegisterUserDialog(): void {
    this.dialogService.createDialog(RegisterUserDialogComponent, DialogType.Modal, null, null, 440);
  }

  openTutorials(): void {
    const tourURL: string = `https://docs.sw.siemens.com/en-US/product/288782031/doc/PL20190617110825503.xid1847034/html/xid2116896`;
    window.open(tourURL, '_blank');
  }

  openGetFromMS(): void {
    const getFromMsUrl: string = `https://www.microsoft.com/en-us/p/intosite/9wzdncrd2d4x?activetab=pivot%3Aoverviewtab`;
    window.open(getFromMsUrl, '_blank');
  }

  openGetFromGoogle(): void {
    const getFromGoogleUrl: string = `https://play.google.com/store/apps/details?id=intosite.mobile`;
    window.open(getFromGoogleUrl, '_blank');
  }

  openPrivacyPolicy(): void {
    const privacyPolicyUrl: string = `https://www.plm.automation.siemens.com/global/en/legal/privacy-policy.html`;
    window.open(privacyPolicyUrl, '_blank');
  }

  openTermsOfService(): void {
    const termsOfServiceUrl: string = `https://www.sw.siemens.com/en-US/sw-terms/sla/`;
    window.open(termsOfServiceUrl, '_blank');
  }

}
