import {Select, Store} from '@ngxs/store';
import {Component, OnInit} from '@angular/core';
import {Observable, Subscription} from 'rxjs';
import {ServerApi} from '../services/api.services/server.api';
import {AppState} from '../Store/app.state/app.state';
import {ISite} from '../Store/sites.state/sites.model';
import {ActivatedRoute, NavigationEnd, Router} from '@angular/router';
import {SessionApiSvc} from '../services/api.services/session.api.svc';
import {ApiTools} from '../services/api.services/api.tools';
import {
  GoToProxyViewpoint,
  SetEmptyActiveSite,
  SetGoogleBlocked,
  SetSelectedMode,
  SetWebshareSession,
} from '../Store/app.state/app.actions';
import {SseApiSvc} from '../services/api.services/SSE/sse.api.svc';
import {PermissionsManager} from '../services/permissions-manager';
import {CMD_ACTIONS, CMD_TARGETS, CmdRouterService} from '../services/cmd-router.service';
import {isNullOrUndefined} from 'util';
import {SetAllSitesNoDefault} from '../Store/sites.state/sites.actions';
import {APPLICATION_MODE, IAppConf, SCENE_MODE} from '../Store/app.state/app.model';
import {environment} from '../../environments/environment';
import {SitesLoaderSvc} from '../services/api.services/sites.loader.svc';
import {WebshareApiSvc} from '../services/api.services/webshare.api.svc';
import {CoordinatesType, IPlacemark} from '../Store/placemarks.state/placemarks.model';
import {PlacemarksApiSvc} from '../services/api.services/placemarks.api.svc';
import {IntositeEmbeddedService} from '../services/external/intosite-embedded.service';
import {SiemensAnalyticsService} from '../services/siemens-analytics.service';
import {DataPrivacyApiSvc} from '../services/api.services/data-privacy.api.svc';
import {DataPrivacyType} from '../common/Models/UI/company-info.interface';
import {SitesApiSvc} from '../services/api.services/sites.api.svc';
import {MessageAndType, PriorityStack, StatusService} from '../services/status.service';
import {ViewerObjectType} from '../common/Models/UI/viewer-object-type.enum';

@Component({
  selector: 'ins-main',
  templateUrl: './main.component.html',
  styleUrls: ['./main.component.scss']
})
export class MainComponent implements OnInit {

  vpProxyParams: string;
  proxyChangedFromStart: boolean = true;
  @Select(AppState.getActiveSite) activeSite$: Observable<ISite>;
  activeSiteName: string = 'No Site Selected';
  @Select(AppState.getLogoutToggleIndex) logoutToggleIndex$: Observable<number>;

  @Select(AppState.getAppConf) appConf$: Observable<IAppConf>;
  public appConf: IAppConf;
  public APPLICATION_MODES: any = APPLICATION_MODE;

  @Select(AppState.getSelectedMode) selectedMode$: Observable<SCENE_MODE>;
  public sceneMode: SCENE_MODE;

  constructor(private store: Store, private serverApi: ServerApi, private route: ActivatedRoute, private placemarksApiSvc: PlacemarksApiSvc, private sessionApiSvc: SessionApiSvc,
    private sseApiSvc: SseApiSvc, private router: Router, private cmdRouterSvc: CmdRouterService, private siteApiSvc: SitesApiSvc, private statusService: StatusService,
    private siteLoaderSvc: SitesLoaderSvc, private webshareApiSvc: WebshareApiSvc, private intositeEmbeddedService: IntositeEmbeddedService, private dataPrivacyApiSvc: DataPrivacyApiSvc, private siemensAnalyticsService: SiemensAnalyticsService) {
    window.addEventListener('storage', this.storageChange.bind(this));
    if (!environment.production) {
      window.addEventListener('keydown', this.keyDownDebug.bind(this));
    }
    sessionStorage.setItem('tabId', Math.round(Math.random() * 1000000000000 + new Date().getTime()).toString());

    const sessionId: string = localStorage.getItem('intosite_session');
    this.sessionApiSvc.sessionId = sessionId;
    this.store.dispatch(new SetGoogleBlocked(localStorage.getItem('googleBlocked') !== 'false'));
    this.store.dispatch(new SetWebshareSession(JSON.parse(localStorage.getItem('webshareSession'))));
    PermissionsManager.initManager(sessionId);
    PermissionsManager.initPermBeforeGetPermFromServer();
    if (!ApiTools.isThroughLoginPage) {
      localStorage.setItem('intosite_session_counter', (+localStorage.getItem('intosite_session_counter') + 1).toString());
      ApiTools.sessionIndex = +localStorage.getItem('intosite_session_counter');
    }

    this.selectedMode$.subscribe((mode: SCENE_MODE) => {
      this.sceneMode = mode;
    });

    this.appConf$.subscribe( (appConf: IAppConf) => {
      this.appConf = appConf;
    });
    this.handleSiemensAnalyticsOnStartup();

    this.router.events.subscribe((ev) => {
      if (ev instanceof NavigationEnd && (ev.url === '/' || ev.url.startsWith('/?intosite-proxy=yes'))) {

        this.activeSite$.subscribe((site: ISite) => {
          if (site) {
            this.activeSiteName = site.name;
          } else {
            this.activeSiteName = 'No Site Selected';
          }
        });

        // for debug only!
        store.subscribe((state: any) => {
          (window as any).store = state;
        });

        this.runExampleFlow();
      }
    });
  }

  storageChange(event$: any): void {
    if (event$.key === 'proxyPath') {
      this.proxyChangedFromStart = false;
      const params = JSON.parse('{"' + event$.newValue.replace(/&/g, '","').replace(/=/g,'":"') + '"}', (key, value) => key===""?value:decodeURIComponent(value));
      this.goToProxyViewpoint(params);
      this.serverApi.isSiteAlreadyLoaded.next(true);
      localStorage.removeItem('proxyPath');
    }

    if (event$.key == null || (event$.key === 'intosite_session' && localStorage.getItem('intosite_session') === null)) {
      // console.log('intosite session: ', localStorage.getItem('intosite_session'));
          this.sessionApiSvc.expiredSessionMessage();
    }
  }

  keyDownDebug(event$: any): void {
    if (event$.ctrlKey && event$.altKey && event$.key === 'u') {
      const sampleString: string = '';
      this.proxyChangedFromStart = false;
      this.goToProxyViewpoint(sampleString);
      this.serverApi.isSiteAlreadyLoaded.next(true);
    }
  }

  ngOnInit(): void {
    this.route.queryParams.subscribe((params: any) => {
      if (params['intosite-site-viewpoint'] || params['intosite-object-viewpoint']) {
        this.proxyChangedFromStart = true;
        this.goToProxyViewpoint(params);
        console.log('changed', this.vpProxyParams);
      }
    });
  }

  public handleSiemensAnalyticsOnStartup() {
    // Siemens Analytics end session if its a refresh
    if (sessionStorage.getItem("refreshIntosite") === "true") {
      this.siemensAnalyticsService.endSession();
    }
    // Siemens Analytics init
    this.siemensAnalyticsService.initSession();
    if (sessionStorage.getItem("refreshIntosite") != "true" && +localStorage.getItem('intosite_session_counter') === 1) {
      // Siemens Analytics data privacy consent
      this.dataPrivacyApiSvc.openDataPrivacyDialog(DataPrivacyType.PEP, true);
      // Log Siemens Analytics event
      this.siemensAnalyticsService.logEvent('INS_Login', false);
    }
  }

  public goToProxyViewpoint(params: any): void {
    this.vpProxyParams = params;
    this.setViewpointFromProxy(params);
    console.log('changed', this.vpProxyParams);
  }

  public async runExampleFlow(): Promise<any> {
    await this.sessionApiSvc.getLibraryIcons();
    await this.sessionApiSvc.getCurrentUser();
    await this.sseApiSvc.regSse();

    this.cmdRouterSvc.sendActionCmd(CMD_TARGETS.MAP_MANAGER, CMD_ACTIONS.CLEAR_SCENE);
    this.cmdRouterSvc.sendActionCmd(CMD_TARGETS.WEBGL_MANAGER, CMD_ACTIONS.CLEAR_SCENE); // need to check - map / facility / pano
    this.cmdRouterSvc.sendActionCmd(CMD_TARGETS.PANORAMIC_MANAGER, CMD_ACTIONS.CLEAR_PANORAMIC_VIEWER);

    if (this.appConf.appMode === APPLICATION_MODE.EMBEDDED && this.appConf.loadSpecificSite) {
      this.intositeEmbeddedService.loadSiteInEmbeddedMode$.next(true);
      return;
    }
    await this.sessionApiSvc.getSessionContext();
    // await this.sitesApiSvc.getSites();
    if (ApiTools.defaultSiteId === undefined) {
      return;
    } else {
      PermissionsManager.getUserPermissions();
      if (isNullOrUndefined(this.vpProxyParams) || this.proxyChangedFromStart) {
        if(isNullOrUndefined(this.vpProxyParams)){
          console.log("site loading without await...")
          this.siteLoaderSvc.loadSite(undefined, undefined, false, true);
        }else{
          console.log("site loading with await...")
          await this.siteLoaderSvc.loadSite(undefined, undefined, false, true, true);
        }
      }
    }
    this.serverApi.isSiteAlreadyLoaded.next(true);
  }

  async createVP(params: string[]): Promise<any> {
    let returnVal: any = {};
    switch (params[1]) {
      case '0': {
        returnVal = {
          coordinatesType: 'GIS',
          latitude: Number(params[2]),
          longitude: Number(params[3]),
          altitude: Number(params[4]),
          parentZoneId: undefined,
          accessMode: undefined,
          creatorId: undefined,
          description: undefined,
          parentLayerId: undefined,
          rotation: undefined,
          range: undefined,
          tilt: undefined,
          parentPanoramaId: undefined,
          isDefaultInZone: undefined,
          positionProperty: undefined,
          category: undefined,
          parent: 'MAPS',
          id: undefined,
          name: undefined
        };
        break;
      }
      case '1': {
        returnVal = {
          coordinatesType: 'FACILITY',
          latitude: undefined,
          longitude: undefined,
          altitude: undefined,
          parentZoneId: undefined,
          accessMode: undefined,
          creatorId: undefined,
          description: undefined,
          parentLayerId: undefined,
          rotation: Number(params[5]),
          range: Number(params[6]),
          tilt: Number(params[7]),
          parentPanoramaId: params[8] != null ? params[8] : undefined,
          isDefaultInZone: undefined,
          positionProperty: {x: Number(params[2]), y: Number(params[3]), z: Number(params[4])},
          category: undefined,
          parent: params[8] != null ? 'PANORAMIC' : 'WEBGL',
          id: undefined,
          name: undefined
        };
        break;
      }
    }

    if (returnVal.parent == 'PANORAMIC') {
      let pm: IPlacemark = await this.placemarksApiSvc.getPlacemarkTypeById(returnVal.parentPanoramaId);
      if (pm) {
        if (pm.scanUUID) {
          const sub = this.webshareApiSvc.webShareLoginInfoSubject()
            .subscribe( async ( success ) => {
              sub.unsubscribe();
              if ( success ) {
                try {
                  await this.webshareApiSvc.checkUserAccessToWebshareProject(pm['layer']);
                  this.store.dispatch(new GoToProxyViewpoint(returnVal));
                } catch (error) {
                }
              }
            })
          this.webshareApiSvc.loginToWebshare();
        } else {
          this.store.dispatch(new GoToProxyViewpoint(returnVal));
        }
      }
    } else {
      this.store.dispatch(new GoToProxyViewpoint(returnVal));
    }
  }

  async zoomInToObject(params: any): Promise<any> {
    const response = await this.siteApiSvc.getObjectByExternalId(params.siteId, params.externalId, params.app);
    const object = response.object;
    let type = null;
    if (response.objectType === 'PLACEMARK') {
      type = ViewerObjectType.PLACEMARK;
    } else if (response.objectType === 'THREE_D_ELEMENT') {
      type = ViewerObjectType.MODEL;
    }
    const data = {objectType: type, id: object.id?.value};
    const subscription: Subscription = this.statusService.registerStatus().subscribe((status: PriorityStack<MessageAndType>) => {
      if (status.isEmpty() || status.top().isOnTime) {
        if (params.view === 'facility') {
          this.sceneMode !== SCENE_MODE.Facility && this.store.dispatch(new SetSelectedMode(SCENE_MODE.Facility));
          this.cmdRouterSvc.sendActionCmd(CMD_TARGETS.WEBGL_MANAGER, CMD_ACTIONS.ZOOM_IN_TO_OBJECT, {data});
        } else if (params.view === 'map') {
          this.sceneMode !== SCENE_MODE.Map && this.store.dispatch(new SetSelectedMode(SCENE_MODE.Map));
          this.cmdRouterSvc.sendActionCmd(CMD_TARGETS.MAP_MANAGER, CMD_ACTIONS.GO_TO_VP_MODE, {lat: object.geoLocation?.latitude, lng: object.geoLocation?.longitude, selectedVP: {coordinatesType: CoordinatesType.GIS}});
        }
        subscription.unsubscribe();
      }
    });
  }

  setViewpointFromProxy(params: any): void {
    const subscription: Subscription = this.serverApi.isSiteAlreadyLoaded.subscribe(async (isSiteAlreadyLoaded: boolean) => {
      if (params['siteId'] && isSiteAlreadyLoaded) {
        const siteId: number = Number(params['siteId']);
        const targetedSite: ISite = this.store.selectSnapshot<ISite[]>((state: any) => state.StateSites.sites).find((site: ISite) => site.id === siteId);
        let path = params['intosite-site-viewpoint'] ? params['intosite-site-viewpoint'].split(';') : [];
        if (!isNullOrUndefined(targetedSite)) {
          const activeSiteId: number = this.store.selectSnapshot<ISite>((state: any) => state.StateApp.activeSite).id;
          if (targetedSite.id !== activeSiteId) {
            await this.siteLoaderSvc.loadSite(targetedSite, undefined, false, false, true);
          }

          if (params['intosite-object-viewpoint']) {
            this.zoomInToObject(params);
          } else {
            this.createVP(path);
          }
          path = [];
        } else {
          path = [];
          this.activeSiteName = 'No Site Selected';
          this.store.dispatch(new SetSelectedMode(SCENE_MODE.Load));
          this.store.dispatch(new SetEmptyActiveSite());
          this.store.dispatch(new SetAllSitesNoDefault());
        }
      }
      subscription.unsubscribe();
    });
  }
}
