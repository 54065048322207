<div class="SubMenuContent" [class.opened]="menuOpened">
  <ins-expansion-box *ngIf="isPerm2D" [leftPositionedArrow]="true" [titleHeight]="'auto'"
    [elementOnPanel]="false"  [titleStyle]="{'padding': '6px 0 6px 2px', 'align-items': 'flex-end', 'line-height': '20px'}">
    <div titleContent style="white-space: normal">2D Model</div>
    <div content class="subSubMenuEntries">
      <div *ngIf="isPerm2DUpload" id="upload2DModelLink" (click)="uploadLayout()">&nbsp; Upload</div>
      <div *ngIf="isPerm2DEdit" (click)="editLayout()">&nbsp; Edit</div>
      <div *ngIf="isPerm2DDelete" (click)="deleteLayouts()">&nbsp; Delete</div>
    </div>
  </ins-expansion-box>
  <ins-expansion-box *ngIf="!(limitedAccess$ | async).disable3DModels && isPerm3D" [leftPositionedArrow]="true" [titleHeight]="'auto'"
    [elementOnPanel]="false" [titleStyle]="{'padding': '6px 0 6px 2px', 'align-items': 'flex-end', 'line-height': '20px'}">
    <div titleContent style="white-space: normal">3D Model</div>
    <div content class="subSubMenuEntries">
      <div *ngIf="isPerm3DUpload" id="upload3DModelLink" (click)="uploadModel()">&nbsp; Upload</div>
      <div *ngIf="isPerm3DEdit" (click)="editModel()">&nbsp; Edit</div>
      <div *ngIf="isPerm3DDelete" (click)="deleteModel()">&nbsp; Delete</div>
    </div>
  </ins-expansion-box>
</div>
<div *ngIf="isPermModel" id="modelMenuBtn" class="SubMenuButton" [class.pressed]="menuOpened" (click)="toggleMenu()">
  <mat-icon *ngIf="visible" class="subMenuArrow" svgIcon="arrow.submenu"></mat-icon>
  <mat-icon class="subMenuIcon" [class.pressed]="menuOpened" svgIcon="models.submenu"></mat-icon>
  <div>Model</div>
</div>
