import {RouterModule, Routes} from '@angular/router';
import {MainComponent} from './main-component/main.component';
import {LoginComponent} from './Viewer/login.component/login.component';
import {AuthGuard} from './Guards/Auth.guard';
import {AlreadyLoggedGuard} from './Guards/Already-logged.guard';
import {SSOLoginComponent} from './Viewer/sso-login/sso-login.component';
import {PublicTourLoginComponent} from './Viewer/public-tour-login/public-tour-login.component';
import {TourAuthGuard} from './Guards/TourAuth.guard';
import {TourLogoutLandingComponent} from './Viewer/tour-logout-landing/tour-logout-landing.component';
import {EmbeddedAppLandingComponent} from './Viewer/emdedded-intosite/embedded-app-landing/embedded-app-landing.component';
import { NotFoundComponent } from './Viewer/not-found/not-found.component';

const appRoutes: Routes = [
  {path: 'login', component: LoginComponent,  canActivate: [AlreadyLoggedGuard]},
  {path: 'intosite', component: EmbeddedAppLandingComponent},
  {path: 'ssologin', component: SSOLoginComponent,  canActivate: [AlreadyLoggedGuard]},
  {path: 'publicTour', component: PublicTourLoginComponent},
  {path: 'Tour', loadChildren: () => import('./tour-component/tour.module').then(m => m.TourModule), canActivate: [TourAuthGuard] },
  {path: 'tourLogoutLanding', component: TourLogoutLandingComponent},
  {path: '', component: MainComponent, canActivate: [AuthGuard], pathMatch: 'full'},
  {path: '**', pathMatch: 'full', component: NotFoundComponent }
];

export const AppRouting = RouterModule.forRoot(appRoutes, { relativeLinkResolution: 'corrected' });
