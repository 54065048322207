<div class="upperSection">
  <div class="menuTitle">
    <div class="title">Settings</div>
  </div>
</div>
<div class="middleSection noFlex">
  <perfect-scrollbar>
    <div class="outerEntry1 first textButtonOnPanel asEntry" (click)="copyLink()">
      <mat-icon class="lineIcon noStroke" svgIcon="copy.link"></mat-icon>
      Copy Link
    </div>
    <div *ngIf="isAdmin" class="outerEntry1 textButtonOnPanel asEntry"
      (click)="openWebshareConfDialog()">
      <mat-icon class="lineIcon noStroke" svgIcon="pointCloudConf.link"></mat-icon>
      Point Cloud Configuration
    </div>
    <div class="outerEntry1 textButtonOnPanel asEntry"
      (click)="openOperationStatusBoard()"
      *ngIf="isPerm2DEdit || isAdmin || isSiteAdmin">
      <mat-icon class="lineIcon noStroke" svgIcon="status.link"></mat-icon>
      Operation Status Board
    </div>
    <ins-expansion-box [titleHeight]="'auto'" *ngIf="useSan && isAdmin" [leftPositionedArrow]="true"
      [elementOnPanel]="true" [titleStyle]="{margin: '4px 0', 'align-items': 'flex-end'}">
      <div titleContent style="white-space: normal">Data Privacy</div>
      <div content class="expansionEntries">
        <div (click)="openDataPrivacyDialog('DPE')">Digital Product Experience</div>
        <div (click)="openDataPrivacyDialog('PEP')">Product Excellence Program</div>
        <div style="padding: 0"></div>
      </div>
    </ins-expansion-box>
  </perfect-scrollbar>
</div>
<div class="bottomAlignedPanelFooter"></div>
